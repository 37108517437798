import { Component } from "react";

import './quote-state-selector.css';

class QuoteStateSelector extends Component {
  render() {
    let tl = this.props.tl
    let value = this.props.value;
    let mapping = this.props.mapping;
    let status = mapping
      .filter(qs => qs.tag === value)[0]
      || { label: 'UNKNOWN', theme: 'red' };

    return (
      <div className={'quoteStateSelector ' + status.theme} onClick={e => e.stopPropagation()}>
        <span>{tl(status.label)}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="13.223" height="7.929" viewBox="0 0 13.223 7.929">
          <path id="Shape" d="M13.23,1.336A1.325,1.325,0,0,0,10.969.4L6.62,4.748,2.272.4A1.327,1.327,0,1,0,.4,2.272L5.684,7.548a1.325,1.325,0,0,0,1.873,0l5.289-5.276A1.325,1.325,0,0,0,13.23,1.336Z" transform="translate(-0.007 -0.007)" />
        </svg>
        <select
          value={value}
          disabled={this.props.disabled}
          onChange={(e) => this.props.onUpdateStatus(e.target.value)}
        >
          {mapping.map(qs =>
            <option key={qs.tag} value={qs.tag} disabled={qs.disabled}>{tl(qs.label)}</option>)
          }
        </select>
      </div>
    );
  }
}

export default QuoteStateSelector;
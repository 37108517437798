import AppContext from "../app/app-context";
import BaseComponent from "../app/base-component";
import ApiClient from "../api-client/api-client";

import GenericPage from "../app/generic-page/generic-page";
import Table from "../app/table/table";

import AddButton from "../app/generic-page/buttons/add-button";
import FilterButton from "../app/generic-page/buttons/filter-button";

import AccountPopup from "./account-popup/account-popup";

import "./account-management-page.css";
import { Link } from "react-router-dom";
import QueryFilterPopup from "../app/table/filter-popup/query-filter-popup";
import AccountImportPopup from "./account-import-popup/accoun-import-popup";

class AccountManagementPage extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      accountPopupShown: false,
      filterPopupShown: false,
      accounts: null,
      selectedAccount: null,
      filterQuery: 'Active==true',

      languages: [],
    };
  }

  updateFilterQuery(filterQuery) {
    this.setStateAsync({
      filterQuery,
    })
      .then(this.refreshData.bind(this));
  }

  updateSortQuery(sortQuery) {
    this.setStateAsync({
      sortQuery
    })
      .then(this.refreshData.bind(this));
  }

  componentDidMount() {
    Promise.all([
      this.getLanguages(),
    ]).then(() => {
      this.refreshData();
    });
  }

  getLanguages() {
    return new ApiClient(this.context)
      .call("GET", "/v1/Language", null, false, { "Accept-Language": null })
      .then((result) => {
        if (result.ok) {
          let data = result.json;

          return this.setStateAsync({
            languages: data,
          });
        }
      });
  }

  refreshData() {
    var sortQuery = this.state.sortQuery || '';
    var filterQuery = this.state.filterQuery || '';

    new ApiClient(this.context)
      .call("GET", `/v1/Tenant/${this.props.tenantId}/Account`, null, false, {}, {
        'Sorts': sortQuery,
        'Filters': filterQuery,
      })
      .then((response) => {
        if (response.ok) {
          let accounts = response.json;

          this.setStateAsync((state) => ({
            accounts: accounts,
          }));
        }
      });
  }

  toggleAddAccountPopup() {
    this.setState((state) => ({
      accountPopupShown: !state.accountPopupShown,
      selectedAccount: null,
    }));
  }

  toggleEditAccountPopup(account) {
    this.setState((state) => ({
      accountPopupShown: !state.accountPopupShown,
      selectedAccount: account,
    }));
  }

  toggleFilterPopup() {
    this.setState((state) => ({
      filterPopupShown: !state.filterPopupShown,
    }));
  }

  toggleImportPopup() {
    this.setState(state => ({
      importPopupShown: !state.importPopupShown,
    }));
  }

  renderButtons() {
    let tl = this.context.translate;

    return (
      <div>
        <FilterButton onClick={this.toggleFilterPopup.bind(this)} />
        <div class="group">
          {this.context.userHasClaim('Accounts', 'Add') && <AddButton onClick={this.toggleImportPopup.bind(this)} label={tl('general_import')} />}
          {this.context.userHasClaim('Accounts', 'Add') && <AddButton onClick={this.toggleAddAccountPopup.bind(this)} />}
        </div>
      </div>
    );
  }

  renderTable() {
    let tl = this.context.translate;

    return (
      <Table
        mapping={{
          active: {
            label: tl('general_active'),
            sortKey: 'Active',
            value: (tenant) => tenant.active ? '✅' : '⛔',
          },
          account: {
            label: tl('general_account'),
            value: (account) => account.name,
            sortKey: 'Name',
          },
          actions: {
            label: tl('general_actions'),
            value: (account) => (
              <Link to={`/tenant/${this.props.tenantId}/account/${account.id}/contacts`}>
                <button>
                  {tl('general_contacts')}
                </button>
              </Link>
            ),
          },
        }}
        entries={this.state.accounts}
        onSelect={this.toggleEditAccountPopup.bind(this)}

        sortQuery={this.state.sortQuery}
        updateSortQuery={this.updateSortQuery.bind(this)}
      />
    );
  }

  render() {
    let tl = this.context.translate;

    return (
      <GenericPage
        className="accountManagementPage"
        title="Accounts"
        buttons={this.renderButtons()}
        content={this.renderTable()}
      >
        {this.state.accountPopupShown && (
          <AccountPopup
            onDismiss={this.toggleAddAccountPopup.bind(this)}
            languages={this.state.languages}
            account={this.state.selectedAccount}
            tenantId={this.props.tenantId}
            onCommit={this.refreshData.bind(this)}
          />
        )}
        {this.state.filterPopupShown && (
          <QueryFilterPopup
            onDismiss={this.toggleFilterPopup.bind(this)}
            updateFilterQuery={this.updateFilterQuery.bind(this)}
            filterQuery={this.state.filterQuery}
            mapping={{
              'Name': {
                label: tl('general_account'),
              },
            }}
          />
        )}
        {this.state.importPopupShown && (
          <AccountImportPopup
            onDismiss={this.toggleImportPopup.bind(this)}
            tenantId={this.props.tenantId}
            onCommit={this.refreshData.bind(this)}
          />
        )}
      </GenericPage>
    );
  }
}

export default AccountManagementPage;

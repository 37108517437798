import moment from "moment/moment";
import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";
import Checkbox from "../../app/checkbox/checkbox";

import Popup from "../../app/popup/popup";

import "./contact-popup.css";

class ContactPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    var contact = {};

    if (props.contact) {
      contact = {
        account: props.contact.account,

        fullName: props.contact.fullName,
        jobTitle: props.contact.jobTitle,
        externalId: props.contact.externalId,
        userLanguage: props.contact.userLanguage || {},

        email: props.contact.email,
        phoneNumberPersonal: props.contact.phoneNumberPersonal,

        address: props.contact.address,
        country: props.contact.country,
        postalCode: props.contact.postalCode,
        city: props.contact.city,

        role: props.contact.role,

        lastLoginTime: props.contact.lastLoginTime,
      };
    }

    this.state = {
      contact: {
        account: {},

        fullName: "",
        jobTitle: "",
        externalId: "",
        userLanguage: {},

        email: "",
        sendActivationEmail: true,
        phoneNumberPersonal: "",

        address: "",
        country: "",
        postalCode: "",
        city: "",

        role: {},

        lastLoginTime: null,

        ...contact
      },
    };
  }

  componentDidMount() {
    if (!this.props.contact) {
      this.setStateAsync(state => {
        let account = this.props.accounts
          .find(a => a.id === this.props.accountId);

        return {
          contact: {
            ...state.contact,
            account: account,
            address: account.address,
            postalCode: account.postalCode,
            city: account.city,
            country: account.country,
            userLanguage: account.defaultLanguage || {},
          },
        };
      });
    }
  }

  updateContact(create = false) {
    let isSelf = this.props.contact
      && this.props.contact.id === this.context.user.id;

    let apiClient = new ApiClient(this.context);
    let call = create
      ? ['POST', '/v1/Contact']
      : (isSelf
        ? ['PUT', `/v1/Contact/UpdateCurrent`]
        : ['PUT', `/v1/Contact/${this.props.contact.id}`]);

    let account = this.state.contact.account;
    let role = this.state.contact.role;
    let userLanguage = this.state.contact.userLanguage;

    apiClient
      .call(...call, {
        ...this.state.contact,
        accountId: account && account.id,
        roleId: role && role.id,
        userLanguageId: userLanguage && userLanguage.id,
        bearerToken: null,
      })
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.onCommit && this.props.onCommit();
          this.context.addNotification(
            "success",
            create
              ? this.context.translate('notifications_add_user_successfull')
              : this.context.translate('notifications_edit_user_successfull'),
          );

          if (isSelf) {
            this.context.setUser({
              ...result.json,
              bearerToken: this.context.user.bearerToken,
            });
          }
        } else {
          throw result;
        }
      });
  }

  toggleContactActive(active) {
    let tl = this.context.translate;

    this.askConfirmation(
      tl('notification_are_you_sure'),
      tl('warning_contact_activate'),
    )
      .then((confirm) => {
        if (!confirm) return;

        let apiClient = new ApiClient(this.context);
        let call = active
          ? ['PUT', `/v1/Contact/${this.props.contact.id}/Activate`]
          : ['DELETE', `/v1/Contact/${this.props.contact.id}`];

        apiClient
          .call(...call)
          .then((result) => {
            if (result.ok) {
              this.props.onDismiss();
              this.props.onCommit();
              this.context.addNotification(
                "success",
                active
                  ? tl('notification_contact_reactivate_success')
                  : tl('notification_contact_deactivate_success')
              );
            } else {
              throw result;
            }
          });
      })
  }

  onPropChange(key, val) {
    this.setState(state => ({
      contact: {
        ...state.contact,
        [key]: val,
      },
    }));
  }

  onInputChange(e) {
    let key = e.target.name;
    let val = e.target.value;

    this.onPropChange(key, val);
  }

  onCheckboxChange(e) {
    let key = e.target.name;
    let val = e.target.checked;

    this.onPropChange(key, val);
  }

  onSelectChange(options, e) {
    let key = e.target.name;
    let id = e.target.value;
    let val = options.find(o => o.id === id) || {};

    this.onPropChange(key, val);
  }

  render() {
    let tl = this.context.translate;
    let userHasRole = this.context.userHasRole;

    let isSelf = this.props.contact
      && this.props.contact.id === this.context.user.id;
    let lastLoginString = this.state.contact.lastLoginTime
      ? moment(this.state.contact.lastLoginTime).format('YYYY-MM-DD HH:mm')
      : tl('general_never');

    return (
      // TODO: I18n
      <Popup
        title={tl('general_contacts')}
        actions={
          this.props.contact
            ? [
              this.props.contact.active && {
                label: tl('general_deactivate'),
                handler: this.toggleContactActive.bind(this, false),
                className: 'red',
                disabled: isSelf,
              },
              !this.props.contact.active && {
                label: tl('general_reactivate'),
                handler: this.toggleContactActive.bind(this, true),
                className: 'green',
                disabled: isSelf,
              },
              {
                label: tl('general_update'),
                handler: this.updateContact.bind(this, false),
                submit: true,
              },
            ]
            : [
              {
                label: tl('general_save'),
                handler: this.updateContact.bind(this, true),
                submit: true,
              },
            ]
        }
        onDismiss={this.props.onDismiss}
      >
        <div className="contactPopup">
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_name')}</h2>
                <input
                  name="fullName"
                  value={this.state.contact.fullName}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_jobtitle')}</h2>
                <input
                  name="jobTitle"
                  value={this.state.contact.jobTitle}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
            <div className="popup-column">
              {!userHasRole('PURCHASEMANAGER') && !userHasRole('INSIDESALES') &&
                <div className="popup-inputContainer">
                  <h2>{tl('general_external_id')}</h2>
                  <input
                    className="contactPopup-inputExternalId"
                    name="externalId"
                    value={this.state.contact.externalId}
                    onChange={this.onInputChange.bind(this)}
                  ></input>
                </div>
              }
              <div className="popup-inputContainer">
                <h2>{tl('general_language')}</h2>
                <select
                  name="userLanguage"
                  value={this.state.contact.userLanguage.id}
                  onChange={this.onSelectChange.bind(this, this.props.languages)}
                >
                  <option></option>
                  {this.props.languages.map((l) => (
                    <option key={l.id} value={l.id}>
                      {l.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_email')}</h2>
                <input
                  className="contactPopup-inputEmail"
                  name="email"
                  value={this.state.contact.email}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              {!this.props.contact && (
                <div className="popup-inputContainer">
                  <Checkbox
                    title={tl('general_send_activation_email')}
                    selected={this.state.contact.sendActivationEmail}
                    id="sendActivationEmail"
                    onChange={this.onCheckboxChange.bind(this)}
                  />
                </div>
              )}

            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_phonenumber')}</h2>
                <input
                  className="contactPopup-inputPhonenumber"
                  name="phoneNumberPersonal"
                  value={this.state.contact.phoneNumberPersonal}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
          </div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_address')}</h2>
                <input
                  name="address"
                  value={this.state.contact.address}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_country')}</h2>
                <select
                  name="country"
                  value={this.state.contact.country}
                  onChange={this.onInputChange.bind(this)}
                >
                  <option></option>
                  {this.props.countries.map((c) => (
                    <option key={c} value={c}>
                      {c}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('account_zip_code_city_title')}</h2>
                <input
                  className="contactPopup-inputZipCode"
                  name="postalCode"
                  value={this.state.contact.postalCode}
                  onChange={this.onInputChange.bind(this)}
                ></input>
                <input
                  className="contactPopup-inputCity"
                  name="city"
                  value={this.state.contact.city}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
          </div>
          {!isSelf && this.props.roles.length > 0 && (
            <div className="popup-section">
              <div className="popup-column">
                <h2>{tl('general_role')}</h2>
                <div className="popup-linkContainer popup-inputContainer">
                  <select
                    name="role"
                    value={this.state.contact.role !== null ? this.state.contact.role.id : null}
                    onChange={this.onSelectChange.bind(this, this.props.roles)}
                  >
                    <option></option>
                    {this.props.roles.map((r) => (
                      <option key={r.id} value={r.id}>
                        {r.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
          {this.props.contact && !userHasRole('PURCHASEMANAGER') &&
            <div className="popup-section">
              <div className="popup-column">
                <h2>{tl('general_lastlogin')}</h2>
                <div className="popup-inputContainer">
                  {lastLoginString}
                </div>
              </div>
            </div>
          }
        </div>
      </Popup>
    );
  }
}

export default ContactPopup;

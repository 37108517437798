import { Component } from 'react';
import moment from 'moment';
import './footer.css';
import AppContext from '../app-context';


class Footer extends Component {
  static contextType = AppContext;
  render() {
    let tl = this.context.translate;
    return (
      <div className="footer">
        <span className='copyrightSpan'>&copy; {moment().format('YYYY')} Offer2Order</span>
        <nav className='footer-footerNavigation'>
          <ul>
            {/* <li>
              <a href="/">
                {tl('footer_contact')}
              </a>
            </li> */}
            <li>
              <a target="_blank" rel="noreferrer" href="https://offer2order.com/data-privacy-notice/">
                {tl('footer_data_privacy_notice')}
              </a>
            </li>
            <li>
              <a target="_blank" rel="noopener noreferrer" href="https://offer2order.com/imprint/">
                {tl('footer_imprint')}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default Footer;
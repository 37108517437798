class ApiClient {
  constructor(context) {
    this.context = context;
  }

  call(method, uri, data = null, form = false, headers = {}, queryParams = {}) {
    var settings = {
      method: method,
      headers: {
        Accept: "application/json",
      },
    };

    if (this.context.user) {
      settings["headers"]["Authorization"] =
        "Bearer " + this.context.user.bearerToken;
    }
    if (data) {
      if (data instanceof FormData) {
        settings["body"] = data;
      } else if (form) {
        settings["headers"]["Content-Type"] =
          "application/x-www-form-urlencoded;charset=UTF-8";
        settings["body"] = new URLSearchParams(data).toString();
      } else {
        settings["headers"]["Content-Type"] = "application/json";
        settings["body"] = JSON.stringify(data);
      }
    }

    if (this.context.language) {
      settings["headers"]["Accept-Language"] = this.context.language.code;
    }

    settings["headers"] = {
      ...settings.headers,
      ...headers,
    };

    let queryString = new URLSearchParams(queryParams).toString();

    return this.context.setLoading(true).then(() =>
      fetch(`${this.context.settings.apiUrl}${uri}?${queryString}`, settings)
        .then(async (response) => {
          if (response.status === 401) {
            this.context
              .setUser(null)
              .then(() =>
                this.context.addNotification(
                  "info",
                  this.context.translate('notifications_logout_successfull')
                )
              );
          }

          let contentType = (response.headers.get('Content-Type') || '').split(';')[0];
          let contentDisposition = (response.headers.get('Content-Disposition') || '')
            .split(';')
            .filter(s => s.length > 0)
            .reduce((a, s) => {
              let kvp = s.split('=');
              a[kvp[0]] = kvp[1] || true;
              return a;
            }, {});
          var json = {};
          var raw = new Blob([], { type: 'application/x-empty' });

          if (/^application\/.*?json$/.test(contentType)) {
            json = await response
              .json()
              .catch((e) => (!response.ok ? e : {}));
          }
          else {
            raw = await response.blob();
          }

          if (json.errors) {
            Object.values(json.errors)
              .flatMap((e) => e)
              .filter((v, i, s) => s.indexOf(v) === i)
              .forEach((e) => this.context.addNotification("error", e));
          }

          if (json.error) {
            this.context.addNotification("error", json.error_description);
          }

          return {
            status: response.status,
            ok: response.ok,
            json: json,

            contentType: contentType,
            contentDisposition: contentDisposition,
            raw: raw,
          };
        })
        .finally(() => this.context.setLoading(false))
    );
  }
}

export default ApiClient;

import { Component } from "react";
import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import Popup from "../../app/popup/popup";
import AuthenticationPage from "../authentication-page";

class PasswordResetPage extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    var params = new URLSearchParams(window.location.search);

    this.state = {
      email: params.get("email"),
      token: params.get("token"),
      passwordA: "",
      passwordB: "",
      done: false,
    };
  }

  onInputChange(e) {
    let key = e.target.name;
    this.setState({
      [key]: e.target.value,
    });
  }

  resetPassword() {
    new ApiClient(this.context)
      .call("POST", "/v1/User/PasswordResetJwt", {
        email: `${this.context.theme.tenantId}\\${this.state.email}`,
        token: this.state.token,
        password: this.state.passwordA,
      })
      .then((json) => {
        if (json.ok) {
          this.context.addNotification(
            "success",
            this.context.translate('notifications_password_change_successfull')
          );
          this.setState({
            done: true,
          });
        } else {
          throw json;
        }
      });
  }

  render() {
    let tl = this.context.translate;

    return (
      <AuthenticationPage>
        <Popup
          title={tl('password_reset_title')}
          dismissable={true}
          actions={[
            {
              label: tl('password_reset_submit'),
              handler: this.resetPassword.bind(this),
              submit: true,
            },
          ]}
        >
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('login_email_label')}</h2>
                <input
                  placeholder={tl('login_email_placeholder')}
                  name="email"
                  value={this.state.email}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('password_reset_new_label')}</h2>
                <input
                  type="password"
                  placeholder={tl('password_reset_new_placeholder')}
                  name="passwordA"
                  value={this.state.passwordA}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('password_reset_repeat_label')}</h2>
                <input
                  type="password"
                  placeholder={
                    tl('password_reset_repeat_placeholder')
                  }
                  name="passwordB"
                  value={this.state.passwordB}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
          </div>
        </Popup>
      </AuthenticationPage>
    );
  }
}

export default PasswordResetPage;

import React from "react";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";
import ApiClient from "../../api-client/api-client";

import Popup from "../../app/popup/popup";
import Loader from "../../app/loader/loader";

import "./quotation-popup.css";
import QuotationListItem from "./quotation-list-item/quotation-list-item";
import CalculationOverview from "./calculation-overview/calculation-overview";
import { buildFormData } from "../../helpers";
import PendingPopup from "./pending-popup/pending-popup";
import moment from "moment";

class QuotationPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);

    this.state = {
      disableButtons: true,
      informationPopupShown: false,
      pendingPopupShown: false,
      quote: null,
      quoteId: "",
      parts: null,
      accounts: [],
      contacts: [],
      supportedOperations: [],
      unattended: false,
      deliveryOptions: [],
      deliveryTimes: [],
    };
  }

  componentDidMount() {
    Promise.all([
      this.getAccounts(),
      this.getSupportedOperations(),
    ])
      .then(() => {
        if (this.props.reorderId) {
          this.cloneQuote();

        } else if (!this.props.quote) {
          this.calculatePrice();

        } else if (!this.props.unattended) {
          this.getCalculation().then(() => {
            if (this.props.recalculate) {
              this.recalculateQuote();
            }
          });
        }
      });
  }

  getSupportedOperations() {
    let url = `/v1/TenantSettings/SupportedOperations`;

    return new ApiClient(this.context).call("GET", url)
      .then((response) => {
        if (response.ok) {
          return this.setState({
            supportedOperations: response.json
          });
        }
      });
  }

  getAccounts() {
    if (this.context.userHasClaim('Accounts', 'View')) {
      return new ApiClient(this.context)
        .call(
          "GET",
          `/v1/Tenant/${this.context.theme.tenantId}/Account`,
          null,
          false,
          {},
          {
            Sorts: "name",
            Filters: "Active==true",
          }
        )
        .then((response) => {
          let accounts = response.json;

          return this.setStateAsync({
            accounts: accounts,
          });
        });
    }
    else {
      return this.setStateAsync({
        accounts: [
          this.context.user.account
        ],
      });
    }
  }

  getContacts() {
    if (this.context.userHasClaim("Contacts", "View")) {
      return new ApiClient(this.context)
        .call(
          "GET",
          `/v1/Account/${this.state.quote.account.id}/Contact`,
          null,
          false,
          {},
          {
            Filters: "Active==true",
          }
        )
        .then((response) => {
          let contacts = response.json;

          if (response.ok) {
            return this.setStateAsync({
              contacts: contacts,
            });
          } else {
            return this.setStateAsync({
              contacts: [],
            });
          }
        });
    } else {
      return this.setStateAsync({
        contacts: [this.context.user],
      });
    }
  }

  getDeliveryOptions() {
    let url = `/v1/TenantSettings/${this.context.theme.tenantId}/TenantDeliverySettings`;

    return new ApiClient(this.context).call("GET", url).then((response) => {
      if (response.ok) {
        let options = response.json;

        this.setState({ deliveryOptions: options });
      } else {
        this.setState({ deliveryOptions: [] });
      }
    });
  }

  getDeliveryTime() {
    let url = `/v1/TenantSettings/${this.context.theme.tenantId}/TenantDeliveryTimes`;

    return new ApiClient(this.context).call("GET", url).then((response) => {
      if (response.ok) {
        let times = response.json;

        this.setState({ deliveryTimes: times });
      } else {
        this.setState({ deliveryTimes: [] });
      }
    })
  }

  getCalculation(quoteId = null) {
    let quote = this.props.quote;
    quoteId = quoteId || quote.id;
    let url = `/v1/Quote/${quoteId}`;

    return new ApiClient(this.context).call("GET", url).then((response) => {
      if (response.status === 204) {
        setTimeout(this.getCalculation.bind(this, quoteId), 1000);
      } else if (response.status === 403) {
        this.togglePendingPopup();
      } else {
        let data = response.json;

        this.props.refreshData && this.props.refreshData();

        return this.mapCalculatePriceResult(data);
      }
    });
  }

  cloneQuote() {
    let apiClient = new ApiClient(this.context);

    apiClient
      .call("POST", "/v1/WCApi/ClonePrice", { quoteId: this.props.reorderId })
      .then((response) => {
        let data = response.json;

        if (response.ok) {
          this.getCalculation(data.id);
        } else {
          this.props.onDismiss();
        }
      });
  }

  calculatePrice() {
    let url = "/v1/WCApi/CalculatePrice";
    let data = new FormData();
    let parts = this.props.parts
      .filter((part) => part.dxfFileInfo !== undefined)
      .map((part) => {
        return {
          collectionId: part.collectionId,
          fileId: part.fileId,
          materialThicknessId: part.material ? part.material.id : null,
          amount: part.amount,
          bending: part.bending,
          counterSinking: part.counterSinking,
          surfaceTreatment: part.surfaceTreatment,
          engraving: part.engraving,
          materialCertificate: part.materialCertificate,
          partFile: part.partFile,
          length: part.dxfFileInfo.threeDimensional ? part.dxfFileInfo.threeDimensional.boundary.x : part.dxfFileInfo.basicInformation.length,
          width: part.dxfFileInfo.threeDimensional ? part.dxfFileInfo.threeDimensional.boundary.y : part.dxfFileInfo.basicInformation.width,
          tapping: part.tapping
        };
      });

    buildFormData(data, parts, "files");
    buildFormData(data, this.props.account.id, "accountId");
    buildFormData(data, !!this.props.unattended, "isUnattendedCalculation");

    new ApiClient(this.context).call("POST", url, data).then((response) => {
      let data = response.json;

      if (!response.ok) {
        this.props.validationCallback(data.errors);
        this.props.onDismiss();
      } else if (this.props.unattended) {
        this.props.reset && this.props.reset();
        this.setState({ unattended: true });
      } else {
        this.props.reset && this.props.reset();
        this.getCalculation(data.id);
      }
    });
  }

  mapCalculatePriceResult(data) {
    let tl = this.context.translate;

    return this.setStateAsync({
      quote: data,
      quoteId: data.id,
      parts: data.parts.map((part) => {
        var dimensions = [];
        let dxfFileInfo = part.calculatePriceResult.dxfFileInfo;

        if (dxfFileInfo.threeDimensional) {
          dimensions = [
            dxfFileInfo.threeDimensional.boundary.x,
            dxfFileInfo.threeDimensional.boundary.y,
            dxfFileInfo.threeDimensional.boundary.z,
          ];
        } else {
          dimensions = [
            dxfFileInfo.basicInformation.length,
            dxfFileInfo.basicInformation.width,
          ];
        }

        dimensions = dimensions
          .map((f) => Math.round(f))
          .filter((s) => s)
          .join("x");

        return {
          ...part,
          material: part.material.materialName,
          thickness: part.material.thickness + " " + tl('general_mm'),
          dimensions: dimensions + " " + tl('general_mm'),
          qualities: [],
        };
      }),
    }).then(() => {
      this.getContacts();
      this.getDeliveryOptions();
      this.getDeliveryTime();
    });
  }

  updatePart(fileId, e) {
    let key = e.target.name;
    let val = e.target.value;

    this.setState({
      parts: [
        ...this.state.parts.map((part) => ({
          ...part,
          [key]:
            part.calculatePriceResult.file.fileId === fileId ? val : part[key],
        })),
      ],
    });
  }

  deletePart(fileId, e) {
    return this.setStateAsync((state) => ({
      parts: state.parts.filter(
        (p) => p.calculatePriceResult.file.fileId !== fileId
      ),
    }));
  }

  createQuoteRequestModel() {
    let quoteRequestModel = {
      id: this.state.quoteId,
      title: this.state.quote.title,
      description: this.state.quote.description,
      tenantDeliverySettingsId: this.state.quote.tenantDeliverySettings.id,
      tenantDeliveryTimeId: this.state.quote.tenantDeliveryTime.id,
      discount: this.state.quote.discount,
      accountId: this.state.quote.account.id,
      contactId: this.state.quote.contact.id,
      parts: this.state.parts.map((part) => ({
        id: part.id,
        note: part.note,
        amount: part.amount,
      })),
    };

    return quoteRequestModel;
  }

  saveToQuotes() {
    let quoteRequestModel = this.createQuoteRequestModel();
    quoteRequestModel.status = "OPEN";

    let apiClient = new ApiClient(this.context);

    apiClient
      .call("PUT", "/v1/Quote/" + this.state.quoteId, quoteRequestModel)
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.refreshData && this.props.refreshData();
          this.context.addNotification(
            "success",
            this.context.translate('notification_save_quotation_success')
          );
        } else {
          throw result;
        }
      });
  }

  placeOrder() {
    let tl = this.context.translate;

    this.askConfirmation(
      "",
      tl('warning_ordering_quote'),
      tl('general_order_button')
    ).then((confirmed) => {
      if (!confirmed) return;

      let quoteRequestModel = this.createQuoteRequestModel();
      quoteRequestModel.status = "ORDERED";

      let apiClient = new ApiClient(this.context);

      apiClient
        .call("PUT", "/v1/Quote/" + this.state.quoteId, quoteRequestModel)
        .then((result) => {
          if (result.ok) {
            this.props.onDismiss();
            this.props.refreshData && this.props.refreshData();
            this.context.addNotification(
              "success",
              this.context.translate('notification_order_quotation_success')
            );
            if (this.props.refreshData) {
              this.props.refreshData();
            }
          } else {
            throw result;
          }
        });
    });
  }

  recalculateQuote() {
    if (!this.state.quote) return Promise.resolve();

    let quoteRequestModel = this.createQuoteRequestModel();
    let apiClient = new ApiClient(this.context);

    return this.setStateAsync({
      quote: null,
    }).then(() => {
      return apiClient
        .call(
          "PUT",
          `/v1/WCApi/CalculatePrice/${this.state.quoteId}`,
          quoteRequestModel
        )
        .then((result) => {
          if (result.ok) {
            this.getCalculation(this.state.quoteId);

            // this.context.addNotification(
            //   "success",
            //   this.context.translate('notification_recalculate_success')
            // );
          } else {
            throw result;
          }
        });
    });
  }

  toggleInformationPopup() {
    this.setState({
      informationPopupShown: !this.state.informationPopupShown,
    });
  }

  togglePendingPopup() {
    if (this.state.pendingPopupShown) {
      this.props.onDismiss();
    }

    this.setState({
      pendingPopupShown: !this.state.pendingPopupShown,
    });
  }

  updateQuoteProperty(key, val) {
    let isAccount = key === "account";

    this.setStateAsync((state) => ({
      quote: {
        ...state.quote,
        contact: isAccount ? {} : state.quote.contact,
        [key]: val,
      },
    })).then(() => {
      if (isAccount) {
        this.getContacts();
      }
    });
  }

  onSelectChange(options, fallback, e) {
    let key = e.target.name;
    let id = e.target.value;
    let val = options.find((o) => o.id === id) || fallback;

    this.updateQuoteProperty(key, val);
  }

  onInputChange(e) {
    let key = e.target.name;
    var val = e.target.value;

    if (key === "discount") {
      let cost = this.getManufacturingCostInCents() / 100;
      val = Math.max(0, Math.min(cost, val));
    }

    this.updateQuoteProperty(key, val);
  }

  downloadPdf() {
    let quote = this.props.quote;
    let tl = this.context.translate;

    if (!quote.pdfFile) {
      this.context.addNotification(
        "error",
        tl('notifications_download_unavailable')
      );
      return;
    }

    this.context.addNotification(
      "info",
      tl('notifications_download_started')
    );

    new ApiClient(this.context)
      .call("GET", quote.pdfFile.filePath)
      .then((response) => {
        this.apiResponseAsDownload(response, `${quote.number}.pdf`);
      });
  }

  getDeliveryDate() {
    let date = moment(this.state.quote.transportDeliveryDate);

    return date.format("DD/MM/YYYY");
  }

  getTransportCostInCents() {
    return this.state.quote.tenantDeliverySettings.calculateDeliveryCosts
      && Math.round(this.state.quote.transportPrice * 100)
      || 0;
  }

  getDiscountInCents() {
    return Math.round(this.state.quote.discount * 100);
  }

  getManufacturingCostInCents() {
    let index = this.state.quote.tenantDeliveryTime.index;
    let price = this.state.quote.price ? this.state.quote.price[index] : 0.0;

    return Math.round(price * 100) || 0;
  }

  getDiscountedPriceInCents() {
    return this.getManufacturingCostInCents() + this.getTransportCostInCents() - this.getDiscountInCents();
  }

  getTotalInCents() {
    return Math.round(this.getDiscountedPriceInCents() + this.getVatInCents());
  }

  getVatPercentage() {
    let percentage =
      (this.state.quote && this.state.quote.account.vatPercentage) || 0;

    return percentage;
  }

  getVatInCents() {
    return Math.round(
      (this.getDiscountedPriceInCents() * this.getVatPercentage()) / 100
    );
  }

  renderButtons() {
    let tl = this.context.translate;
    let isOrder = !!this.props.quote && !!this.props.quote.order;
    let isFailed = this.state.quote.status === "FAILED";
    let needsRecalculate =
      this.state.quote.status === "OVERDUE" ||
      this.state.quote.parts.some((originalPart) => {
        let currentPart =
          this.state.parts.find((part) => part.id === originalPart.id) || {};

        return currentPart.amount !== originalPart.amount;
      });

    return (
      <>
        {isFailed && (
          <button
            type="button"
            className="popup-popupButton"
            onClick={this.props.onDismiss}
          >
            {tl('general_ok')}
          </button>
        )}
        {!isOrder && needsRecalculate && !isFailed && (
          <button
            type="button"
            className="popup-popupButton"
            onClick={this.recalculateQuote.bind(this)}
          >
            {tl('quotation_recalculate_button')}
          </button>
        )}
        {!isOrder && !needsRecalculate && !isFailed && (
          <button
            type="submit"
            className="popup-popupButton"
            onClick={this.saveToQuotes.bind(this)}
          >
            {tl('quotation_save_to_quotes_button')}
          </button>
        )}
        {!isOrder && !needsRecalculate && !isFailed && (
          <button
            type="button"
            className="popup-popupButton"
            onClick={this.placeOrder.bind(this)}
          >
            {tl('quotation_place_order_button')}
          </button>
        )}

        {(this.props.order && this.props.quote) && (
          <button
            type="button"
            className="popup-popupButton"
            onClick={this.downloadPdf.bind(this)}
          >
            {tl('general_download_pdf')}
          </button>
        )}
      </>
    );
  }

  render() {
    let tl = this.context.translate;
    let user = this.context.user;
    let isPurchaseManager = user && user.role.name === "PurchaseManager";
    let isOrder = !!this.props.quote && !!this.props.quote.order;
    let currencyFormatter = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    });
    let title = this.state.quote &&
      `${this.state.quote.status === "ORDERED" ? tl('order_title') : tl('quotation_title')}: ${this.state.quote.number}`

    return (
      <Popup
        title={title}
        onDismiss={this.props.onDismiss}
      // headerItem={
      //   <div className="quotationPopup-header">
      //     <h2>
      //       {tl('general_status')}:
      //     </h2>
      //     <Dropdown></Dropdown>
      //   </div>
      // }
      >
        {!this.state.quote ? (
          !this.state.unattended ? (
            <Loader />
          ) : (
            <div className="quotationPopup-unattendedMessage">{tl('calculation_unattended_confirm')}</div>
          )
        ) : (
          <div className="quotationPopup-container">
            <div className="quotationPopup-informationContainer">
              <div className="quotationPopup-informationContainer-accountContainer">
                <h2>{tl('quotation_account_information_title')}</h2>
                <div className="horizontalLine"></div>
                <div className="popup-inputContainer smallWidth">
                  <select
                    disabled={true}
                    name="account"
                    onChange={this.onSelectChange.bind(
                      this,
                      this.state.accounts,
                      {}
                    )}
                    value={this.state.quote.account.id}
                  >
                    <option key={0xdeadbeef}></option>
                    {this.state.accounts.map((account) => (
                      <option key={account.id} value={account.id}>
                        {account.name}
                      </option>
                    ))}
                  </select>
                  <select
                    disabled={isOrder}
                    name="contact"
                    onChange={this.onSelectChange.bind(
                      this,
                      this.state.contacts,
                      {}
                    )}
                    value={this.state.quote.contact.id}
                  >
                    <option key={0xdeadbeef}></option>
                    {this.state.contacts.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.fullName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="popup-inputContainer fullWidth">
                  <input
                    disabled={isOrder}
                    name="title"
                    value={this.state.quote.title}
                    onChange={this.onInputChange.bind(this)}
                    placeholder={tl('quotes_project_reference')}
                  ></input>
                </div>
                <div className="popup-inputContainer fullWidth">
                  <input
                    disabled={isOrder}
                    name="description"
                    value={this.state.quote.description}
                    onChange={this.onInputChange.bind(this)}
                    placeholder={tl('quotation_note_text')}
                  ></input>
                </div>
                {this.state.quote.contact && (
                  <div className="accountInformationContainer">
                    <div className="accountInformationContainer-rowContainer">
                      <div className="accountInformationContainer-information">
                        {tl('general_name')}:
                      </div>
                      <div className="accountInformationContainer-data">
                        {this.state.quote.contact.fullName}
                      </div>
                    </div>
                    <div className="accountInformationContainer-rowContainer">
                      <div className="accountInformationContainer-information">
                        {tl('general_address')}:
                      </div>
                      <div className="accountInformationContainer-data">
                        {this.state.quote.contact.address}
                      </div>
                    </div>
                    <div className="accountInformationContainer-rowContainer">
                      <div className="accountInformationContainer-information">
                        {tl('general_country')}:
                      </div>
                      <div className="accountInformationContainer-data">
                        {this.state.quote.contact.country}
                      </div>
                    </div>
                    <div className="accountInformationContainer-rowContainer">
                      <div className="accountInformationContainer-information">
                        {tl('general_vat')}:
                      </div>
                      <div className="accountInformationContainer-data">
                        {`${this.getVatPercentage()}%`}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="quotationPopup-informationContainer-shippingContainer">
                <h2>{tl('quotation_shipping_title')}</h2>
                <div className="horizontalLine"></div>
                <div className="quotationPopup-informationContainer-shippingContainer-row popup-inputContainer smallWidth">
                  <select
                    disabled={isOrder}
                    name="tenantDeliverySettings"
                    value={this.state.quote.deliverySetting}
                    onChange={this.onSelectChange.bind(
                      this,
                      this.state.deliveryOptions,
                      {}
                    )}
                  >
                    {this.state.deliveryOptions.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                        selected={option.id === this.state.quote.tenantDeliverySettings.id}
                      >
                        {option.description}
                      </option>
                    ))}
                  </select>
                </div>
                <h2>{tl('quotation_delivery_time_title')}</h2>
                <div className="horizontalLine"></div>
                <div className="quotationPopup-informationContainer-shippingContainer-row popup-inputContainer smallWidth">
                  <input className="quotationPopup-deliveryTimes" disabled name="deliveryTimes"
                    value={this.getDeliveryDate()} />
                  <select
                    disabled={isOrder}
                    name="tenantDeliveryTime"
                    value={this.state.quote.deliveryTime}
                    onChange={this.onSelectChange.bind(
                      this,
                      this.state.deliveryTimes,
                      {}
                    )}
                  >
                    {this.state.deliveryTimes.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                        selected={option.id === this.state.quote.tenantDeliveryTime.id}
                      >
                        {option.description}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="quotationPopup-informationContainer-calculationContainer">
                <div className="informationPopupContainer">
                  {!isPurchaseManager && (
                    <button
                      className="informationPopupContainer-button"
                      onClick={this.toggleInformationPopup.bind(this)}
                    >
                      <svg
                        id="informationButton"
                        data-name="Component 57 – 8"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 10"
                      >
                        <circle
                          id="Ellipse_2"
                          data-name="Ellipse 2"
                          cx="5"
                          cy="5"
                          r="5"
                          fill="#306bff"
                        />
                        <text
                          id="i"
                          transform="translate(6 7)"
                          fill="#fff"
                          fontSize="7"
                          fontFamily="Karla-Bold, Karla"
                          fontWeight="700"
                        >
                          <tspan x="-2.296" y="0">
                            i
                          </tspan>
                        </text>
                      </svg>
                    </button>
                  )}
                </div>
                <span className="calculationContainer-information">
                  {tl('quotation_manufacturing')}:
                </span>
                <span className="calculationContainer-data">
                  {currencyFormatter.format(
                    this.getManufacturingCostInCents() / 100
                  )}
                </span>
                {this.getTransportCostInCents() > 0 && (
                  <>
                    <span className="calculationContainer-information">
                      {tl('quotation_transport')}:
                    </span>
                    <span className="calculationContainer-data">
                      {currencyFormatter.format(this.getTransportCostInCents() / 100)}
                    </span>
                  </>
                )}
                {((isPurchaseManager && this.state.quote.discount > 0) ||
                  !isPurchaseManager) && (
                    <div style={{ display: "contents" }}>
                      <span className="calculationContainer-information">
                        {tl('quotation_discount')}:
                      </span>
                      <span className="calculationContainer-data">
                        <span className="inputPrefix">€ </span>
                        <input
                          name="discount"
                          type="number"
                          step="0.01"
                          disabled={isPurchaseManager}
                          value={this.state.quote.discount}
                          onChange={this.onInputChange.bind(this)}
                        ></input>
                      </span>
                    </div>
                  )}
                <span className="calculationContainer-information">
                  {tl('general_vat')}:
                </span>
                <span className="calculationContainer-data">
                  {currencyFormatter.format(this.getVatInCents() / 100)}
                </span>
                <div className="horizontalLine"></div>
                <span className="calculationContainer-information">
                  {tl('quotation_total')}:
                </span>
                <span className="calculationContainer-data">
                  {currencyFormatter.format(this.getTotalInCents() / 100)}
                </span>

                <div className="calculationContainer-buttonContainer-desktop">
                  {this.renderButtons()}
                </div>
              </div>
            </div>
            <div className="quotationPopup-partsContainer">
              <div className="quotationPopup-partsContainer-headerContainer">
                <h2 className="quotationPopup-partsContainer-parts">
                  {tl('quotation_part_and_specification_title')}
                </h2>
                <h2 className="quotationPopup-partsContainer-price desktopText">
                  {tl('general_price')}
                </h2>
                <h2 className="quotationPopup-partsContainer-amount desktopText">
                  {tl('general_amount')}
                </h2>
                <h2 className="quotationPopup-partsContainer-total desktopText">
                  {tl('quotation_total')}
                </h2>
              </div>
              <div className="horizontalLine"></div>
              <div className="quotationPopup-partListContainer">
                {this.state.parts &&
                  this.state.parts.map((part) => (
                    <QuotationListItem
                      {...part}
                      key={part.calculatePriceResult.file.fileId}
                      quote={this.state.quote}
                      isOrder={isOrder}
                      supportedOperations={this.state.supportedOperations}
                      updatePart={this.updatePart.bind(
                        this,
                        part.calculatePriceResult.file.fileId
                      )}
                      deletePart={this.deletePart.bind(
                        this,
                        part.calculatePriceResult.file.fileId
                      )}
                    />
                  ))}
                {!this.state.parts && <Loader />}
              </div>
            </div>
            <div className="calculationContainer-buttonContainer-mobile">
              {this.renderButtons()}
            </div>
          </div>
        )}

        {this.state.informationPopupShown && this.state.quote.parts.length > 0 && (
          <CalculationOverview
            onDismiss={this.toggleInformationPopup.bind(this)}
            quote={this.state.quote}
          />
        )}
        {this.state.pendingPopupShown && (
          <PendingPopup
            onDismiss={this.togglePendingPopup.bind(this)}
          />
        )}
      </Popup>
    );
  }
}

export default QuotationPopup;

import { Component } from "react";
import ApiClient from "../../../api-client/api-client";
import { buildFormData } from "../../../helpers";
import AppContext from "../../app-context";

import Popup from "../../popup/popup";
import "./mail-popup.css";

class MailPopup extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      tenantEmail: "",
      recipientEmail: "",
    }
  }

  componentDidMount() {
    if(this.props.quote) {
      this.setState((state) => ({
        tenantEmail: this.props.quote.account.email,
      }));
    }
  }

  sendEmail(quoteId) {
    let url = `/v1/Quote/${quoteId}/SendPdfEmail`;
    let data = {
      tenantEmail: this.state.tenantEmail,
      userEmail: this.state.recipientEmail,
    };

    new ApiClient(this.context).call("POST", url, data).then((response) => {
      let data = response.json;

      if (response.ok) {
        this.props.onDismiss();
        this.context.addNotification(
          "success",
          this.context.translate('notifications_email_send_success'),
        );
      } else {
        this.props.onDismiss();
      }
    });
  }

  onInputChange(e) {
    let key = e.target.name;
    var value = e.target.value;

    this.setState((state) => ({
      [key]: value,
    }));
  }

  render() {
    let tl = this.context.translate;

    return(
      <Popup
        title={tl('general_send_mail')}
        onDismiss={this.props.onDismiss}
      >
        <div className="mailPopup-container">
          <div className="popup-inputContainer">
            <h2>{tl('general_tenant_email')}</h2>
            <input 
              name="tenantEmail" 
              value={this.state.tenantEmail} 
              onChange={this.onInputChange.bind(this)}
            ></input>
          </div>
          <div className="popup-inputContainer">
            <h2>{tl('general_recipient_email')}</h2>
            <input 
              name="recipientEmail" 
              value={this.state.recipientEmail} 
              onChange={this.onInputChange.bind(this)}
            ></input>
          </div>
          <div className="popup-section popup-buttonSection">
            <button
              type="button"
              className="popup-popupButton"
              onClick={() => this.sendEmail(this.props.quote.id)}
            >
              {tl('general_send')}
            </button>
          </div>
        </div>
      </Popup>
    );
  }
}

export default MailPopup;
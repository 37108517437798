import { Component } from "react";
import AppContext from "../../app-context";
import './buttons.css';

class CalculateButton extends Component {
  static contextType = AppContext;
  render() {
    let tl = this.context.translate;

    return (
      <span>
        <svg id='svgCalculate' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
          <circle className='svgCalculate-1' cx="17" cy="17" r="17" />
          <path className='svgCalculate-2' d="M16.661,15.762v4.054H5.851V3.6h6.756V2.25H5.851A1.351,1.351,0,0,0,4.5,3.6V19.816a1.351,1.351,0,0,0,1.351,1.351h10.81a1.351,1.351,0,0,0,1.351-1.351V15.762Z" transform="translate(5.225 5.291)" />
          <path className='svgCalculate-2' data-name="Path 347" d="M24.452,4.792,22.223,2.562a1.081,1.081,0,0,0-1.513,0l-9.459,9.459v3.743h3.736l9.459-9.459a1.081,1.081,0,0,0,0-1.513ZM14.425,14.412H12.6V12.588L18.979,6.2,20.81,8.035Zm7.337-7.33L19.932,5.251l1.534-1.534L23.3,5.548Z" transform="translate(2.527 5.29)" />
        </svg>
        <span>{tl('general_calculate')}</span>
      </span>
    );
  }
}

export default CalculateButton;

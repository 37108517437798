import { Component } from "react";
import AppContext from "../../app-context";

import Popup from "../../popup/popup";

import "./filter-popup.css";

class FilterPopup extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      ...props.filters
    };
  }

  onInputChange(e) {
    let key = e.target.name;
    let val = e.target.value || undefined;

    this.setState({
      [key]: val,
    });
  }

  saveFilter() {
    this.props.updateFilters(this.state);
    this.props.onDismiss();
  }

  clearFilter() {
    this.props.updateFilters({});
    this.props.onDismiss();
  }

  renderFilter(k, map) {
    if (map.options) {
      return <select
        name={k}
        value={this.state[k]}
        onChange={this.onInputChange.bind(this)}
      >
        <option></option>
        {map.options.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>;
    }
    else {
      return <input
        name={k}
        value={this.state[k]}
        onChange={this.onInputChange.bind(this)}
      ></input>
    }

  }

  renderFilters() {
    return Object.entries(this.props.mapping)
      .map(([k, map]) => {
        return map && <div className="popup-column">
          <div className="popup-inputContainer">
            <h2>{map.label}</h2>
            {this.renderFilter(k, map)}
          </div>
        </div>;
      });
  }

  render() {
    let tl = this.context.translate;
    return (
      <Popup
        title={tl('general_filter')}
        actions={[
          {
            label: tl('general_delete'),
            handler: this.clearFilter.bind(this),
          },
          {
            label: tl('general_filter'),
            handler: this.saveFilter.bind(this),
            submit: true,
          },
        ]}
        onDismiss={this.props.onDismiss}
      >
        <div className="filterPopup">
          <div className="popup-section">
            {this.renderFilters()}
          </div>
        </div>
      </Popup>
    );
  }
}

export default FilterPopup;

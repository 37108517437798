import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";

import Popup from "../../app/popup/popup";

import "./import-order-line-popup.css";

class ImportOrderLinePopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {};
  }

  preparePostData(obj) {
    let data = new FormData();

    for (const [k, v] of Object.entries(obj)) {
      if (v) {
        data.append(k, v);
      }
    }

    return data;
  }

  uploadFile(e) {
    let tl = this.context.translate;
    var value = e.target.files[0];

    if (!value) return;

    let data = this.preparePostData({
      ExcelFile: value,
    });
    e.target.value = "";

    this.askConfirmation(
      tl('notification_are_you_sure'),
      tl('warning_direct_file_upload'),
    )
      .then((confirm) => {
        if (!confirm) return;

        let apiClient = new ApiClient(this.context);

        apiClient
          .call(
            "POST",
            `/v1/Quote/ImportOrderLines`,
            data
          )
          .then((result) => {
            if (result.ok) {
              this.context.addNotification(
                "success",
                tl('notification_upload_file_success')
              );
              this.props.didReceiveOrderLines(result.json);
              this.props.onDismiss();
            } else {
              throw result;
            }
          });
      });
  }

  render() {
    return (
      // TODO: I18n
      <Popup
        title={'Import Order Lines'}
        onDismiss={this.props.onDismiss}
      >
        <div className="importOrderLinePopup">
          <div className="popup-column">
            <div className="popup-inputContainer">
              <input
                name="UploadLogo"
                type="file"
                onChange={this.uploadFile.bind(this)}
              ></input>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

export default ImportOrderLinePopup;

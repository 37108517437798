import { Component } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../app/app-context";
import ApiClient from "../../api-client/api-client";

class LoginPage extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
    };
  }


  login() {
    new ApiClient(this.context)
      .call(
        "POST",
        "/connect/token",
        {
          username: `${this.context.theme.tenantId}\\${this.state.email}`,
          password: this.state.password,
          client_id: this.context.settings.apiClient,
          client_secret: this.context.settings.apiSecret,
          grant_type: "password",
        },
        true
      )
      .then((result) => {
        if (result.ok) {
          let json = result.json;
          this.getUserData(json["access_token"]);
        }
      });
  }

  getUserData(bearerToken) {
    new ApiClient(this.context)
      .call("GET", "/v1/Contact/GetCurrent", null, false, {
        Authorization: "Bearer " + bearerToken,
      })
      .then((result) => {
        if (result.ok) {
          let user = result.json;

          this.props
            .setUser({
              ...user,
              bearerToken: bearerToken,
            })
            .then(() => {
              this.context.addNotification(
                "success",
                this.context.translate('notifications_login_successfull')
              );
            });
        }
      });
  }

  onInputChange(e) {
    let key = e.target.name;
    this.setState({
      [key]: e.target.value,
    });
  }

  render() {
    let tl = this.context.translate;

    return (
      <div className="popup-section">
        <div className="popup-column">
          <div className="popup-inputContainer">
            <h2>{tl('login_email_label')}</h2>
            <input
              placeholder={tl('login_email_placeholder')}
              name="email"
              value={this.state.email}
              onChange={this.onInputChange.bind(this)}
            ></input>
          </div>
          <div className="popup-inputContainer">
            <h2>{tl('login_password_label')}</h2>
            <input
              placeholder={tl('login_password_placeholder')}
              type="password"
              name="password"
              value={this.state.password}
              onChange={this.onInputChange.bind(this)}
            ></input>
          </div>
          <div className="popup-linkContainer">
            <Link to="/password-request">
              {tl('login_forgot_password')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;

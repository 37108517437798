import React from "react";
import ApiClient from "../../../api-client/api-client";
import AppContext from "../../../app/app-context";
import BaseComponent from "../../../app/base-component";
import FileDownload from "../../../app/file-download/file-download";

import "./quotation-list-item.css";

class QuotationListItem extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      preview: null,
    };
  }

  getFilePreview() {
    let url = "/v1/WCApi/GetFilePreview";

    new ApiClient(this.context)
      .call(
        "GET",
        url,
        null,
        false,
        {},
        {
          collectionId: this.props.calculatePriceResult.file.collectionId,
          fileId: this.props.calculatePriceResult.file.fileId,
          format: "png",
        }
      )
      .then((response) => {
        this.setState({
          preview: response.json.image,
        });
      });
  }

  componentDidMount() {
    this.getFilePreview();
  }

  getPriceInCents() {
    let index = this.props.quote.tenantDeliveryTime.index;
    let price = this.props.price ? this.props.price[index] : 0.0;

    return Math.round(price * 100) || 0;
  }

  getTotalPriceInCents() {
    let index = this.props.quote.tenantDeliveryTime.index;
    let price = this.props.totalPrice ? this.props.totalPrice[index] : 0.0;

    return Math.round(price * 100) || 0;
  }

  render() {
    let tl = this.context.translate;
    let part = this.props;
    let isOrder = part.isOrder;
    let currencyFormatter = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' });
    let name = part.calculatePriceResult.fileInfo.file.name;
    let removedOperations = part.removedOperations ?
      part.removedOperations
        .map(ro => this.props.supportedOperations.find(so => so.value === ro))
        .filter(ro => ro)
      : [];
    let partFile = part.partFile;
    let tappingFile = part.tapping && part.tapping.tappingFile;

    return (
      <div className="quotationPopup-part">
        <div className="quotationPopup-part-header">
          <h3>
            {tl('general_part')}:{" "}
            {name}
          </h3>
          {!part.isOrder && <button id="delete-btn" onClick={this.props.deletePart}>
            <svg
              id="delete-btn-svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 15.344 18"
            >
              <path
                className="delete-btn-svg-1"
                d="M13.5,13.5h1.279v7.672H13.5Z"
                transform="translate(-8.385 -7.045)"
              />
              <path
                className="delete-btn-svg-1"
                d="M20.25,13.5h1.279v7.672H20.25Z"
                transform="translate(-11.299 -7.045)"
              />
              <path
                className="delete-btn-svg-1"
                d="M4.5,6.75V8.037H5.779V20.906a1.283,1.283,0,0,0,1.279,1.287H17.287a1.283,1.283,0,0,0,1.279-1.287V8.037h1.279V6.75ZM7.057,20.906V8.037H17.287V20.906Z"
                transform="translate(-4.5 -4.193)"
              />
              <path
                className="delete-btn-svg-1"
                d="M13.5,2.25h5.115V3.529H13.5Z"
                transform="translate(-8.385 -2.25)"
              />
            </svg>
          </button>}
        </div>
        <div className="horizontalLine"></div>
        <div className="quotationPopup-part-main">
          <div className="quotationPopup-partsContainer-parts">
            <div className="quotationPopup-partsContainer-imageContainer">
              <img
                src={"data:image/png;base64," + this.state.preview}
                alt="preview"
              ></img>
            </div>
            <div className="quotationPopup-partsContainer-informationNoteContainer">
              <div className="quotationPopup-partsContainer-informationContainer">
                <div className="quotationPopup-partsContainer-general">
                  <h4>{tl('general_material')}:</h4>
                  <div>{part.material}</div>
                  <h4>{tl('general_thickness')}:</h4>
                  <div>{part.thickness}</div>
                  <h4>{tl('general_dimensions')}:</h4>
                  <div>{part.dimensions}</div>
                </div>
                <div className="quotationPopup-partsContainer-operations">
                  <h4>{tl('general_operations')}:</h4>
                  <div>
                    {this.getPartOperations(part).map((operation) => {
                      return <div key={operation}>{operation}</div>;
                    })}
                  </div>
                  {(partFile || tappingFile) && <>
                    <h4>{tl('general_partfile')}:</h4>
                    {partFile &&
                      <div>
                        <FileDownload file={partFile} fileName={name} />
                      </div>
                    }
                    {tappingFile &&
                      <div>
                        <FileDownload file={tappingFile} fileName={`${name} - ${tl('calculate_tapping')}`} />
                      </div>
                    }
                  </>}
                  {removedOperations && removedOperations.length > 0 &&
                    <div className="quotationPopup-partsContainer-removedOperations">
                      <h4>{tl('quotation_removed_operations_title')}</h4>
                      <div>
                        {removedOperations.map((operation) => {
                          return <div key={operation}>{operation.label}</div>;
                        })}
                      </div>
                    </div>
                  }
                </div>
                {/* <div className="quotationPopup-partsContainer-quality">
                  <h4>{tl('general_quality')}:</h4>
                  <div>
                    {this.props.qualities.map((quality) => {
                      return <div key={quality}>{quality}</div>;
                    })}
                  </div>
                </div> */}
              </div>
              <div className="quotationPopup-partsContainer-noteContainer">
                <h4>{tl('quotation_note_title')}:</h4>
                <input
                  disabled={isOrder}
                  name="note"
                  value={part.note}
                  onChange={this.props.updatePart}
                  placeholder={tl('quotation_note_text') + "..."}
                ></input>
              </div>
            </div>
          </div>
          <div className="quotationPopup-partsContainer-priceAmountTotalWrapper">
            <div className="quotationPopup-partsContainer-price quotationPopup-part-moreTopMargin">
              <span className="quotationPopup-partsContainer-mobileText">{tl('general_price')} </span>
              {currencyFormatter.format(this.getPriceInCents() / 100)}
            </div>
            <div className="quotationPopup-partsContainer-amount quotationPopup-part-moreTopMargin">
              <span className="quotationPopup-partsContainer-mobileText">{tl('general_amount')} </span>
              <input
                disabled={isOrder}
                name="amount"
                value={part.amount}
                onChange={this.props.updatePart}>
              </input>
            </div>
            <div className="quotationPopup-partsContainer-total quotationPopup-part-moreTopMargin">
              <span className="quotationPopup-partsContainer-mobileText">{tl('quotation_total')} </span>
              {currencyFormatter.format(this.getTotalPriceInCents() / 100)}
            </div>
          </div>
        </div>
        {/* {part.subParts.map(subPart =>
          <QuotationListSubItem
            {...subPart}
            key={subPart.fileId}

            togglePartSelected={this.props.togglePartSelected}
          />
        )} */}
      </div>
    );
  }
}

export default QuotationListItem;

import moment from "moment";

import AppContext from "../app/app-context";
import BaseComponent from "../app/base-component";
import ApiClient from "../api-client/api-client";

import GenericPage from "../app/generic-page/generic-page";
import Table from "../app/table/table";

import FilterButton from "../app/generic-page/buttons/filter-button";

import QuotationPopup from "../calculation/quotation-popup/quotation-popup";

import "./quote-management-page.css";
import QuoteStateSelector from "./quote-state-selector/quote-state-selector";
import TableActionDelete from "../app/table/actions/table-action-delete";
import QueryFilterPopup from "../app/table/filter-popup/query-filter-popup";
import TableActionSave from "../app/table/actions/table-action-save";
import TableActionRecalculate from "../app/table/actions/table-action-recalculate";
import TableActionMail from "../app/table/actions/table-action-mail";
import MailPopup from "../app/table/mail-popup/mail-popup";

class QuoteManagementPage extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      quotePopupShown: false,
      filterPopupShown: false,
      mailPopupShown: false,
      quotes: null,
      selectedQuote: null,
      accounts: [],
    };
  }

  updateFilterQuery(filterQuery) {
    this.setStateAsync({
      filterQuery,
    }).then(this.refreshData.bind(this));
  }

  updateSortQuery(sortQuery) {
    this.setStateAsync({
      sortQuery,
    }).then(this.refreshData.bind(this));
  }

  componentDidMount() {
    this.getQuoteStates()
      .then(() => this.getAccounts())
      .then(() => this.refreshData());
  }

  getQuoteStates() {
    return this.setStateAsync({
      quoteStates: [
        {
          tag: "OPEN",
          label: "quote_status_open",
        },
        {
          tag: "OVERDUE",
          label: "quote_status_overdue",
          theme: "red",
          disabled: true,
        },
        {
          tag: "PENDING",
          label: "quote_status_pending",
          theme: "yellow",
          disabled: true,
        },
        {
          tag: "ARCHIVED",
          label: "quote_status_archived",
        },
        {
          tag: "FAILED",
          label: "quote_status_failed",
          theme: "red",
          disabled: true,
        },
      ],
    });
  }

  getAccounts() {
    if (this.context.userHasClaim('Accounts', 'View')) {
      return new ApiClient(this.context)
        .call(
          "GET",
          `/v1/Tenant/${this.context.theme.tenantId}/Account`,
          null,
          false,
          {},
          {
            Sorts: "name",
            Filters: "Active==true",
          }
        )
        .then((response) => {
          let accounts = response.json;

          return this.setStateAsync({
            accounts: accounts,
          });
        });
    }
    else {
      return this.setStateAsync({
        accounts: [
          this.context.user.account
        ],
      });
    }
  }

  refreshData() {
    var sortQuery = this.state.sortQuery || "";
    var filterQuery = this.state.filterQuery || "";

    return new ApiClient(this.context)
      .call(
        "GET",
        "/v1/Quote",
        null,
        false,
        {},
        {
          Sorts: sortQuery,
          Filters: filterQuery,
        }
      )
      .then((response) => {
        if (response.ok) {
          let quotes = response.json;

          this.setStateAsync((state) => ({
            quotes: quotes,
          }));
        }
      });
  }

  toggleEditQuotePopup(quote, recalculate = false) {
    if (!quote) {
      this.setState((state) => ({
        quotePopupShown: !state.quotePopupShown,
        selectedQuote: quote,
        recalculate: recalculate,
      }));

      return;
    }

    if (quote.status === 'OVERDUE' && !recalculate) {
      let tl = this.context.translate;
      this.context.addNotification('error', tl('quote_overdue_error'));

      return;
    }

    this.setState((state) => ({
      quotePopupShown: !state.quotePopupShown,
      selectedQuote: quote,
      recalculate: recalculate,
    }));
  }

  toggleFilterPopup() {
    this.setState((state) => ({
      filterPopupShown: !state.filterPopupShown,
    }));
  }

  toggleSendMailPopup(quote) {
    this.setState((state) => ({
      mailPopupShown: !state.mailPopupShown,
      selectedQuote: quote,
    }));
  }

  renderButtons() {
    return (
      <div>
        <FilterButton onClick={this.toggleFilterPopup.bind(this)} />
      </div>
    );
  }

  renderTable() {
    let tl = this.context.translate;
    let currencyFormatter = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' });

    return (
      <Table
        mapping={{
          number: {
            label: tl('quotes_quotes_number'),
            sortKey: "Number",
          },
          account: {
            label: tl('general_client'),
            value: (quote) => quote.account.name,
            sortKey: "AccountName",
          },
          contact: {
            label: tl('general_contact'),
            value: (quote) => quote.contact.fullName,
            sortKey: "ContactName",
          },
          title: {
            label: tl('quotes_project_reference'),
            sortKey: "Title",
          },
          createdAt: {
            label: tl('general_date'),
            value: (quote) => moment(quote.createdAt).format("DD-MM-YYYY"),
            sortKey: "CreatedAt",
          },
          price: {
            label: tl('general_price'),
            value: (quote) => currencyFormatter.format(quote.price),
            sortKey: "Price",
          },
          status: {
            label: tl('general_status'),
            value: (quote) => this.renderStatus(quote),
            sortKey: "Status",
          },
          actions: {
            label: tl('general_actions'),
            value: (quote) => this.renderActions(quote),
          },
        }}
        entries={this.state.quotes}
        onSelect={(quote) => this.toggleEditQuotePopup(quote)}
        sortQuery={this.state.sortQuery}
        updateSortQuery={this.updateSortQuery.bind(this)}
      />
    );
  }

  updateStatus(quote, status) {
    this.updateStatusToAPI(quote, status);
  }

  updateStatusToAPI(quote, status) {
    let apiClient = new ApiClient(this.context);

    apiClient
      .call("PUT", "/v1/Quote/" + quote.id + "/Status", { status: status })
      .then((result) => {
        if (result.ok) {
          if (status === "CANCELLED") {
            this.context.addNotification(
              "success",
              this.context.translate('notification_quote_deleted')
            );
          }
          else {
            this.context.addNotification(
              "success",
              this.context.translate('notification_quote_save_success')
            );
          }
        } else {
          throw result;
        }
      })
      .then(() => {
        this.refreshData();
      });
  }

  downloadPdf(quote) {
    let tl = this.context.translate;

    if (!quote.pdfFile) {
      this.context.addNotification("error", tl('notifications_download_unavailable'));
      return;
    }

    this.context.addNotification("info", tl('notifications_download_started'));

    new ApiClient(this.context)
      .call("GET", quote.pdfFile.filePath)
      .then((response) => {
        this.apiResponseAsDownload(response, `${quote.number}.pdf`);
      });
  }

  recalculate(quote) {
    this.toggleEditQuotePopup(quote, true);
  }

  sendMail(quote) {
    this.toggleSendMailPopup(quote);
  }

  renderStatus(quote) {
    return (
      <QuoteStateSelector
        tl={this.context.translate}
        value={quote.status}
        mapping={this.state.quoteStates}
        disabled={quote.status === 'OVERDUE' || quote.status === 'PENDING'}
        onUpdateStatus={this.updateStatus.bind(this, quote)}
      />
    );
  }

  renderActions(quote) {
    return (
      <div>
        {quote.status === 'OVERDUE' &&
          <TableActionRecalculate onClick={this.recalculate.bind(this, quote)} />
        }
        {quote.status !== 'OVERDUE' && quote.status !== 'PENDING' && quote.status !== 'FAILED' &&
          <>
            <TableActionMail onClick={this.sendMail.bind(this, quote)} />
            <TableActionSave onClick={this.downloadPdf.bind(this, quote)} />
          </>
        }
        <TableActionDelete
          onClick={this.updateStatus.bind(this, quote, "CANCELLED")}
        />
      </div >
    );
  }

  render() {
    let tl = this.context.translate;
    return (
      <GenericPage
        className="quoteManagementPage"
        title={tl('quotes_title')}
        buttons={this.renderButtons()}
        content={this.renderTable()}
      >
        {this.state.filterPopupShown && (
          <QueryFilterPopup
            onDismiss={this.toggleFilterPopup.bind(this)}
            filterQuery={this.state.filterQuery}
            updateFilterQuery={this.updateFilterQuery.bind(this)}
            mapping={{
              Number: {
                label: tl('quotes_quotes_number'),
              },
              AccountId: {
                label: tl('general_client'),
                options: this.state.accounts.map((a) => ({
                  label: a.name,
                  value: a.id,
                })),
              },
              Title: {
                label: tl('quotes_project_reference'),
              },
              CreatedAt: {
                label: tl('general_date'),
              },
              Status: {
                label: tl('general_status'),
                options: this.state.quoteStates.map((m) => ({
                  label: tl(m.label),
                  value: m.tag,
                })),
              },
              Active: null,
            }}
          />
        )}
        {this.state.quotePopupShown && (
          <QuotationPopup
            onDismiss={this.toggleEditQuotePopup.bind(this)}
            quote={this.state.selectedQuote}
            recalculate={this.state.recalculate}
            refreshData={this.refreshData.bind(this)}
          />
        )}
        {this.state.mailPopupShown && (
          <MailPopup
            quote={this.state.selectedQuote}
            onDismiss={this.toggleSendMailPopup.bind(this)}
          />
        )}

      </GenericPage>
    );
  }
}

export default QuoteManagementPage;

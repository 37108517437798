import { Component } from "react";
import React from "react";
import "./add-new-news-item.css";
import AppContext from "../../../app/app-context";

class AddNewNewsItem extends Component {
  static contextType = AppContext;
  render() {
    let tl = this.context.translate;
    return (
      <div className="addNewNewsItem">
        <button className="addNewItem" onClick={this.props.onClick}>
          <p>{tl('news_item_add_new')}</p>
          <svg
            className="addButton"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
          >
            <line
              stroke="#ffffff"
              className="svgAddButton"
              y2="16"
              transform="translate(9 1)"
            />
            <line
              stroke="#ffffff"
              className="svgAddButton"
              y2="16"
              transform="translate(17 9) rotate(90)"
            />
          </svg>
        </button>
      </div>
    );
  }
}
export default AddNewNewsItem;

import Statistic from "./statistic/statistic";
import Chart from "./chart/chart";
import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import "./statistics.css";

import icon from "./accounts.png";
import BaseComponent from "../../app/base-component";

class Statistics extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      statistics: {},
    };
  }

  componentDidMount() {
    this.getStatistics();
  }

  percentage(partialValue, totalValue) {
    return Math.round((100 * partialValue) / totalValue);
  }

  getStatistics() {
    new ApiClient(this.context)
      .call("GET", "/v1/Statistics")
      .then((response) => {
        if (response.ok) {
          let statistics = response.json;
          this.setStateAsync((state) => ({
            statistics: statistics,
          }));
        }
      });
  }

  render() {
    let tl = this.context.translate;
    let currencyFormatter = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' });

    return (
      <div className="statistics">
        <h1 className="statistics-title">
          {tl('dashboard_statistics_title')}
        </h1>
        <div className="statistics-horizontalLine"></div>
        <div className="statistics-container">
          <Statistic
            icon={icon}
            statistic={
              this.state.statistics.usersLast12Months
                ? this.state.statistics.usersLast12Months[0].count
                : 0
            }
            unit={tl('general_users')}
            subtitleAmount=""
            subtitleUnit=""
            worth=""
            difference={
              this.state.statistics.usersLast12Months
                ? this.state.statistics.usersLast12Months[1].count
                : 0
            }
            increase={
              this.state.statistics.usersLast12Months
                ? this.state.statistics.usersLast12Months[1].count <=
                this.state.statistics.usersLast12Months[0].count
                : false
            }
          />
          <Statistic
            icon={icon}
            statistic={
              this.state.statistics.calculationsLast12Months
                ? this.state.statistics.calculationsLast12Months[0].count
                : 0
            }
            unit={tl('general_calculations')}
            subtitleAmount=""
            subtitleUnit=""
            worth=""
            difference={
              this.state.statistics.calculationsLast12Months
                ? this.state.statistics.calculationsLast12Months[1].count
                : 0
            }
            increase={
              this.state.statistics.calculationsLast12Months
                ? this.state.statistics.calculationsLast12Months[1].count <=
                this.state.statistics.calculationsLast12Months[0].count
                : false
            }
          />
          <Statistic
            icon={icon}
            statistic={
              this.state.statistics.quotesLast12Months
                ? this.state.statistics.quotesLast12Months[0].count
                : 0
            }
            unit={tl('general_quotes')}
            subtitleAmount={
              this.state.statistics.quotesLast12Months &&
                this.state.statistics.calculationsLast12Months[0].count !== 0
                ? this.percentage(
                  this.state.statistics.quotesLast12Months[0].count,
                  this.state.statistics.calculationsLast12Months[0].count
                )
                : "-"
            }
            subtitleUnit={tl('dashboard_statistics_of_calculations')}
            worth={currencyFormatter.format(this.state.statistics.quotesWorthThisMonth || 0)}
            difference={
              this.state.statistics.quotesLast12Months
                ? this.state.statistics.quotesLast12Months[1].count
                : 0
            }
            increase={
              this.state.statistics.quotesLast12Months
                ? this.state.statistics.quotesLast12Months[1].count <=
                this.state.statistics.quotesLast12Months[0].count
                : false
            }
          />
          <Statistic
            icon={icon}
            statistic={
              this.state.statistics.ordersLast12Months
                ? this.state.statistics.ordersLast12Months[0].count
                : 0
            }
            unit={tl('general_orders')}
            subtitleAmount={
              this.state.statistics.ordersLast12Months &&
                this.state.statistics.quotesLast12Months[0].count !== 0
                ? this.percentage(
                  this.state.statistics.ordersLast12Months[0].count,
                  this.state.statistics.quotesLast12Months[0].count
                )
                : "-"
            }
            subtitleUnit={tl('dashboard_statistics_of_quotes')}
            worth={currencyFormatter.format(this.state.statistics.ordersWorthThisMonth || 0)}
            difference={
              this.state.statistics.ordersLast12Months
                ? this.state.statistics.ordersLast12Months[1].count
                : 0
            }
            increase={
              this.state.statistics.ordersLast12Months
                ? this.state.statistics.ordersLast12Months[1].count <=
                this.state.statistics.ordersLast12Months[0].count
                : false
            }
          />
          <Chart statistic={this.state.statistics.quotesLast12Months} />
        </div>
      </div>
    );
  }
}

export default Statistics;

import AppContext from '../app/app-context';
import './calculation.css';
import PartsList from './parts-list/parts-list';
import UploadPopup from './upload-popup/upload-popup';
import QuotationPopup from './quotation-popup/quotation-popup';
import BaseComponent from '../app/base-component';
import ApiClient from '../api-client/api-client';
import ImportOrderLinePopup from './import-order-line-popup/import-order-line-popup';
import FilterPopup from '../app/table/filter-popup/filter-popup';
import PreviewPopup from './preview-popup/preview-popup';
import TappingPopup from './tapping-popup/tapping-popup';

const Operations = {
  TAPPING: 1001,
  COUNTERSINKING: 1002,
  BENDING: 1003,

  ONE_SIDED_DEBURRING: 2004,
  TWO_SIDED_DEBURRING: 2005,

  ENGRAVING: 3006,

  MATERIAL_CERT_3_1: 4007,
  MATERIAL_CERT_3_2: 4008,
};

class Calculation extends BaseComponent {
  static contextType = AppContext;
  constructor(props) {
    super(props);

    this.state = {
      importOrderLinePopUpShown: false,
      uploadPopUpShown: false,
      tappingPopUpShown: false,
      quotationPopUpShown: false,
      previewPopUpShown: false,
      unattended: false,
      thicknesses: [],
      tappings: [],
      filters: {},
      parts: [],
      previewPart: null,
      account: {},
    };
  }

  componentDidMount() {
    this.getMaterials();
    this.getTappings();
    this.setState({
      account: this.context.user.account,
      unattended: !!this.context.tenantSettings.unattendedByDefault,
    });
    //this.getBendConfiguration();

    document.addEventListener("keydown", this.keyDownListener.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDownListener.bind(this));
  }

  keyDownListener(e) {
    // CTRL+A (select all)
    if (e.ctrlKey && e.keyCode === 65) {
      e.preventDefault();
      e.stopPropagation();
      this.selectAllParts();
    }
  }

  // TODO: Needs to be further implemented where needed.
  getMaterials() {
    new ApiClient(this.context).call(
      'GET',
      `/v1/TenantSettings/${this.context.theme.tenantId}/TenantMaterials`
    )
      .then(response => {
        if (response.ok) {
          let thicknesses = response.json
            .reduce((result, mt) => {
              var thickness = result.find(m => m.thickness === mt.thickness);

              if (!thickness) {
                thickness = {
                  thickness: mt.thickness,
                  materials: [],
                };

                result.push(thickness);
              }

              thickness.materials.push({
                id: mt.id,
                name: mt.material.name,
              });

              return result;
            }, []);

          thicknesses.forEach(m => {
            m.materials.sort((a, b) => a.name < b.name
              ? -1
              : (a.name < b.name ? 1 : 0)
            )
          });

          thicknesses.sort((a, b) => a.thickness < b.thickness
            ? -1
            : (a.thickness > b.thickness ? 1 : 0)
          );

          this.setState({
            thicknesses: thicknesses,
          });
        }
      })
      .catch(e => {
        return e;
      });
  }

  getTappings() {
    new ApiClient(this.context).call(
      'GET',
      `/v1/TenantSettings/${this.context.theme.tenantId}/TenantTappings`
    )
      .then(response => {
        if (response.ok) {
          this.setState({
            tappings: response.json || [],
          });
        }
      })
      .catch(e => {
        return e;
      });
  }

  // TODO: Needs to be further implemented where needed.
  getBendConfiguration() {
    new ApiClient(this.context).call(
      'GET',
      '/v1/WCApi/GetBendConfiguration',
    )
      .then(response => {
        console.log(response);
      })
      .catch(e => {
        return e;
      });
  }

  toggleUnattended() {
    this.setState(state => ({
      unattended: !state.unattended,
    }));
  }

  setPreviewPart(id) {
    this.setState(state => ({
      previewPart: state.parts.find(part => part.fileId === id),
    }));

    this.togglePreviewPopUp();
  }

  selectAllParts() {
    this.setState(state => {
      let filterThickness = this.getFilterThickness(state);
      let val = !this.getFilteredParts(state)
        .every(p => p.selected);
      let parts = state.parts
        .map(p => ({
          ...p,
          selected: isNaN(filterThickness) || this.getThickness(p) === filterThickness ? val : false,
        }));

      return {
        parts: parts,
      }
    });
  }

  togglePartSelected(id, multi) {
    let parts = this.state.parts
      .map(part => ({
        ...part,
        selected: part.fileId === id
          ? !part.selected
          : multi ? part.selected : false,
      }));

    this.setState({
      parts: parts,
    });
  }

  deleteSelection() {
    this.setStateAsync(state => ({
      parts: state.parts.filter(p => !p.selected),
    }));
  }

  validationCallback(errors) {
    errors = Object.entries(errors)
      .filter(([k]) =>
        k.split('[').length > 1
        && k.split('.').length > 1
      )
      .reduce((a, [k, v]) => {
        let pi = k.split('[')[1].split(']')[0];
        let pk = k.split('.')[1];

        if (!a[pi]) {
          a[pi] = {};
        }

        if (!a[pi][pk]) {
          a[pi][pk] = [];
        }

        a[pi][pk] = [...a[pi][pk], ...v];

        return a;
      }, {});

    this.setState(state => {
      let parts = state.parts.map((part, i) => ({
        ...part,
        apiErrors: errors[i],
      }));

      return {
        parts: parts,
        instantValidation: true,
      };
    });
  }

  toggleUploadPopUp() {
    this.setState({
      uploadPopUpShown: !this.state.uploadPopUpShown
    });
  }

  toggleTappingPopUp() {
    this.setState({
      tappingPopUpShown: !this.state.tappingPopUpShown
    });
  }

  //TODO: make sure that when the pop up is shown the API also calculates
  //the correct costs.
  toggleQuotationPopUp(shown = null) {
    return this.setStateAsync(state => ({
      quotationPopUpShown: typeof shown === 'boolean'
        ? shown
        : !state.quotationPopUpShown
    }));
  }

  toggleImportOrderLinePopUp() {
    this.setState({
      importOrderLinePopUpShown: !this.state.importOrderLinePopUpShown
    });
  }

  togglePreviewPopUp() {
    this.setState({
      previewPopUpShown: !this.state.previewPopUpShown
    });
  }

  toggleFilterPopup() {
    this.setState(state => ({
      filterPopUpShown: !state.filterPopUpShown
    }));
  }

  updateFilters(filters) {
    this.setState(state => ({
      filters: filters,
      parts: state.parts.map(p => ({
        ...p,
        selected: false,
      }))
    }));
  }

  calculateCosts() {
    this.toggleQuotationPopUp(true);
  }

  convertFileToPart(file) {
    var dimensions = [];

    if (file.isThreeDimensional && file.hasDxfFile) {
      dimensions = [
        file.dxfFileInfo.threeDimensional.boundary.x,
        file.dxfFileInfo.threeDimensional.boundary.y,
        file.dxfFileInfo.threeDimensional.boundary.z,
      ];
    }
    else {
      dimensions = [
        file.fileInfo.basicInformation.length,
        file.fileInfo.basicInformation.width,
      ];
    }

    dimensions = dimensions
      .map(f => Math.round(f))
      .filter(s => s).join('x');
    let thickness = file.isThreeDimensional && file.hasDxfFile
      ? file.dxfFileInfo.threeDimensional.thickness
      : null;

    let availableOperations = this.context.tenantSettings.availableOperations;

    let hasBendings = file.isThreeDimensional && file.hasDxfFile && file.dxfFileInfo.threeDimensional.bendings.length > 0;
    let hasCounterSinks = file.isThreeDimensional && file.hasDxfFile && file.dxfFileInfo.threeDimensional.features.counterSinks.length > 0;

    let allowBending = availableOperations.some(ao => ao === "BENDING") && hasBendings;
    let allowCounterSinking = availableOperations.some(ao => ao === "COUNTERSINKING") && hasCounterSinks;

    return {
      fileId: file.fileId,
      collectionId: file.collectionId,
      dimensions: dimensions,
      fileInfo: file.fileInfo,
      dxfFileInfo: file.dxfFileInfo,
      isThreeDimensional: file.isThreeDimensional,
      hasDxfFile: file.hasDxfFile,
      dxfFileId: file.hasDxfFile
        ? file.dxfFile.fileId
        : file.fileId,
      dxfCollectionId: file.hasDxfFile
        ? file.dxfFile.collectionId
        : file.collectionId,
      selected: false,
      amount: file.isThreeDimensional && file.dxfFileInfo
        ? file.dxfFileInfo.threeDimensional.assembly.instances.length
        : 1,
      material: null,
      materialThickness: this.state.thicknesses.find(t => t.thickness === thickness),
      note: "",
      bending: allowBending,
      allowBending: allowBending,
      counterSinking: allowCounterSinking,
      allowCounterSinking: allowCounterSinking,
      surfaceTreatment: "NONE",
      engraving: false,
      materialCertificate: "NONE",
      tapping: null,
    };
  }

  reset() {
    this.setState({
      filters: {},
      parts: [],
      previewPart: null,
    })
  }

  didReceiveParts(files) {
    this.setState({
      parts: [
        ...this.state.parts,
        ...files.map(this.convertFileToPart.bind(this))
      ]
    });
  }

  didReceiveOrderLines(data) {
    this.setState({
      parts: this.state.parts.map((part) => (
        this.mapImportToPart(data, part)
      ))
    });
  }

  mapImportToPart(data, part) {
    let importPart = data.find(e => e.name === part.fileInfo.file.name)
    let surfaceTreatmentMapping = importPart
      ? this.getSurfaceTreatmentMapping().find(s => s.label === importPart.surfaceTreatment)
      : null;
    let materialCertificateMapping = importPart
      ? this.getMaterialCertificateMapping().find(s => s.label === importPart.materialCertificate)
      : null;

    let importThickness = !part.isThreeDimensional ? importPart.thickness : part.materialThickness.thickness;
    let importMaterialThickness = this.state.thicknesses.find(tm => tm.thickness === importThickness);
    let importMaterial = importMaterialThickness && importMaterialThickness.materials.find(m => m.name === importPart.material);

    return importPart && importMaterial && importMaterialThickness
      ?
      {
        ...part,
        amount: importPart.amount,
        material: importMaterial,
        materialThickness: importMaterialThickness,
        surfaceTreatment: surfaceTreatmentMapping ?
          surfaceTreatmentMapping.tag
          : "NONE",
        engraving: importPart.engraving,
        materialCertificate: materialCertificateMapping ?
          materialCertificateMapping.tag
          : "NONE",
      }
      : part
  }

  onSettingChange(key, val) {
    this.setState({
      [key]: val,
    });
  }

  onInputChange(key, val) {
    this.setState(state => ({
      parts: [
        ...state.parts.map((part) => {
          var material = part.material;
          var apiErrors = part.apiErrors || {};

          if (part.selected && key === 'materialThickness') {
            delete (apiErrors['materialThicknessId']);

            if (!val) {
              material = null;
            }
            else {
              material = part.material && val.materials
                .find(m => m.name === part.material.name);
            }
          }

          if (part.selected && key === 'material') {
            delete (apiErrors['materialThicknessId']);
          }

          return {
            ...part,
            material: material,
            apiErrors: apiErrors,
            [key]: (part.selected
              ? val
              : part[key]),
          }
        })
      ]
    }));
  }

  getFilterThickness(state = null) {
    state = state || this.state;

    return parseFloat(state.filters.materialThickness);
  }

  getThickness(part) {
    return part.materialThickness ? part.materialThickness.thickness : 0;
  }

  getFilteredParts(state = null) {
    state = state || this.state;

    let filterThickness = this.getFilterThickness();
    return !isNaN(filterThickness)
      ? state.parts.filter(p => filterThickness === this.getThickness(p))
      : state.parts;
  }

  render() {
    let tl = this.context.translate;
    let filteredParts = this.getFilteredParts();
    let sortedParts = [...filteredParts].sort((a, b) => {
      let [ta, tb] = [this.getThickness(a) - this.getThickness(b)];
      return ta != 0 ? (tb != 0 ? ta - tb : -1) : 1;
    });
    let uniqueThicknesses = [...this.state.parts
      .map(p => this.getThickness(p)),
    this.getFilterThickness()
    ]
      .filter((v, i, s) => s.indexOf(v) === i)
      .sort();

    return (
      <div className="pageContainer">
        <div className="calculation">
          <div className="partsListContainer">
            <span className="partsListContainer-title">
              {tl('calculate_parts_list_title')}
            </span>
            <PartsList
              parts={sortedParts}

              active={this.state.parts.length > 0}
              instantValidation={this.state.instantValidation}

              account={this.state.account}
              thicknesses={this.state.thicknesses}
              tappings={this.state.tappings}

              toggleImportOrderLinePopUp={this.toggleImportOrderLinePopUp.bind(this)}
              toggleFilterPopup={this.toggleFilterPopup.bind(this)}
              toggleUploadPopUp={this.toggleUploadPopUp.bind(this)}
              toggleTappingPopUp={this.toggleTappingPopUp.bind(this)}
              togglePartSelected={this.togglePartSelected.bind(this)}

              onInputChange={this.onInputChange.bind(this)}
              onSettingChange={this.onSettingChange.bind(this)}

              deleteSelection={this.deleteSelection.bind(this)}
              setPreviewPart={this.setPreviewPart.bind(this)}
              selectAllParts={this.selectAllParts.bind(this)}

              calculateCosts={this.calculateCosts.bind(this)}

              unattended={this.state.unattended}
              toggleUnattended={this.toggleUnattended.bind(this)}
            />
          </div>
        </div>
        {this.state.uploadPopUpShown &&
          <UploadPopup
            onDismiss={this.toggleUploadPopUp.bind(this)}
            didReceiveParts={this.didReceiveParts.bind(this)}
            thicknesses={this.state.thicknesses}
          />
        }
        {this.state.quotationPopUpShown &&
          <QuotationPopup
            reset={this.reset.bind(this)}
            onDismiss={this.toggleQuotationPopUp.bind(this)}
            validationCallback={this.validationCallback.bind(this)}
            unattended={this.state.unattended}
            parts={this.state.parts}
            account={this.state.account}
          />
        }

        {this.state.filterPopUpShown &&
          <FilterPopup
            onDismiss={this.toggleFilterPopup.bind(this)}
            filters={this.state.filters}
            updateFilters={this.updateFilters.bind(this)}
            mapping={{
              materialThickness: {
                label: tl('general_thickness'),
                options: uniqueThicknesses.map((t) => ({
                  label: t,
                  value: t,
                })),
              },
            }}
          />
        }
        {this.state.importOrderLinePopUpShown &&
          <ImportOrderLinePopup
            onDismiss={this.toggleImportOrderLinePopUp.bind(this)}
            didReceiveOrderLines={this.didReceiveOrderLines.bind(this)}
          />
        }
        {this.state.previewPopUpShown &&
          <PreviewPopup
            onDismiss={this.togglePreviewPopUp.bind(this)}
            previewPart={this.state.previewPart}
          />
        }
        {this.state.tappingPopUpShown &&
          <TappingPopup
            onDismiss={this.toggleTappingPopUp.bind(this)}
            onInputChange={this.onInputChange.bind(this)}
            parts={sortedParts}
            tappings={this.state.tappings}
          />
        }
      </div>
    );
  }
}

export default Calculation;
import React from "react";
import uuidv4 from 'uuid/v4';
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";
import ApiClient from "../../api-client/api-client";

import Popup from "../../app/popup/popup";

import "./upload-popup.css";
import UploadProgress from "./upload-progress";
import { arrayify, delay } from "../../helpers";
import EmailPopup from "./email-popup/email-popup";

class UploadPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      dismissButonShown: true,
      emailPopupShown: false,
      uploadStatus: [],
      faultyFiles: [],
      supportedErrors: [],
    };
  }

  componentDidMount() {
    this.getSupportedErrors();
  }

  toggleDismissButton() {
    this.setState({
      dismissButonShown: !this.state.dismissButonShown,
    });
  }

  getSupportedErrors() {
    let url = `/v1/TenantSettings/SupportedErrors`;
    return new ApiClient(this.context).call("GET", url)
      .then((response) => {
        if (response.ok) {
          return this.setStateAsync({
            supportedErrors: response.json,
          });
        }
      });
  }

  uploadFiles(e) {
    let files = [...e.target.files];
    var parts = [];

    if (this.state.dismissButonShown) {
      this.toggleDismissButton();
    }

    Promise.all(
      files.map(async (file) => {
        return await this.uploadFile(file, parts);
      })
    ).then(() => {
      // Another promise to get the proper data we need from the files.
      // This is done because we need to filter them out and get the thickness etc.
      Promise.all(
        parts.map(async (part) => {
          part.hasDxfFile = part.dxfFile !== undefined;
          // Part is threeDimensional unless it is a dxffile in this map.
          part.isThreeDimensional = true;

          part.unfoldTries = 0;
          var fileInfo = await this.getFileInfo(
            part,
            part.collectionId,
            part.fileId,
            false
          );
          part.fileInfo = fileInfo;

          // If 2D file just make the dxfFileInfo the already retrieved file info.
          if (
            this.context.settings.extensions.twoDimensional.includes(
              fileInfo.file.extension.toLocaleLowerCase()
            )
          ) {
            // If the parent part is twoDimensional it means the file is twoDimensional. Set the flag to false.
            part.isThreeDimensional = false;
            part.dxfFileInfo = fileInfo;
          }
          // If 3D File and it has a unfolded 2D File get the file info from the unfolded 2D file.
          if (part.hasDxfFile) {
            var dxfFileInfo = await this.getFileInfo(
              part,
              part.dxfFile.collectionId,
              part.dxfFile.fileId,
              true
            );
            part.dxfFileInfo = dxfFileInfo;
          }

          // There are 6 part types the webshop only supports part 2 and 3.
          // If the part does not have threeDimensional info it is 100% likely it has different errors (probably nesting) so we return this to true.
          let supportedPartType = (dxfFileInfo && dxfFileInfo.threeDimensional) ? dxfFileInfo.threeDimensional.partType === "_2" || dxfFileInfo.threeDimensional.partType === "_3" : true;
          let supportedErrors = this.state.supportedErrors;
          let ignoredErrors = this.context.tenantSettings.ignoredErrorCodes
            .map(e => supportedErrors.find(o => o.value == e));

          part.errors = [
            ...((fileInfo && fileInfo.validation.geometryErrors) || []),
            ...((fileInfo && fileInfo.validation.unfoldErrors) || []),
            ...((dxfFileInfo && dxfFileInfo.validation.geometryErrors) || []),
            ...((dxfFileInfo && dxfFileInfo.validation.unfoldErrors) || []),
            ...(dxfFileInfo && !supportedPartType ? [{
              code: 1000000,
            }] : []),
            ...(part.unfoldTries > 100 ? [{
              code: 1000001,
            }] : []),
            ...(dxfFileInfo && dxfFileInfo.threeDimensional && dxfFileInfo.threeDimensional.thickness && !this.props.thicknesses.some(t => t.thickness === dxfFileInfo.threeDimensional.thickness) ? [{
              code: 1000002,
            }] : [])
          ]
            .map(e => supportedErrors.find(se => parseInt(e.code) === se.code))
            .filter(e => e !== undefined)
            .map(e => ({
              ...e,
              ignored: ignoredErrors.some(ie => ie.code === e.code),
            }));

          this.updateFileProgress(part.ref, 0, 1, part.errors);
          return part;
        })
      ).then((mappedParts) => {
        let hasErrors = mappedParts
          .some(p => this.getFileErrors(p.ref).length > 0);
        let validParts = mappedParts
          .filter(p => this
            .getFileErrors(p.ref)
            .every(e => e.ignored));
        this.props.didReceiveParts(validParts);
        this.toggleDismissButton();

        if (!hasErrors) {
          this.props.onDismiss();
        }
      });
    });
  }

  async uploadFile(file, parts) {
    let formData = new FormData();
    formData.append("file", file);
    let ref = await this.addFileProgress(file.name);

    return await new ApiClient(this.context)
      .call("POST", "/v1/WCApi/UploadFile", formData)
      .then(async (response) => {
        if (response.ok) {
          var part = {
            ref: ref,
            ...response.json,
          };

          parts.push(part);
          let fileExtension = file.name.split(".").pop().toString();
          if (this.context.settings.extensions.twoDimensional.includes(fileExtension.toLocaleLowerCase())) {
            this.updateFileProgress(part.ref, 1, 1);
            return part;
          } else {
            this.updateFileProgress(part.ref, 2, 1);
            return this.startUnfold(part, parts, file);
          }
        } else {
          return undefined;
        }

      }, this);
  }

  addFileProgress(name) {
    let uuid = uuidv4();
    return this.setStateAsync((state) => ({
      uploadStatus: {
        ...state.uploadStatus,
        [uuid]: {
          name: name,
          load: 1,
          complete: 0,
          errors: [],
        },
      },
    })).then(() => uuid);
  }

  getFileErrors(ref) {
    return this.state.uploadStatus[ref].errors;
  }

  updateFileProgress(ref, load = 0, complete = 0, errors = []) {
    return this.setStateAsync((state) => ({
      uploadStatus: {
        ...state.uploadStatus,
        [ref]: {
          name: state.uploadStatus[ref].name,
          load: state.uploadStatus[ref].load + load,
          complete: state.uploadStatus[ref].complete + complete,
          errors: [
            ...state.uploadStatus[ref].errors,
            ...errors
          ],
        },
      },
    }));
  }

  // TODO: Get proper config and material
  startUnfold(part, parts, file) {
    let url = "/v1/WCApi/StartUnfold";

    let body = {
      bendConfig: "YRvAvruuZUu0O_FD5NyTBA==",
      materialNumber: 1,
      collectionId: part.collectionId,
      fileId: part.fileId,
    };

    return new ApiClient(this.context)
      .call("POST", url, body)
      .then((response) => {
        if (response.status === 204) {
          return this.getUnfold(part, parts, file);
        }
      });
  }

  getUnfold(part, parts, file) {
    let tl = this.context.translate;
    let url =
      "/v1/WCApi/GetUnfold/" +
      part.collectionId +
      "/" +
      part.fileId +
      "/YRvAvruuZUu0O_FD5NyTBA==/1";

    return new ApiClient(this.context)
      .call("GET", url)
      .then((response) => {
        if (response.status === 206) {
          return delay(250).then(() => {
            return this.getUnfold(part, parts, file);
          });
        } else if (response.status === 200) {
          this.updateFileProgress(part.ref, 0, 1);
          return this.unfoldChildObjects(response.json, parts, part);
        }
      })
      .catch((e) => {
        this.updateFileProgress(part.ref, 0, 1, [{
          code: -1,
          ignored: false,
          label: tl('warning_assemblies_not_supported_yet'),
          value: 'ASSEMBLIES_NOT_SUPPORTED',
        }]);
        return this.setStateAsync((state) => ({
          faultyFiles: [...state.faultyFiles, file]
        }));
      });
  }

  unfoldChildObjects(unfoldingResponse, parts, part) {
    if (unfoldingResponse.disassembled === null) {
      return Promise.all(
        arrayify(unfoldingResponse.unfolded).map((file) => {
          part.dxfFile = file;
          return parts;
        })
      );
    } else {
      throw new Error("This feature is not implemented yet");

      // return Promise.all(
      //   arrayify(unfoldingResponse.disassembled).map((file) => {
      //     var part = {
      //       ...file,
      //     };
      //     parts.push(part);

      //     this.updateFileProgress(part.collectionId, 1, 0);

      //     return this.startUnfold(part, parts);
      //   })
      // );
    }
  }

  getFileInfo(part, collectionId, fileId, isUnfoldedDxf) {
    let url = "/v1/WCApi/GetFileInfo";

    return new ApiClient(this.context)
      .call(
        "GET",
        url,
        null,
        false,
        {},
        {
          collectionId: collectionId,
          fileId: fileId,
        }
      )
      .then(async (response) => {
        if (response.ok) {
          if (
            this.context.settings.extensions.twoDimensional.includes(
              response.json.file.extension.toLocaleLowerCase()
            ) &&
            !response.json.status.validation2DReady &&
            !isUnfoldedDxf
          ) {
            return delay(250).then(() => {
              if (!isUnfoldedDxf) {
                if (part.unfoldTries > 100) {
                  return response.json;
                }
                part.unfoldTries++;
              }

              return this.getFileInfo(part, collectionId, fileId);
            });
          } else {
            return response.json;
          }
        } else {
          return undefined;
        }
      }, this);
  }

  hideEmailPopup(file) {
    this.setState((state) => ({
      faultyFiles: state.faultyFiles.filter(
        (faultyFile) => faultyFile !== file
      ),
    }));
  }
  renderEmailPopups() {
    return this.state.faultyFiles.map((file) => (
      <EmailPopup
        key={file}
        hide={this.hideEmailPopup.bind(this, file)}
        file={file}
      />
    ));
  }

  render() {
    let tl = this.context.translate;
    let settings = this.context.settings;
    let extensions = settings.extensions.twoDimensional.concat(settings.extensions.threeDimensional);

    return (
      <>
        <Popup
          title={tl('calculate_part_upload_title').toUpperCase()}
          onDismiss={this.state.dismissButonShown && this.props.onDismiss}
        >
          <div className="uploadPopup">
            <div className="popup-section">
              <div className="popup-column">
                <div className="progressContainer">
                  {Object.keys(this.state.uploadStatus).map((collectionId) => {
                    let item = this.state.uploadStatus[collectionId];

                    return (
                      <UploadProgress
                        key={collectionId}
                        name={item.name}
                        progress={item.load > 0 ? item.complete / item.load : 0}
                        complete={item.complete === item.load}
                        errors={item.errors}
                      />
                    );
                  })}
                </div>
                <label
                  className="dropzone"
                  data-label={
                    tl('calculate_part_upload_drag_and_drop')
                  }
                >
                  <input
                    type="file"
                    onChange={this.uploadFiles.bind(this)}
                    accept={extensions.map(ext => `.${ext.toUpperCase()}`).join(', ')}
                    multiple
                  />
                </label>
              </div>
            </div>
          </div>
        </Popup>
        {this.renderEmailPopups()}
      </>
    );
  }
}

export default UploadPopup;

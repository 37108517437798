import ApiClient from "../../api-client/api-client";
import AppContext from "../app-context";
import BaseComponent from "../base-component";

class FileDownload extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);
  }

  downloadFile(file, fileName, e) {
    e.preventDefault();
    e.stopPropagation();

    let tl = this.context.translate;

    if (!file) {
      this.context.addNotification("error", tl('notifications_download_unavailable'));
      return;
    }

    this.context.addNotification("info", tl('notifications_download_started'));

    new ApiClient(this.context)
      .call("GET", file.filePath)
      .then((response) => {
        this.apiResponseAsDownload(response, fileName);
      });
  }

  render() {
    let file = this.props.file;
    var fileName = this.props.fileName;
    fileName = fileName
      ? `${fileName}.${file.extension}`
      : file.name;

    return (
      <a href="#" onClick={this.downloadFile.bind(this, file, fileName)}>
        {this.props.children || fileName}
      </a>
    );
  }
}


export default FileDownload;
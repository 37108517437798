import { Component, Fragment } from "react";
import AppContext from "./app-context";
import Popup from "./popup/popup";


class BaseComponent extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.renderContent = this.render;
    this.render = this.renderContainer;

    this.state = {};
  }

  setStateAsync(state) {
    return new Promise((resolve) => this.setState(state, resolve));
  }

  apiResponseAsDownload(apiResponse, fileName = null) {
    if (apiResponse.ok) {
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(apiResponse.raw);
      a.download = fileName
        || (apiResponse.contentDisposition && apiResponse.contentDisposition['name'])
        || 'file';
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
    else {
      this.context.addNotification("error", this.context.translate('notifications_unknown_error'));
    }
  }

  destroyDialog() {
    this.setState({
      dialog: null,
    });
  }

  askConfirmation(title, message, actionLabel = null) {
    let tl = this.context.translate;

    return new Promise((resolve, reject) => {
      this.setState({
        dialog: {
          title: title,
          message: message,
          actionLabel: actionLabel || tl('general_ok'),
          action: () => {
            resolve(true);
            this.destroyDialog();
          },
          dismiss: () => {
            resolve(false);
            this.destroyDialog();
          }
        }
      });
    });
  }

  getPartOperations(part) {
    let tl = this.context.translate;
    let tappingLines = part.tapping && (part.tapping.lines || part.tapping.tappingLines.reduce((a, v) => ({ ...a, [v.tenantTappingId]: v.amount }), {}));
    let tappingAmount = tappingLines && Object.values(tappingLines).reduce((a, v) => a + v, 0) || 0;

    return [
      this.getCuttingTechnologyMapping().find(
        c => c.tag === this.context.tenantSettings.cuttingTechnology
      ).label,
      part.bending && tl('calculate_bending'),
      part.counterSinking && tl('calculate_counter_sinking'),
      this.getSurfaceTreatmentMapping().find(
        (c) => c.tag === part.surfaceTreatment
      ).label,
      part.engraving && tl('calculate_engraving'),
      this.getMaterialCertificateMapping().find(
        (c) => c.tag === part.materialCertificate
      ).label,
      tappingAmount > 0 && `${tl('calculate_tapping')} (${tappingAmount})`,
    ].filter(v => v);
  }

  getCuttingTechnologyMapping() {
    let tl = this.context.translate;

    return [
      {
        tag: "SHEETLASER",
        label: tl('cutting_technologies_sheetlaser'),
      },
      {
        tag: "TUBELASER",
        label: tl('cutting_technologies_tubelaser'),
      },
      {
        tag: "WATERJET",
        label: tl('cutting_technologies_waterjet'),
      },
      {
        tag: "PLASMA",
        label: tl('cutting_technologies_plasma'),
      },
      {
        tag: "OXYFUEL",
        label: tl('cutting_technologies_oxyfuel'),
      },
    ];
  }

  getSurfaceTreatmentMapping() {
    let tl = this.context.translate;

    return [
      {
        tag: "ONE_SIDED_DEBURRING",
        label: tl('operation_one_sided_deburring'),
      },
      {
        tag: "TWO_SIDED_DEBURRING",
        label: tl('operation_two_sided_deburring'),
      },
      {
        tag: "NONE",
        label: false,
      }
    ];
  }

  getMaterialCertificateMapping() {
    return [
      {
        tag: "NONE",
        label: false,
      },
      {
        tag: "MATERIAL_CERT_3_1",
        label: "3.1",
      },
      {
        tag: "MATERIAL_CERT_3_2",
        label: "3.2",
      },
    ];
  }

  getCountries() {
    let tl = this.context.translate;
    let countries = this.context.settings.countries;

    return this.setStateAsync({
      countries: countries.map(c => tl(`country_${c}`)),
    });
  }

  renderContainer() {
    let tl = this.context.translate;

    return <Fragment>
      {this.renderContent()}
      {this.state.dialog && (
        <Popup
          className={`${this.state.dialog.className}`}
          title={this.state.dialog.title}
          actions={[
            this.state.dialog.dismiss && {
              label: tl('general_cancel'),
              handler: this.state.dialog.dismiss,
            },
            {
              label: this.state.dialog.actionLabel,
              handler: this.state.dialog.action || this.destroyDialog.bind(this),
            },
          ]}
          onDismiss={this.destroyDialog.bind(this)}
        >
          <div className="popup-section">
            <div className="popup-column">
              {this.state.dialog.message}
              {this.state.dialog.content}
            </div>
          </div>
        </Popup>
      )}
    </Fragment>;
  }
}

export default BaseComponent;
import BaseComponent from "../base-component";

import './percentage-input.css';

class PercentageInput extends BaseComponent {
  constructor(props) {
    super(props);
  }

  onChange(e) {
    let key = e.target.name;
    var val = e.target.value;
    var isDecimal = this.props.isDecimal;

    if (val !== '' && isDecimal) {
      var val = (val / 100);
      val = Math.min(1, Math.max(0, val));
    }

    this.props.onChange(key, val);
  }

  render() {
    var val = this.props.value;
    var isDecimal = this.props.isDecimal;

    if (val !== '' && isDecimal) {
      val = Math.round(val * 100);
    }

    return (
      <div className="percentageInput">
        <input
          name={this.props.name}
          type="number"
          min="0"
          max="100"
          value={`${val}`}
          onChange={this.onChange.bind(this)}
        ></input>
      </div>
    );
  }
}


export default PercentageInput;
import { Component } from "react";

import AppContext from "../../app-context";

import "./filter-button.css";

class FilterButton extends Component {
  static contextType = AppContext;

  render() {
    return (
      <button className="genericPage-filterButton" onClick={this.props.onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.756 13.274">
          <g transform="translate(-4.5 -9)">
            <path
              className="svgFilterButton"
              d="M12.085,21.326a.948.948,0,0,1,.948-.948h5.689a.948.948,0,0,1,0,1.9H13.034A.948.948,0,0,1,12.085,21.326ZM8.293,15.637a.948.948,0,0,1,.948-.948H22.515a.948.948,0,0,1,0,1.9H9.241A.948.948,0,0,1,8.293,15.637ZM4.5,9.948A.948.948,0,0,1,5.448,9h20.86a.948.948,0,1,1,0,1.9H5.448A.948.948,0,0,1,4.5,9.948Z"
            />
          </g>
        </svg>
        {this.props.label || this.context.translate('general_filter')}
      </button>
    );
  }
}

export default FilterButton;

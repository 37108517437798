import { Component } from "react";
import AppContext from "../../app/app-context";
import Loader from "../../app/loader/loader";

class LogoutPage extends Component {
  static contextType = AppContext;

  componentDidMount() {
    this.props
      .setUser(null)
      .then(() =>
        this.context.addNotification(
          "info",
          this.context.translate('notifications_logout_successfull')
        )
      );
  }

  render() {
    return <Loader />;
  }
}

export default LogoutPage;

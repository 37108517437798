import { Component } from "react";
import AppContext from "../../app-context";
import './buttons.css';

class AccountsButton extends Component {
  static contextType = AppContext;
  render() {
    let tl = this.context.translate;

    return (
      <span>
        <svg id='svgAccounts' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
          <circle className='svgAccounts-1' cx="17" cy="17" r="17" />
          <path className='svgAccounts-2' d="M16.9,3H5.926A1.463,1.463,0,0,0,4.463,4.463V6.657H3V8.12H4.463V9.583H3v1.463H4.463v1.463H3v1.463H4.463v2.194a1.463,1.463,0,0,0,1.463,1.463H16.9a.731.731,0,0,0,.731-.731V3.731A.731.731,0,0,0,16.9,3ZM11.046,5.193A2.195,2.195,0,1,1,8.852,7.388,2.2,2.2,0,0,1,11.046,5.193Zm4.389,9.51H6.657v-.549c0-1.623,1.979-3.292,4.389-3.292s4.389,1.668,4.389,3.292Z" transform="translate(6.686 6.686)" />
        </svg>
        <span>{tl('general_accounts')}</span>
      </span>
    );
  }
}

export default AccountsButton;

import React from "react";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";

import "./upload-progress.css";

const getStatus = (isComplete, errors) => {
  let hasErrors = errors.some(e => !e.ignored);
  let hasWarnings = errors.some(e => e.ignored);
  var status = {
    className: '',
    indicator: '',
    message: errors.map(e => e.label).join('\r\n'),
  };

  if (hasErrors) {
    status = {
      ...status,
      className: 'error',
      indicator: '❌',
    };
  }
  else if (hasWarnings) {
    status = {
      ...status,
      className: 'warning',
      indicator: '⚠️',
    };
  }
  else if (isComplete) {
    status = {
      ...status,
      indicator: '✅',
    };
  }

  return status;
};

class UploadProgress extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      uploadStatus: [],
    };
  }

  render() {
    // Unique errors
    let errors = this.props.errors
      .filter((v, i, s) => s.findIndex(e => v.code === e.code) === i);

    let isComplete = this.props.complete;
    let status = getStatus(isComplete, errors);

    return (
      <div className="uploadProgress">
        <div className="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.113 61.259">
            <path
              id="file"
              d="M39.954,1.125H3.375V62.384H57.488V18.657ZM53.4,20.348v.176H38.088V5.209h.176ZM7.459,58.3V5.209H34v19.4H53.4V58.3Z"
              transform="translate(-3.375 -1.125)"
              fill="#2444a3"
            />
          </svg>
        </div>
        <div className="info">
          <div className="name">
            <span>{this.props.name}</span>
            <a title={status.message}>{status.indicator}</a>
          </div>
          <div
            className="progress"
            style={{
              paddingRight: 100 - this.props.progress * 100 + "%",
            }}
          ></div>
          <div
            className={`statusMessage ${status.className}`}
          >
            {status.message}
          </div>
        </div>
      </div>
    );
  }
}

export default UploadProgress;

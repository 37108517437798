import React, { Component } from "react";
import * as THREE from "three";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import OrbitControls from "three-orbitcontrols";

class ObjPreview extends Component {
  componentDidMount() {

    let width = this.mount.clientWidth;
    let height = this.mount.clientHeight;

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(45, width / height, 1, 1000);
    this.camera.position.x = -10;
    this.camera.position.y = 10;
    this.camera.position.z = 10;

    let light = new THREE.PointLight(0xffffff, 0.9);
    this.camera.add(light);

    this.scene.add(this.camera);

    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setSize(width, height);
    this.mount.appendChild(this.renderer.domElement);

    new OrbitControls(this.camera, this.renderer.domElement);

    this.start();
  }

  componentWillUnmount() {
    this.stop();
    this.mount.removeChild(this.renderer.domElement);
  }

  animate = () => {
    this.renderer.render(this.scene, this.camera);
    this.frameId = window.requestAnimationFrame(this.animate);
  };

  start() {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  }

  stop() {
    cancelAnimationFrame(this.frameId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.source !== this.props.source) {
      if (this.mesh) this.mesh.removeFromParent();

      new OBJLoader().load(this.props.source,
        (object) => {
          let mat = new THREE.MeshLambertMaterial({
            color: 0x919191
          });
          let scale = 0.01 * this.props.scale;
          object.scale.set(scale, scale, scale);
          object.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              child.material = mat;
            }
          });

          this.scene.add(object);
          this.mesh = object;
        }
      );
    }
  }

  render() {
    return (
      <div
        className="modelPreview-canvas"
        ref={(mount) => {
          this.mount = mount;
        }}
      />
    );
  }
}
export default ObjPreview;

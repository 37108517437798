import { Component } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../app/app-context";
import ApiClient from "../../api-client/api-client";

class RegisterPage extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      chamberOfCommerceNumber: "",
      company: "",
      email: "",
      phoneNumber: "",
    };
  }

  register() {
    let tl = this.context.translate;
    new ApiClient(this.context)
      .call(
        "POST",
        "/v1/Register",
        {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          company: this.state.company,
          email: this.state.email,
          phoneNumber: this.state.phoneNumber,
          tenantId: this.context.theme.tenantId,
          chamberOfCommerceNumber: this.state.chamberOfCommerceNumber,
        },
        false
      )
      .then((result) => {
        if (result.ok) {
          this.context.addNotification(
            'success',
            tl('register_success')
          );
        }
      });
  }

  onInputChange(e) {
    let key = e.target.name;
    this.setState({
      [key]: e.target.value,
    });
  }

  render() {
    let tl = this.context.translate;

    return (
      <div className="popup-section registerContainer">
        <div className="popup-inputContainer">
          <h2>{tl('register_company_label')}</h2>
          <input
            placeholder={tl('register_company_placeholder')}
            name="company"
            value={this.state.company}
            onChange={this.onInputChange.bind(this)}
          ></input>
        </div>
        <div className="popup-inputContainer">
          <h2>{tl('general_coc_number')}</h2>
            <input
              placeholder={tl("register_coc_number_placeholder")}
              name="chamberOfCommerceNumber"
              value={this.state.cocNumber}
              onChange={this.onInputChange.bind(this)}
            ></input>
        </div>
        <div className="popup-inputContainer">
          <h2>{tl('register_first_name_label')}</h2>
          <input
            placeholder={tl('register_first_name_placeholder')}
            name="firstName"
            value={this.state.firstName}
            onChange={this.onInputChange.bind(this)}
          ></input>
        </div>
        <div className="popup-inputContainer">
          <h2>{tl('register_last_name_label')}</h2>
          <input
            placeholder={tl('register_last_name_placeholder')}
            name="lastName"
            value={this.state.lastName}
            onChange={this.onInputChange.bind(this)}
          ></input>
        </div>
        <div className="popup-inputContainer">
          <h2>{tl('register_email_label')}</h2>
          <input
            placeholder={tl('register_email_placeholder')}
            name="email"
            value={this.state.email}
            onChange={this.onInputChange.bind(this)}
          ></input>
        </div>
        <div className="popup-inputContainer ">
          <h2>{tl('register_phone_number_label')}</h2>
          <input
            placeholder={tl('register_phone_number_placeholder')}
            name="phoneNumber"
            value={this.state.phoneNumber}
            onChange={this.onInputChange.bind(this)}
          ></input>
        </div>
      </div>
    );
  }
}

export default RegisterPage;

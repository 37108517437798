import { Component } from "react";

import AppContext from "../../app-context";

import "./add-button.css";

class AddButton extends Component {
  static contextType = AppContext;

  render() {
    return (
      <button className="genericPage-addButton" onClick={this.props.onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
          <line className="svgAddButton" y2="16" transform="translate(9 1)" />
          <line
            className="svgAddButton"
            y2="16"
            transform="translate(17 9) rotate(90)"
          />
        </svg>
        {this.props.label || this.context.translate('general_add')}
      </button>
    );
  }
}

export default AddButton;

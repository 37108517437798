import { Component, createRef, setStateAsync } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";
import LoginPopup from "../../app/popup/login-popup";
import AuthenticationPage from "../authentication-page";
import LoginPage from "../login-page/login-page";
import RegisterPage from "../register-page/register-page";

class LandingPage extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.myRef = createRef();

    this.state = {
      isLoginPage: props.isLoginPage,
    };
  }


  handleClick = () => {
    return this.state.isLoginPage ? this.myRef.current.login() : this.myRef.current.register()
  }

  changeState = () => {
    console.log(this.state.isLoginPage);
    let isLoginPage = this.state.isLoginPage;
    this.setStateAsync((state) => ({
      isLoginPage: !isLoginPage,
    }));
  }


  render() {
    let tl = this.context.translate;

    return (
      <AuthenticationPage>
        <LoginPopup
          loginTitle={tl('login_title')} registerTitle={tl('register_title')} or={tl('register_or')} isLoginPage={this.state.isLoginPage}
          changeState={this.changeState.bind(this)}
          dismissable={true}
          actions={[
            {
              label: this.state.isLoginPage ? tl('login_log_in_button') : tl('register_register_button'),
              handler: this.handleClick.bind(this),
              submit: true,
            },
          ]} >
          {this.state.isLoginPage ? <LoginPage ref={this.myRef} setUser={this.props.setUser} /> : <RegisterPage ref={this.myRef} />}
        </LoginPopup>
      </AuthenticationPage >
    );
  }
}

export default LandingPage;

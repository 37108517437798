import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";
import UploadInput from "../../app/inputs/upload-input";

import Popup from "../../app/popup/popup";

import "./tenant-theme-popup.css";

class TenantThemePopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      tenantTheme: {
        primaryColor: props.tenant.tenantTheme.primaryColor,
        secondaryColor: props.tenant.tenantTheme.secondaryColor,
      },
    };
  }

  onInputChange(e) {
    let key = e.target.name;
    var value = e.target.value;

    this.setState((state) => ({
      tenantTheme: {
        ...state.tenantTheme,
        [key]: value,
      },
    }));
  }

  uploadFile(e) {
    let tl = this.context.translate;
    let key = e.target.name;
    var value = e.target.files[0];

    if (!value) return;

    let data = this.preparePostData({
      ImageFile: value,
    });
    e.target.value = "";

    this.askConfirmation(
      tl('notification_are_you_sure'),
      tl('warning_direct_file_upload'),
    )
      .then((confirm) => {
        if (!confirm) return;

        let apiClient = new ApiClient(this.context);

        apiClient
          .call(
            "PUT",
            `/v1/TenantTheme/${this.props.tenant.tenantTheme.id}/${key}`,
            data
          )
          .then((result) => {
            if (result.ok) {
              this.context.addNotification(
                "success",
                tl('notification_upload_file_success')
              );
              this.context.didUpdateTheme(result.json);
            } else {
              throw result;
            }
          });
      });
  }

  preparePostData(obj) {
    let data = new FormData();

    for (const [k, v] of Object.entries(obj)) {
      if (v) {
        data.append(k, v);
      }
    }

    return data;
  }

  updateTheme() {
    let apiClient = new ApiClient(this.context);

    apiClient
      .call(
        "PUT",
        `/v1/TenantTheme/${this.props.tenant.tenantTheme.id}`,
        this.state.tenantTheme
      )
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.onCommit && this.props.onCommit();
          this.context.addNotification(
            "success",
            this.context.translate('notification_tenant_update_success')
          );
          this.context.didUpdateTheme(result.json);
        } else {
          throw result;
        }
      });
  }

  render() {
    let tl = this.context.translate;
    return (
      // TODO: I18n
      <Popup
        title={tl('general_tenant_theme')}
        actions={[
          {
            label: tl('general_update'),
            handler: this.updateTheme.bind(this),
            submit: true,
          },
        ]}
        onDismiss={this.props.onDismiss}
      >
        <div className="tenantSettingsPopup">
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_primary_color')}</h2>
                <input
                  name="primaryColor"
                  type="color"
                  value={this.state.tenantTheme.primaryColor}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_favicon')}</h2>
                <UploadInput
                  name="UploadFavicon"
                  accept=".ico, .png"
                  asset={this.context.theme.favicon}
                  onChange={this.uploadFile.bind(this)}
                />
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_logo')}</h2>
                <UploadInput
                  name="UploadLogo"
                  accept=".png, .svg"
                  asset={this.context.theme.logo}
                  onChange={this.uploadFile.bind(this)}
                />
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_secondary_color')}</h2>
                <input
                  name="secondaryColor"
                  type="color"
                  value={this.state.tenantTheme.secondaryColor}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_backdrop')}</h2>
                <UploadInput
                  name="UploadBackground"
                  accept=".jpg, .jpeg"
                  asset={this.context.theme.background}
                  onChange={this.uploadFile.bind(this)}
                />
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_invoice_logo')}</h2>
                <UploadInput
                  name="UploadInvoiceLogo"
                  accept=".png"
                  asset={this.context.theme.invoiceLogo}
                  onChange={this.uploadFile.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

export default TenantThemePopup;

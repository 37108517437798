import { Link } from "react-router-dom";
import AppContext from "../app-context";
import ContactsButton from "./buttons/contacts-button";
import AccountsButton from "./buttons/accounts-button";
import TenantsButton from "./buttons/tenants-button";
import CalculateButton from "./buttons/calculate-button";
import OrdersButton from "./buttons/orders-button";
import QuotesButton from "./buttons/quotes-button";

import ApiClient from "../../api-client/api-client";

import BaseComponent from "../base-component";
import UserHover from "./user-hover/user-hover";
import HamburgerNavigation from "./hamburger-navigation/hamburger-navigation";

import ContactPopup from "../../contact-management/contact-popup/contact-popup";
import AccountPopup from "../../account-management/account-popup/account-popup";
import TenantThemePopup from "../../tenant-management/tenant-theme-popup/tenant-theme-popup";
import TenantMasterdataPopup from "../../tenant-management/tenant-masterdata-popup/tenant-masterdata-popup";
import AdminPopup from "../../admin/admin-popup";

import "./header.css";
import TenantDomainPopup from "../../tenant-management/tenant-domain-popup/tenant-domain-popup";

class Header extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      languages: [],
      countries: [],
    };
  }

  toggleProp(prop) {
    this.setState({
      [prop]: !this.state[prop],
    });
  }

  componentDidMount() {
    this.getCountries();

    new ApiClient(this.context)
      .call("GET", "/v1/Language", null, false, { "Accept-Language": null })
      .then((result) => {
        if (result.ok) {
          let langs = result.json;

          this.setStateAsync({
            languages: langs,
          }).then(() => {
            if (!this.context.language) {
              let defaultLang = langs.filter((l) => l.isDefault)[0];
              this.props.setLanguage(defaultLang);
            } else {
              this.props.setLanguage(this.context.language);
            }
          });
        }
      });
  }

  changeLanguage(e) {
    let code = e.target.value;
    let lang = this.state.languages.filter((l) => l.code === code)[0];

    this.props.setLanguage(lang);
  }

  render() {
    let tl = this.context.translate;
    let userHasClaim = this.context.userHasClaim;

    let logo = this.context.theme.logo || {};
    let logoUrl = this.context.settings.apiUrl + logo.filePath;

    let tenantId = this.context.theme && this.context.theme.tenantId;
    let accountId = this.context.user && this.context.user.account.id;

    let actions = [
      {
        label: tl('general_calculate'),
        button: <CalculateButton />,
        target: "/calculate",
        shouldDisplay: (mobile) => !mobile && userHasClaim("Quotes", "Add"),
      },
      {
        label: tl('general_quotes'),
        button: <QuotesButton />,
        target: "/quotes",
        shouldDisplay: (mobile) => userHasClaim("Quotes", "View"),
      },
      {
        label: tl('general_orders'),
        button: <OrdersButton />,
        target: "/orders",
        shouldDisplay: (mobile) => userHasClaim("Orders", "View"),
      },
      {
        label: tl('general_contacts'),
        button: <ContactsButton />,
        target: `/tenant/${tenantId}/account/${accountId}/contacts`,
        shouldDisplay: (mobile) => userHasClaim("Contacts", "View"),
      },
      {
        label: tl('general_accounts'),
        button: <AccountsButton />,
        target: `/tenant/${tenantId}/accounts`,
        shouldDisplay: (mobile) => userHasClaim("Accounts", "View"),
      },
      {
        label: tl('general_tenants'),
        button: <TenantsButton />,
        target: "/tenants",
        shouldDisplay: (mobile) => userHasClaim("Tenants", "View"),
      },
    ];

    let settings = [
      {
        label: tl('general_contact_data'),
        target: "#",
        action: this.toggleProp.bind(this, "contactPopupShown"),
        shouldDisplay: (mobile) => userHasClaim("Self", "Edit"),
      },
      {
        label: tl('general_account_data'),
        target: "#",
        action: this.toggleProp.bind(this, "accountPopupShown"),
        shouldDisplay: (mobile) => userHasClaim("Accounts", "Edit"),
      },
      {
        label: tl('general_tenant_theme'),
        target: "#",
        action: this.toggleProp.bind(this, "themePopupShown"),
        shouldDisplay: (mobile) => userHasClaim("Tenants", "Edit"),
      },
      {
        label: tl('general_master_data'),
        target: "#",
        action: this.toggleProp.bind(this, "masterdataPopupShown"),
        shouldDisplay: (mobile) => userHasClaim("Tenants", "Edit"),
      },
      {
        label: tl('general_domain'),
        target: "#",
        action: this.toggleProp.bind(this, "domainPopupShown"),
        shouldDisplay: (mobile) => userHasClaim("Tenants", "Edit"),
      },
      {
        label: tl('admin_settings_title'),
        target: "#",
        action: this.toggleProp.bind(this, "adminPopupShown"),
        shouldDisplay: (mobile) => userHasClaim("Translations", "Edit"),
      },
    ];

    return (
      <>
        <div className="header">
          <Link to="/dashboard">
            <img id="headerLogoWicam" src={logoUrl} alt="logo-wicam" />
          </Link>
          {this.context.user && (
            <nav className="topNavigation">
              <ul>
                {actions
                  .filter((a) => a.shouldDisplay(false))
                  .map((action) => (
                    <li key={action.label}>
                      <Link to={action.target}>{action.button}</Link>
                    </li>
                  ))}
              </ul>
            </nav>
          )}
          <div className="headerContainer">
            {this.context.user && (
              <div className="userContainerDropdownWrapper">
                <div className="userContainer">
                  <svg
                    id="svgUser"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                  >
                    <circle className="svgUser-1" cx="15" cy="15" r="15" />
                    <path
                      className="svgUser-2"
                      d="M17.124,12.487,14.6,10.845,15.55,9.1a2.782,2.782,0,0,0,.339-1.328V5.009a3.884,3.884,0,0,0-7.767,0V7.772A2.782,2.782,0,0,0,8.461,9.1l.952,1.745L6.887,12.487a2.765,2.765,0,0,0-1.262,2.326v2.957H18.386V14.813a2.765,2.765,0,0,0-1.262-2.326Zm.152,4.173H6.735V14.813a1.659,1.659,0,0,1,.757-1.4l3.387-2.2L9.435,8.569a1.669,1.669,0,0,1-.2-.8V5.009a2.774,2.774,0,0,1,5.548,0V7.772a1.669,1.669,0,0,1-.2.8l-1.444,2.647,3.387,2.2a1.659,1.659,0,0,1,.757,1.4Z"
                      transform="translate(2.964 5.491)"
                    />
                  </svg>

                  <span>{this.context.user.fullName}</span>
                </div>
                <UserHover id="userHover" actions={settings} />
              </div>
            )}
            <select
              className="langSelector"
              onChange={this.changeLanguage.bind(this)}
              value={this.context.language ? this.context.language.code : null}
            >
              {this.state.languages.map((lang) => (
                <option key={lang.code} value={lang.code}>
                  {lang.code}
                </option>
              ))}
            </select>
            {this.context.user && (
              <Link to="/logout">
                <button id="logoutButton">
                  <svg
                    id="svgLogout"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24.108 24.11"
                  >
                    <g transform="translate(0)">
                      <g>
                        <path
                          className="svgLogout-1"
                          d="M308.648,152.973a1.016,1.016,0,0,0-.218-.328l-3.013-3.013a1,1,0,1,0-1.42,1.42l1.3,1.3h-5.612a1,1,0,1,0,0,2.009H305.3l-1.3,1.3a1,1,0,1,0,1.421,1.42l3.013-3.013a1,1,0,0,0,.218-.327A1.006,1.006,0,0,0,308.648,152.973Z"
                          transform="translate(-284.616 -142.307)"
                        />
                        <path
                          className="svgLogout-1"
                          d="M17.092,14.064a1,1,0,0,0-1,1v5.023H12.069V4.018a1.006,1.006,0,0,0-.716-.962L7.864,2.009h8.223V7.032a1,1,0,1,0,2.009,0V1a1,1,0,0,0-1-1H1.019a.9.9,0,0,0-.1.019A.992.992,0,0,0,.489.168C.467.182.44.183.419.2S.408.216.4.222A1,1,0,0,0,.133.539.82.82,0,0,0,.1.624.953.953,0,0,0,.025.859a.625.625,0,0,0,0,.087C.027.966.014.984.014,1V21.1a1,1,0,0,0,.808.984L10.867,24.09a.935.935,0,0,0,.2.02,1.005,1.005,0,0,0,1-1v-1h5.023a1,1,0,0,0,1-1V15.069A1,1,0,0,0,17.092,14.064Z"
                          transform="translate(-0.014)"
                        />
                      </g>
                    </g>
                  </svg>
                </button>
              </Link>
            )}
          </div>

          <button
            className="hamburgerButton"
            onClick={this.toggleProp.bind(this, "hamburgerMenuShown")}
          >
            <div className="hamburgerLine"></div>
            <div className="hamburgerLine"></div>
            <div className="hamburgerLine"></div>
          </button>
        </div>
        {this.state.hamburgerMenuShown && (
          <HamburgerNavigation
            actions={actions}
            settings={settings}
            toggleHamburgerMenu={this.toggleProp.bind(
              this,
              "hamburgerMenuShown"
            )}
          />
        )}
        {this.state.contactPopupShown && (
          <ContactPopup
            onDismiss={this.toggleProp.bind(this, "contactPopupShown")}
            countries={this.state.countries}
            languages={this.state.languages}
            contact={this.context.user}
          />
        )}
        {this.state.accountPopupShown && (
          <AccountPopup
            onDismiss={this.toggleProp.bind(this, "accountPopupShown")}
            languages={this.state.languages}
            account={this.context.user.account}
          />
        )}
        {this.state.themePopupShown && (
          <TenantThemePopup
            onDismiss={this.toggleProp.bind(this, "themePopupShown")}
            tenant={this.context.user.account}
          />
        )}
        {this.state.masterdataPopupShown && (
          <TenantMasterdataPopup
            onDismiss={this.toggleProp.bind(this, "masterdataPopupShown")}
            tenant={this.context.user.account}
          />
        )}
        {this.state.domainPopupShown && (
          <TenantDomainPopup
            onDismiss={this.toggleProp.bind(this, "domainPopupShown")}
            tenant={this.context.user.account}
          />
        )}
        {this.state.adminPopupShown && (
          <AdminPopup
            onDismiss={this.toggleProp.bind(this, "adminPopupShown")}
          />
        )}
      </>
    );
  }
}

export default Header;

import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";

import Popup from "../../app/popup/popup";

import "./tenant-popup.css";

class TenantPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    var tenant = {};

    if (props.tenant) {
      tenant = {
        externalId: props.tenant.externalId,
        name: props.tenant.name,
        email: props.tenant.email,
        chamberOfCommerceNumber: props.tenant.chamberOfCommerceNumber,
        defaultLanguage: props.tenant.defaultLanguage || {},

        address: props.tenant.address,
        postalCode: props.tenant.postalCode,
        city: props.tenant.city,
        country: props.tenant.country,
      };
    }

    this.state = {
      countries: [],
      tenant: {
        externalId: "",
        name: "",
        email: "",
        chamberOfCommerceNumber: "",
        defaultLanguage: {},

        address: "",
        postalCode: "",
        city: "",
        country: "",

        ...tenant,
      },
    };
  }

  componentDidMount() {
    this.getCountries();
  }

  onPropChange(key, val) {
    this.setState(state => ({
      tenant: {
        ...state.tenant,
        [key]: val,
      },
    }));
  }

  onInputChange(e) {
    let key = e.target.name;
    let val = e.target.value;

    this.onPropChange(key, val);
  }

  onSelectChange(options, e) {
    let key = e.target.name;
    let id = e.target.value;
    let val = options.find(o => o.id === id) || {};

    this.onPropChange(key, val);
  }

  createTenant() {
    let tenant = {
      ...this.state.tenant
    };

    tenant.defaultLanguageId = tenant.defaultLanguage && tenant.defaultLanguage.id;

    let apiClient = new ApiClient(this.context);

    apiClient.call("POST", "/v1/Tenant", tenant).then((result) => {
      if (result.ok) {
        this.props.onDismiss();
        this.props.onCommit();
        this.context.addNotification(
          "success",
          this.context.translate('notification_tenant_create_success')
        );
      } else {
        throw result;
      }
    });
  }

  updateTenant() {
    let tenant = {
      ...this.state.tenant
    };

    tenant.defaultLanguageId = tenant.defaultLanguage && tenant.defaultLanguage.id;

    let isSelf =
      this.props.tenant && this.props.tenant.id === this.context.user.account.id;
    let apiClient = new ApiClient(this.context);

    apiClient
      .call("PUT", "/v1/Account/" + this.props.tenant.id, tenant)
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.onCommit();
          this.context.addNotification(
            "success",
            this.context.translate('notification_tenant_update_success')
          );

          if (isSelf) {
            this.context.setUser({
              ...this.context.user,
              account: result.json,
            });
          }
        } else {
          throw result;
        }
      });
  }

  toggleTenantActive(active) {
    let tl = this.context.translate;

    this.askConfirmation(
      tl('notification_are_you_sure'),
      tl('warning_tenant_activate'),
    )
      .then((confirm) => {
        if (!confirm) return;

        let apiClient = new ApiClient(this.context);
        let call = active
          ? ['PUT', `/v1/Tenant/${this.props.tenant.id}/Activate`]
          : ['DELETE', `/v1/Tenant/${this.props.tenant.id}`];

        apiClient
          .call(...call)
          .then((result) => {
            if (result.ok) {
              this.props.onDismiss();
              this.props.onCommit();
              this.context.addNotification(
                "success",
                active
                  ? tl('notification_tenant_reactivate_success')
                  : tl('notification_tenant_deactivate_success')
              );
            } else {
              throw result;
            }
          });
      })
  }

  render() {
    let tl = this.context.translate;
    let isSelf =
      this.props.tenant && this.props.tenant.id === this.context.user.account.id;

    return (
      // TODO: I18n
      <Popup
        title={tl('general_tenant')}
        actions={
          this.props.tenant
            ? [
              this.props.tenant.active && {
                label: tl('general_deactivate'),
                handler: this.toggleTenantActive.bind(this, false),
                className: 'red',
                disabled: isSelf,
              },
              !this.props.tenant.active && {
                label: tl('general_reactivate'),
                handler: this.toggleTenantActive.bind(this, true),
                className: 'green',
                disabled: isSelf,
              },
              {
                label: tl('general_update'),
                handler: this.updateTenant.bind(this),
                submit: true,
              },
            ]
            : [
              {
                label: tl('general_save'),
                handler: this.createTenant.bind(this),
                submit: true,
              },
            ]
        }
        onDismiss={this.props.onDismiss}
      >
        <div className="tenantPopup">
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_name')}</h2>
                <input
                  name="name"
                  value={this.state.tenant.name}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_email')}</h2>
                <input
                  name="email"
                  value={this.state.tenant.email}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_language')}</h2>
                <select
                  name="defaultLanguage"
                  value={this.state.tenant.defaultLanguage.id}
                  onChange={this.onSelectChange.bind(this, this.props.languages)}
                >
                  <option></option>
                  {this.props.languages.map((l) => (
                    <option key={l.id} value={l.id}>
                      {l.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_external_id')}</h2>
                <input
                  name="externalId"
                  value={this.state.tenant.externalId}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_coc_number')}</h2>
                <input
                  name="chamberOfCommerceNumber"
                  value={this.state.tenant.chamberOfCommerceNumber}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
          </div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_address')}</h2>
                <input
                  name="address"
                  value={this.state.tenant.address}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_zip_code')}</h2>
                <input
                  name="postalCode"
                  value={this.state.tenant.postalCode}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_city')}</h2>
                <input
                  name="city"
                  value={this.state.tenant.city}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_country')}</h2>
                <select
                  name="country"
                  value={this.state.tenant.country}
                  onChange={this.onInputChange.bind(this)}
                >
                  <option></option>
                  {this.state.countries.map((c) => (
                    <option key={c} value={c}>
                      {c}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

export default TenantPopup;

import { Component } from "react";
import "./news.css";
import AppContext from "../../app/app-context";
import Slider from "react-slick";

import Arrow from "./arrow/arrow";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewsItem from "./news-item";
import ApiClient from "../../api-client/api-client";
import AddNewNewsItem from "./add-new-news-item/add-new-news-item";
import moment from "moment";

class News extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      newsItems: [],
      activeSlide: 0,
      slidesToShow: 3,
    };
  }

  componentDidMount() {
    this.getNewsItemsFromAPI();
  }

  getNewsItemsFromAPI() {
    new ApiClient(this.context)
      .call("GET", "/v1/NewsItem")
      .then(async (result) => {
        if (result.ok) {
          let newsItems = result.json.map((item) => ({
            id: item.id,
            title: item.title,
            description: item.description,
            author: item.contact.fullName,
            date: item.updatedAt || item.createdAt,
            imageUri: item.image
              ? this.context.settings.apiUrl + item.image.filePath
              : "",
          }));

          newsItems.sort((a, b) => moment(a.date).diff(moment(b.date)));
          this.setState({
            newsItems: newsItems.reverse(),
          });
        }
      });
  }
  addNewItem() {
    let tl = this.context.translate;
    let formData = new FormData();
    formData.append("Title", tl('news_item_new_title'));
    formData.append("Description", tl('news_item_new_description'));
    new ApiClient(this.context)
      .call("POST", "/v1/NewsItem", formData)
      .then((result) => {
        if (result.ok) {
          this.getNewsItemsFromAPI();
        }
      });
  }

  render() {
    let tl = this.context.translate;
    let user = this.context.user;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      swipeToSlide: true,
      nextArrow: (
        <Arrow
          visible={
            this.state.activeSlide !==
            this.state.newsItems.length + 1 - this.state.slidesToShow
          }
          orientation="arrow-next"
        />
      ),
      prevArrow: (
        <Arrow
          visible={this.state.activeSlide !== 0}
          orientation="arrow-prev"
        />
      ),
      beforeChange: (current, next) => {
        this.setState({ activeSlide: next, slidesToShow: 3 });
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            beforeChange: (current, next) => {
              this.setState({ activeSlide: next, slidesToShow: 2 });
            },
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            beforeChange: (current, next) => {
              this.setState({ activeSlide: next, slidesToShow: 1 });
            },
          },
        },
      ],
    };

    return (
      <div className="news">
        <h1 className="news-title">{tl('dashboard_news_title')}</h1>
        <div className="news-horizontalLine"></div>
        <Slider {...settings} className="news-newsItemsContainer">
          {this.state.newsItems.map((item) => {
            return (
              <NewsItem
                key={item.id}
                item={item}
                getNewsItemsFromAPI={this.getNewsItemsFromAPI.bind(this)}
              />
            );
          })}
          {user && user.role.name !==
            "PurchaseManager" && (
              <AddNewNewsItem onClick={this.addNewItem.bind(this)} />
            )}
        </Slider>
      </div>
    );
  }
}

export default News;

import { Component } from "react";
import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import Popup from "../../app/popup/popup";
import AuthenticationPage from "../authentication-page";

class PasswordRequestPage extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      sent: false,
      email: "",
    };
  }

  onInputChange(e) {
    let key = e.target.name;
    this.setState({
      [key]: e.target.value,
    });
  }

  requestPassword() {
    this.setState({ sent: true });

    new ApiClient(this.context)
      .call("POST", "/v1/User/PasswordResetJwtRequest", {
        email: `${this.context.theme.tenantId}\\${this.state.email}`,
      })
      .then((json) => {
        if (json.errors) {
          this.setState({ sent: false });
        } else {
          this.context.addNotification(
            "success",
            this.context.translate('notifications_password_request_successfull')
          );
        }
      })
      .catch((e) => {
        this.setState({ sent: false });
        return e;
      });
  }

  render() {
    let tl = this.context.translate;

    return (
      <AuthenticationPage>
        <Popup
          title={tl('password_reset_title')}
          dismissable={true}
          className="passwordRequest"
          actions={[
            {
              label: tl('password_request_submit'),
              handler: this.requestPassword.bind(this),
              disabled: this.state.sent,
              submit: true,
            },
          ]}
        >
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('login_email_label')}</h2>
                <input
                  placeholder={tl('login_email_placeholder')}
                  name="email"
                  value={this.state.email}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
          </div>
        </Popup>
      </AuthenticationPage>
    );
  }
}

export default PasswordRequestPage;

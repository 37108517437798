import BaseComponent from "../../../app/base-component";
import AppContext from "../../../app/app-context";
import Popup from "../../../app/popup/popup";

class PendingPopup extends BaseComponent {
  static contextType = AppContext;

  render() {
    let tl = this.context.translate;
    return (
      <Popup
        title={tl("warning_pending_title")}
        onDismiss={this.props.onDismiss}
        actions={[
          {
            label: tl("general_cancel"),
            handler: this.props.onDismiss,
          },
          {
            label: tl("general_ok"),
            handler: this.props.onDismiss,
          },
        ]}
      >
        <div className="popup-section">
          <div className="popup-column">
            {tl("warning_pending_description")}
          </div>
        </div>
      </Popup>
    );
  }
}

export default PendingPopup;

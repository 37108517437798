import React from "react";
import Select from 'react-select';

import Checkbox from "../../app/checkbox/checkbox";
import AppContext from "../../app/app-context";
import ListItem from "./list-item/list-item";

import "./parts-list.css";
import BaseComponent from "../../app/base-component";
import ApiClient from "../../api-client/api-client";


class PartsList extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.partFileUploadRef = React.createRef();
    this.state = {
      accounts: [],
    };
  }

  componentDidMount() {
    this.getAccounts();
  }

  getAccounts() {
    if (this.context.userHasClaim('Accounts', 'View')) {
      return new ApiClient(this.context)
        .call(
          "GET",
          `/v1/Tenant/${this.context.theme.tenantId}/Account`,
          null,
          false,
          {},
          {
            Sorts: "name",
            Filters: "Active==true",
          }
        )
        .then((response) => {
          let accounts = response.json;

          return this.setStateAsync({
            accounts: accounts,
          });
        });
    }
    else {
      return this.setStateAsync({
        accounts: [
          this.context.user.account
        ],
      });
    }
  }

  checkboxSelectAll(e) {
    this.props.selectAllParts();
  }

  getMainParts() {
    return this.props.parts.filter((part) => part.collectionId === part.fileId);
  }

  getSubParts(part) {
    return this.props.parts.filter(
      (subPart) =>
        subPart.collectionId === part.fileId && subPart.fileId !== part.fileId
    );
  }

  onFileChange(e) {
    let key = e.target.name;
    let file = e.target.files[0];

    if (file) {
      let extensions = e.target.accept.split(', ');

      if (!extensions.some(e => file.name.toLocaleLowerCase().endsWith(e))) {
        let tl = this.context.translate;
        this.context.addNotification('error', tl('general_file_format_error'));

        return;
      }
    }

    this.props.onInputChange(key, file);
  }

  onSelectSettingChange(options, e) {
    let key = e.target.name;
    let val = options.find(o => o.id.toString() === e.target.value);

    this.props.onSettingChange(key, val);
  }

  onSelectChange(options, e) {
    let key = e.target.name;
    let val = options.find(o => o.id.toString() === e.target.value);

    this.props.onInputChange(key, val);
  }

  onCheckboxChange(e) {
    let key = e.target.name;
    let val = e.target.checked;

    this.props.onInputChange(key, val);
  }

  onInputChange(e) {
    let key = e.target.name;
    let val = e.target.value;

    this.props.onInputChange(key, val);
  }

  render() {
    let tl = this.context.translate;

    let parts = this.props.parts;
    let selectedParts = parts.filter(p => p.selected);

    let allSelected = parts.every((p) => p.selected);
    let isThreeDimensional = selectedParts.some(
      (p) => p.isThreeDimensional
    );
    let uniqueValuesForProp = (selector, keySelector = (v) => v, filterEmpty = false) => selectedParts
      .map(selector)
      .filter((v, i, s) => (!filterEmpty || !!v) && s.findIndex(e => (v && keySelector(v)) === (e && keySelector(e))) === i);
    let singleOrDefault = (array, fallback) =>
      (array.length === 1 && array[0]) || fallback;

    let amounts = uniqueValuesForProp(p => p.amount);
    let materials = uniqueValuesForProp(p => p.material, (v) => v.id);
    let materialThicknesses = uniqueValuesForProp(p => p.materialThickness, (v) => v.thickness);
    let surfaceTreatments = uniqueValuesForProp(p => p.surfaceTreatment);
    let bendings = uniqueValuesForProp(p => p.bending);
    let allowBendings = uniqueValuesForProp(p => p.allowBending);
    let counterSinks = uniqueValuesForProp(p => p.counterSinking);
    let allowCounterSinkings = uniqueValuesForProp(p => p.allowCounterSinking);
    let engravings = uniqueValuesForProp(p => p.engraving);
    let materialCertificates = uniqueValuesForProp(p => p.materialCertificate);
    let partFiles = uniqueValuesForProp(p => p.partFile);
    let partTappings = uniqueValuesForProp(p => p.tapping);

    let amount = singleOrDefault(amounts, '');
    let material = singleOrDefault(materials, { id: 0xDEADBEEF });
    let materialThickness = singleOrDefault(materialThicknesses, { thickness: 0 });
    let surfaceTreatment = singleOrDefault(surfaceTreatments, false);
    let bending = singleOrDefault(bendings, false);
    let allowBending = singleOrDefault(allowBendings, false);
    let counterSink = singleOrDefault(counterSinks, false);
    let allowCounterSinking = singleOrDefault(allowCounterSinkings, false);
    let engraving = singleOrDefault(engravings, false);
    let materialCertificate = singleOrDefault(materialCertificates, false);
    let partFile = singleOrDefault(partFiles, null);
    let partTapping = singleOrDefault(partTappings, null);

    let thicknessOptions = this.props.thicknesses.map(t => ({
      id: t.thickness,
      ...t,
    }));
    let materialOptions = materialThickness.materials || [];

    let availableOperations = this.context.tenantSettings.availableOperations;
    let allowedSurfaceTreatments = this.getSurfaceTreatmentMapping()
      .filter(sto => availableOperations.some(ao => ao === sto.tag));
    let allowedMaterialCertificates = this.getMaterialCertificateMapping()
      .filter(mco => availableOperations.some(ao => ao === mco.tag));
    let allowEngraving = availableOperations.some(ao => ao === "ENGRAVING");
    let allowTapping = this.props.tappings.length > 0 && selectedParts.length === 1;

    let accountOptions = this.state.accounts.map(a => ({ label: a.name, value: a.id }));

    let fileNames = selectedParts.length === 1 &&
      [
        partFile,
        partTapping && partTapping.file
      ]
        .filter(f => f)
        .map(f => f.name);

    return (
      <div className="partsList">
        <div className="partsList-header">
          <div className="group">
            <button
              className="generalButton"
              onClick={this.props.toggleUploadPopUp}
            >
              <svg
                className="upload-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
              >
                <g
                  data-name="Ellipse 1"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="1"
                >
                  <circle cx="9" cy="9" r="9" stroke="none" />
                  <circle cx="9" cy="9" r="8.5" fill="none" />
                </g>
                <path
                  data-name="Path 301"
                  d="M.044-10.311v3.333h3.35v1.77H.044v3.333H-1.984V-5.208h-3.35v-1.77h3.35v-3.333Z"
                  transform="translate(10.243 15.22)"
                  fill="#fff"
                />
              </svg>

              {tl('general_upload')}
            </button>

            {this.props.active && (
              <button
                className="generalButton"
                onClick={this.props.toggleImportOrderLinePopUp}
              >
                <svg
                  className="upload-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 18 18"
                >
                  <g
                    data-name="Ellipse 1"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1"
                  >
                    <circle cx="9" cy="9" r="9" stroke="none" />
                    <circle cx="9" cy="9" r="8.5" fill="none" />
                  </g>
                  <path
                    data-name="Path 301"
                    d="M.044-10.311v3.333h3.35v1.77H.044v3.333H-1.984V-5.208h-3.35v-1.77h3.35v-3.333Z"
                    transform="translate(10.243 15.22)"
                    fill="#fff"
                  />
                </svg>

                {tl('general_import')}
              </button>
            )}
          </div>

          {this.props.active && (
            <button
              className="generalButton"
              onClick={this.props.toggleFilterPopup}
            >
              <svg
                className="filter-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22.756 13.274"
              >
                <g transform="translate(-4.5 -9)">
                  <path
                    className="svgFilterButton"
                    fill="#fff"
                    d="M12.085,21.326a.948.948,0,0,1,.948-.948h5.689a.948.948,0,0,1,0,1.9H13.034A.948.948,0,0,1,12.085,21.326ZM8.293,15.637a.948.948,0,0,1,.948-.948H22.515a.948.948,0,0,1,0,1.9H9.241A.948.948,0,0,1,8.293,15.637ZM4.5,9.948A.948.948,0,0,1,5.448,9h20.86a.948.948,0,1,1,0,1.9H5.448A.948.948,0,0,1,4.5,9.948Z"
                  />
                </g>
              </svg>
              {tl('general_filter')}
            </button>
          )}
        </div>

        {this.props.active ? (
          <div className="partsList-container">
            <div className="partsList-controlsContainer">
              <Checkbox
                id="selectAll"
                onChange={this.checkboxSelectAll.bind(this)}
                selected={allSelected}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.223"
                  height="7.929"
                  viewBox="0 0 13.223 7.929"
                >
                  <path
                    id="Shape"
                    d="M13.23,1.336A1.325,1.325,0,0,0,10.969.4L6.62,4.748,2.272.4A1.327,1.327,0,1,0,.4,2.272L5.684,7.548a1.325,1.325,0,0,0,1.873,0l5.289-5.276A1.325,1.325,0,0,0,13.23,1.336Z"
                    transform="translate(-0.007 -0.007)"
                    fill="#1b1b1b"
                  />
                </svg>
              </Checkbox>

              <button id="delete-btn" onClick={this.props.deleteSelection}>
                <svg
                  id="delete-btn-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 15.344 18"
                >
                  <path
                    className="delete-btn-svg-1"
                    d="M13.5,13.5h1.279v7.672H13.5Z"
                    transform="translate(-8.385 -7.045)"
                  />
                  <path
                    className="delete-btn-svg-1"
                    d="M20.25,13.5h1.279v7.672H20.25Z"
                    transform="translate(-11.299 -7.045)"
                  />
                  <path
                    className="delete-btn-svg-1"
                    d="M4.5,6.75V8.037H5.779V20.906a1.283,1.283,0,0,0,1.279,1.287H17.287a1.283,1.283,0,0,0,1.279-1.287V8.037h1.279V6.75ZM7.057,20.906V8.037H17.287V20.906Z"
                    transform="translate(-4.5 -4.193)"
                  />
                  <path
                    className="delete-btn-svg-1"
                    d="M13.5,2.25h5.115V3.529H13.5Z"
                    transform="translate(-8.385 -2.25)"
                  />
                </svg>
              </button>
            </div>

            <div className="partsList-mainContainer">
              <div className="partsList-list">
                {this.getMainParts().map((part) => (
                  <ListItem
                    {...part}
                    subParts={this.getSubParts(part)}
                    key={part.fileId}
                    instantValidation={this.props.instantValidation}
                    togglePartSelected={this.props.togglePartSelected}
                    setPreviewPart={this.props.setPreviewPart}
                  />
                ))}
              </div>
              <div className="partsList-manufactureSettingsContainer">
                <div className="manufactureContainer">
                  <span>{tl('general_account')}</span>
                  <Select
                    className="reactSelect"
                    name="account"
                    value={accountOptions.find(a => a.value === this.props.account.id)}
                    options={accountOptions}
                    onChange={(o) => this.onSelectSettingChange(this.state.accounts, { target: { name: 'account', value: o.value } })}
                  />
                </div>

                <div className="title">
                  {tl('calculate_manufacture_settings')}
                </div>

                <div className="manufactureContainer">
                  <div className="selectsContainer">
                    <div>
                      <span>{tl('general_amount')}</span>
                      <input
                        name="amount"
                        type="number"
                        placeholder={tl('general_amount') + "..."}
                        onChange={this.onInputChange.bind(this)}
                        value={amount}
                      ></input>
                    </div>
                    <div></div>
                    <div>
                      <span>{tl('general_thickness')}</span>
                      <select
                        name="materialThickness"
                        onChange={this.onSelectChange.bind(this, thicknessOptions)}
                        value={materialThickness.thickness}
                        disabled={selectedParts.length === 0 || isThreeDimensional}
                      >
                        <option key={0} value={0}>
                          {tl('general_thickness') + "..."}
                        </option>
                        {thicknessOptions.map((option) => (
                          <option key={option.thickness} value={option.thickness}>
                            {`${option.thickness} ${tl('general_mm')}`}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <span>{tl('general_material')}</span>
                      <select
                        name="material"
                        onChange={this.onSelectChange.bind(this, materialOptions)}
                        value={material.id}
                        disabled={selectedParts.length === 0}
                      >
                        <option key={0xDEADBEEF} value={0xDEADBEEF}>
                          {tl('general_material') + "..."}
                        </option>
                        {materialOptions.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="title">
                  {tl('general_operations')}
                </div>

                {selectedParts.length > 0 && (
                  <div className="operationsContainer">
                    <div className="detectedOperaions">
                      <Checkbox
                        disabled={true}
                        title={
                          this.getCuttingTechnologyMapping().find(
                            c => c.tag === this.context.tenantSettings.cuttingTechnology
                          ).label
                        }
                        id="cuttingTechnology"
                        selected={true}
                      />

                      {allowBending && (
                        <Checkbox
                          title={tl('calculate_bending')}
                          selected={bending}
                          id="bending"
                          onChange={this.onCheckboxChange.bind(this)}
                        />
                      )}

                      {allowCounterSinking && (
                        <Checkbox
                          title={tl('calculate_counter_sinking')}
                          selected={counterSink}
                          id="counterSinking"
                          onChange={this.onCheckboxChange.bind(this)}
                        />
                      )}
                    </div>
                    <div className="selectableOperaions">
                      <div className="selectsContainer">
                        {allowedSurfaceTreatments.length > 0 && (
                          <select
                            name="surfaceTreatment"
                            onChange={this.onInputChange.bind(this)}
                            value={surfaceTreatment}
                          >
                            <option key={"NONE"} value={"NONE"}>
                              {tl('calculate_surface_treatment') + "..."}
                            </option>
                            {allowedSurfaceTreatments
                              .filter(o => o.label)
                              .map((surfaceTreatment) => (
                                <option
                                  key={surfaceTreatment.tag}
                                  value={surfaceTreatment.tag}
                                >
                                  {surfaceTreatment.label}
                                </option>
                              ))}
                          </select>
                        )}
                        {allowedMaterialCertificates.length > 0 && (
                          <select
                            name="materialCertificate"
                            onChange={this.onInputChange.bind(this)}
                            value={materialCertificate}
                          >
                            <option key={"NONE"} value={"NONE"}>
                              {tl('calculate_material_certificate') + "..."}
                            </option>
                            {allowedMaterialCertificates
                              .filter(o => o.label)
                              .map((materialCertificate) => (
                                <option
                                  key={materialCertificate.tag}
                                  value={materialCertificate.tag}
                                >
                                  {materialCertificate.label}
                                </option>
                              ))}
                          </select>
                        )}
                      </div>
                    </div>
                    <div className="additionalOperations">
                      {allowTapping && (
                        <div className="tappingOption">
                          <a onClick={this.props.toggleTappingPopUp}>
                            <span>{tl('calculate_tapping')}</span>
                            {selectedParts[0].tapping ? Object.values(selectedParts[0].tapping.lines).reduce((a, v) => a + v, 0) : 0}
                          </a>
                        </div>
                      )}
                      {allowEngraving > 0 && (
                        <Checkbox
                          title={tl('calculate_engraving')}
                          selected={engraving}
                          id="engraving"
                          onChange={this.onCheckboxChange.bind(this)}
                        />
                      )}
                    </div>
                  </div>
                )}

                {selectedParts.length === 1 &&
                  <div className="buttonContainer">
                    <input
                      type="file"
                      name="partFile"
                      accept={this.context.settings.extensions
                        .partFiles.map(s => `.${s}`).join(', ')}
                      className="partFileUpload"
                      ref={this.partFileUploadRef}
                      onChange={this.onFileChange.bind(this)}
                    />
                    <button className="generalButton" onClick={() => this.partFileUploadRef.current.click()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.475"
                        height="17.519"
                        viewBox="0 0 15.475 17.519"
                      >
                        <path
                          id="file"
                          d="M13.836,1.125H3.375V18.644H18.85V6.139Zm3.846,5.5v.05H13.3V2.293h.05ZM4.543,17.476V2.293h7.592V7.841h5.548v9.636Z"
                          transform="translate(-3.375 -1.125)"
                          fill="#fff"
                        />
                      </svg>
                      {tl('calculate_add_file_button')}
                    </button>
                    <span className="partFileLabel">
                      {fileNames.map(s => <div>{s}</div>)}
                    </span>
                  </div>
                }
              </div>
            </div>
          </div>
        ) : (
          <div className="partsList-container-noUpload">
            <p className="partsList-noUpload">
              {tl('calculate_part_upload_call_to_action')}...
            </p>
          </div>
        )}
        {this.props.active && (
          <div className="partsList-footer">
            <div className="unattendedContainer">
              <Checkbox
                title={tl('calculate_unattended')}
                selected={this.props.unattended}
                id="unattended"
                onChange={this.props.toggleUnattended}
              />
            </div>
            <button
              className="generalButton"
              onClick={this.props.calculateCosts}
            >
              {tl('calculate_calculate_costs_button')}
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default PartsList;

import { Component } from 'react';
import AppContext from '../app/app-context';

import './authentication-page.css';

class AuthenticationPage extends Component {
  static contextType = AppContext;

  render() {
    let background = this.context.theme.background || {};
    let backgroundUrl = this.context.settings.apiUrl + background.filePath;

    return (
      <div className="pageContainer">
        <div className="authenticationPage" style={{
          backgroundImage: 'url(' + backgroundUrl + ')'
        }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default AuthenticationPage;

import { Component, createRef } from 'react';

import './notification.css';

class Notification extends Component {
  constructor(props) {
    super(props);
    this.containerRef = createRef();
  }

  remove() {
    this.props.removeNotification(this.props.notification.key);
  }

  componentDidMount() {
    let container = this.containerRef.current;

    setTimeout(() => {
      container.style.marginBottom = '10px';
    }, 100);
  }

  render() {
    var progress = this.props.notification.timeRemaining / this.props.notification.time * 100
    return (
      <div className={"notification " + this.props.notification.severity} onClick={this.remove.bind(this)} ref={this.containerRef}>
        {this.props.notification.message}
        <div className="progress" style={{ right: progress + "%" }}>
        </div>
      </div>
    );
  }
}


export default Notification;
import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";

import Popup from "../../app/popup/popup";
import Table from "../../app/table/table";

import "./tenant-domain-popup.css";

class TenantDomainPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      tennantDomain: {
        domain: props.tenant.tenantSettings.domain,
      },
    };
  }

  onInputChange(e) {
    let key = e.target.name;
    var value = e.target.value;

    this.setState((state) => ({
      tennantDomain: {
        ...state.tennantDomain,
        [key]: value,
      },
    }));
  }

  updateTenantDomain() {
    let tl = this.context.translate;
    let apiClient = new ApiClient(this.context);

    apiClient
      .call(
        "PUT",
        `/v1/TenantSettings/${this.props.tenant.tenantSettings.id}/Domain`,
        this.state.tennantDomain
      )
      .then((result) => {
        if (result.ok) {
          this.props.onCommit && this.props.onCommit();

          this.setState({
            dialog: {
              className: 'tenantDnsPopup',
              title: "DNS settings",
              message: "Voer onderstaande DNS records in voor het domein:",
              content: <Table
                mapping={{
                  name: {
                    label: tl('general_name'),
                  },
                  type: {
                    label: tl('general_type'),
                  },
                  value: {
                    label: tl('general_value'),
                  },
                }}
                entries={result.json}
              />,
            },
          });
        } else {
          throw result;
        }
      });
  }

  render() {
    let tl = this.context.translate;
    return (
      // TODO: I18n
      <Popup
        className={"tenantDomainPopup"}
        title={tl('tenant_domain_title')}
        actions={[
          {
            label: tl('general_update'),
            handler: this.updateTenantDomain.bind(this),
            submit: true,
          },
        ]}
        onDismiss={this.props.onDismiss}
      >
        <div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('tenant_domain_name')}</h2>
                <input
                  name="domain"
                  value={this.state.tennantDomain.domain}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

export default TenantDomainPopup;

import BaseComponent from "../base-component";

import './toggle-input.css';

class ToggleInput extends BaseComponent {
  constructor(props) {
    super(props);
  }

  onChange(e) {
    let key = e.target.name;
    var value = e.target.checked;

    this.props.onChange(key, value);
  }

  render() {
    return (
      <label className="toggleInput">
        <input
          name={this.props.name}
          type="checkbox"
          checked={this.props.value}
          onChange={this.onChange.bind(this)}
        ></input>
        <div></div>
      </label>
    );
  }
}


export default ToggleInput;
import React from "react";
import AppContext from "../../../app/app-context";
import BaseComponent from "../../../app/base-component";
import ApiClient from "../../../api-client/api-client";

import Popup from "../../../app/popup/popup";
import Table from "../../../app/table/table";

import "./calculation-overview.css";
import Loader from "../../../app/loader/loader";

import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

class CalculationOverview extends BaseComponent {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);

    let plates = this.props.quote.parts.flatMap(part =>
      part.calculatePriceResult.nestingPlates.map(plate => ({
        ...plate,
        key: plate.svgFileId,
        nestingJobId: part.calculatePriceResult.nestingJobId,
        name: plate.svgFileId,
        material: part.material.materialName,
        thickness: part.material.thickness,
      })
      ))
      .filter((v, i, s) => s.findIndex(e => e.name === v.name) === i);

    let index = props.quote.tenantDeliveryTime.index;
    let price = props.quote.price ? props.quote.price[index] : 0.0;

    this.state = {
      selectedPlate: null,
      preview: null,
      total: price,
      plates: plates,
      calculations: props.quote.parts.map(
        (p) => ({
          ...p.calculatePriceResult.calculationResult,
          amount: p.amount,
        })
      ),
    };
  }

  componentDidMount() {
    this.onSelectPlate(this.state.plates[0]);
  }

  onSelectPlate(plate) {
    this.setStateAsync({
      preview: null,
      selectedPlate: plate,
    }).then(() => {
      this.getPreview(plate);
    });
  }

  getPreview(plate) {
    if (plate) {
      let url = "/v1/WCApi/GetFilePreview";

      new ApiClient(this.context)
        .call(
          "GET",
          url,
          null,
          false,
          {},
          {
            collectionId: plate.nestingJobId,
            fileId: plate.svgFileId,
          }
        )
        .then((response) => {
          this.setState({
            preview: response.json.image,
          });
        });
    }
  }

  download() {
    let quote = this.props.quote;
    let tl = this.context.translate;

    if (!quote.zipFile) {
      this.context.addNotification("error", tl('notifications_download_unavailable'));
      return;
    }

    this.context.addNotification("info", tl('notifications_download_started'));

    new ApiClient(this.context)
      .call("GET", quote.zipFile.filePath)
      .then((response) => {
        this.apiResponseAsDownload(response, `${quote.number}.zip`);
      });
  }

  render() {
    let tl = this.context.translate;
    let formatter = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    });
    let sumCalcProp = (k) =>
      this.state.calculations.reduce((a, c) => c[k] * c.amount + a, 0);
    let formatSumCalcProp = (k) => {
      let v = sumCalcProp(k);

      return v > 0 ? formatter.format(v) : "-";
    };

    return (
      <Popup title={tl("quotation_nesting_popup_title")} className={"calculationPopup"} onDismiss={this.props.onDismiss}>
        <div className="popup-section calculationOverview-container">
          {this.state.plates.length > 0 &&
            <div className="nestingPreview">
              <h2>{tl('quotation_nesting_model_view_title')}</h2>
              <div className="border plates">
                <Table
                  mapping={{
                    name: {
                      label: tl("quotation_nesting_plate_name"),
                    },
                    material: {
                      label: tl("general_material"),
                    },
                    thickness: {
                      label: tl("general_thickness"),
                    },
                    repeats: {
                      label: tl("general_amount"),
                    },
                    size: {
                      label: tl("quotation_nesting_size"),
                      value: (p) =>
                        `${Math.round(p.length)} x ${Math.round(p.width)}`,
                    },
                    efficiency: {
                      label: tl("quotation_nesting_efficiency"),
                      value: (p) =>
                        `${Math.round((p.areas.used / p.areas.total) * 100)}%`,
                    },
                  }}
                  entries={this.state.plates}
                  selected={this.state.selectedPlate}
                  onSelect={this.onSelectPlate.bind(this)}
                />
              </div>
              <div className="border preview">
                {this.state.preview ? (
                  <TransformWrapper>
                    <TransformComponent>
                      <img
                        alt=""
                        src={
                          "data:image/svg+xml;base64," +
                          encodeURIComponent(this.state.preview)
                        }
                      />
                    </TransformComponent>
                  </TransformWrapper>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          }
          <div className="priceSpecification">
            <h2>{tl('quotation_sales_price_specification_title')}</h2>
            <div className="border specification">
              <div>{tl('quotation_material_costs')}</div>
              <div>{formatSumCalcProp("materialPrice")}</div>
              <div>{tl("quotation_nesting_certificates")}</div>
              <div>{formatSumCalcProp("materialCertificatePrice")}</div>
              <div>{tl('quotation_cutting_costs')}</div>
              <div>{formatSumCalcProp("cuttingTechnologyPrice")}</div>
              <div>{tl("calculate_engraving")}</div>
              <div>{formatSumCalcProp("engravingPrice")}</div>
              <div>{tl("quotation_nesting_drilling")}</div>
              <div>{formatSumCalcProp("drillingPrice")}</div>
              <div>{tl("operation_tapping")}</div>
              <div>{formatSumCalcProp("tappingPrice")}</div>
              <div>{tl("operation_countersinking")}</div>
              <div>{formatSumCalcProp("counterSinkingPrice")}</div>
              <div>{tl("operation_bending")}</div>
              <div>{formatSumCalcProp("bendingPrice")}</div>
              <div>{tl("calculate_surface_treatment")}</div>
              <div>{formatSumCalcProp("surfaceTreatmentPrice")}</div>
              <div>{tl("quotation_nesting_coating")}</div>
              <div>{formatSumCalcProp("coatingPrice")}</div>
              <div className="divider"></div>
              <div className="total">{tl('quotation_total')}</div>
              <div className="total">{formatter.format(this.state.total)}</div>
            </div>
            <button
              type="button"
              className="popup-popupButton"
              onClick={this.download.bind(this)}
            >
              {tl('general_download')}
            </button>
          </div>
        </div>
      </Popup>
    );
  }
}

export default CalculationOverview;

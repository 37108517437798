import ApiClient from "../api-client/api-client";
import AppContext from "../app/app-context";
import BaseComponent from "../app/base-component";

import Popup from "../app/popup/popup";

import "./admin-popup.css";

class AdminPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {};
  }

  onInputChange(e) {
    let key = e.target.name;
    var value = e.target.value;

    if (e.target.type === "file") {
      value = e.target.files[0];
      if (!value) return;

      this.setState((state) => ({
        tenantSettings: {
          ...state.tenantSettings,
        },
      }));
    }

    this.setState((state) => ({
      tenantSettings: {
        ...state.tenantSettings,
        [key]: value,
      },
    }));
  }

  importTranslations(e) {
    let tl = this.context.translate;
    var value = e.target.files[0];

    if (!value) return;

    let data = this.preparePostData({
      ExcelFile: value,
    });
    e.target.value = "";

    this.askConfirmation(
      tl('notification_are_you_sure'),
      tl('warning_direct_file_import'),
    )
      .then((confirm) => {
        if (!confirm) return;

        let apiClient = new ApiClient(this.context);

        apiClient
          .call(
            'POST',
            '/v1/GlobalTranslation/ImportTranslations',
            data
          )
          .then((result) => {
            if (result.ok) {
              this.context.addNotification(
                "success",
                tl('notification_import_file_success')
              );
            } else {
              throw result;
            }
          });
      });
  }

  preparePostData(obj) {
    let data = new FormData();

    for (const [k, v] of Object.entries(obj)) {
      if (v) {
        data.append(k, v);
      }
    }

    return data;
  }

  render() {
    let tl = this.context.translate;

    return (
      // TODO: I18n
      <Popup
        title={tl('admin_settings_title')}
        onDismiss={this.props.onDismiss}
      >
        <div className="adminPopup">
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('admin_settings_translations')}</h2>
                <input
                  name="UploadExcelCalculation"
                  type="file"
                  onChange={this.importTranslations.bind(this)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

export default AdminPopup;

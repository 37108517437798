import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Header from "./header/header";
import Footer from "./footer/footer";

import LandingPage from "../authentication/landing-page/landing-page";
import LoginPage from "../authentication/login-page/login-page";
import RegisterPage from "../authentication/register-page/register-page";

import "./app.css";
import AppContext from "./app-context";
import PasswordRequestPage from "../authentication/password-request-page/password-request-page";
import PasswordResetPage from "../authentication/password-reset-page/password-reset-page";
import LogoutPage from "../authentication/logout-page/logout-page";
import Dashboard from "../dashboard/dashboard";
import NotificationContainer from "./notification/notification-container";
import Calculation from "../calculation/calculation";
import BaseComponent from "./base-component";
import ApiClient from "../api-client/api-client";
import QuoteManagementPage from "../quote-management/quote-management-page";
import OrderManagementPage from "../order-management/order-management";
import ContactManagementPage from "../contact-management/contact-management-page";
import AccountManagementPage from "../account-management/account-management-page";
import TenantManagementPage from "../tenant-management/tenant-management-page";
import Loader from "./loader/loader";
import Popup from "./popup/popup";

class App extends BaseComponent {
  constructor(props) {
    super(props);
    var user = null;
    try {
      user = JSON.parse(localStorage.getItem("user"));
    } catch (error) {
      localStorage.setItem("user", null);
    }

    var language = null;
    try {
      language = JSON.parse(localStorage.getItem("language"));
    } catch (error) {
      localStorage.setItem("language", null);
    }

    this.state = {
      settings: {
        ...require("./app-settings.json"),
        apiUrl: process.env.REACT_APP_API_URL,
        apiClient: process.env.REACT_APP_API_CLIENT,
        apiSecret: process.env.REACT_APP_API_SECRET,
      },
      user: user,
      userHasClaim: this.userHasClaim.bind(this),
      userHasRole: this.userHasRole.bind(this),
      language: language,
      translations: null,
      theme: null,
      tenantSettings: null,
      loading: false,
      translate: this.translate.bind(this),
      addNotification: this.addNotification.bind(this),
      setLoading: this.setLoading.bind(this),
      setUser: this.setUser.bind(this),
      didUpdateTheme: this.didUpdateTheme.bind(this),
      didUpdateTenantSettings: this.didUpdateTenantSettings.bind(this),
    };

    this.notificationContainerRef = React.createRef();
  }

  userHasClaim(type, value) {
    return !!this.state.user.claims
      .find(c => c.type === type && c.value === value);
  }

  userHasRole(name) {
    return !!(this.state.user.role.normalizedName === name);
  }

  componentDidMount() {
    window.onunhandledrejection = (e) => {
      this.addNotification(
        "error",
        this.state.translate('notifications_unknown_error')
      );
    };

    new ApiClient(this.state)
      .call(
        "GET",
        "/v1/TenantTheme/Styling",
        null, false, {},
        {
          domain: window.location.hostname,
        }
      )
      .then((result) => {
        let theme = result.ok && result.json || false;

        return this.didUpdateTheme(theme);

      }).then(this.getTenantSettings.bind(this));
  }

  applyTheme(theme) {
    if (theme) {
      let themeColorEl = document.querySelector('meta[name="theme-color"]')
        || document.createElement('meta');
      themeColorEl.name = 'theme-color';
      themeColorEl.content = theme.primaryColor;
      document.head.appendChild(themeColorEl);

      if (theme.favicon) {
        let faviconEl = document.querySelector('link[rel="icon"]')
          || document.createElement('link');
        faviconEl.rel = 'icon';
        faviconEl.href = this.state.settings.apiUrl + theme.favicon.filePath;
        document.head.appendChild(faviconEl);
      }

      let titleEl = document.querySelector('title')
        || document.createElement('title');
      titleEl.innerText = `${theme.tenantName} Webshop`;
      document.head.appendChild(titleEl);
    }
  }

  didUpdateTheme(theme) {
    let promises = [];

    if (!theme || !this.state.theme || theme.id === this.state.theme.id) {
      this.applyTheme(theme);
      promises.push(this.setStateAsync({
        theme: theme,
      }));
    }

    let accountTheme = (this.state.user && this.state.user.account.tenantTheme) || {};

    if (theme && theme.id === accountTheme.id) {
      promises.push(this.setUser({
        ...this.state.user,
        account: {
          ...this.state.user.account,
          tenantTheme: theme
        }
      }, false, false));
    }

    return Promise.all(promises);
  }

  setLoading(loading) {
    return this.setStateAsync({ loading: loading });
  }

  translate(key) {
    let translations = this.state.translations || {};
    return (translations[key]) || `{${key}}`;
  }

  addNotification(severity, message, time = 10) {
    console.trace(message);
    if (this.notificationContainerRef.current) {
      this.notificationContainerRef.current.addNotification(
        severity,
        message,
        time
      );
    }
  }

  setUser(user, loadLanguage = true, loadSettings = true) {
    localStorage.setItem("user", JSON.stringify(user));

    return this.setStateAsync({
      user: user,
    }).then(() => {
      if (user && loadLanguage) {
        return this.setLanguage(user.userLanguage);
      }
    }).then(() => {
      if (loadSettings) {
        return this.getTenantSettings()
      }
    });
  }

  getTenantSettings() {
    if (this.state.user) {
      return new ApiClient(this.state).call(
        'GET',
        `/v1/Tenant/${this.state.theme.tenantId}/TenantSettings/`
      )
        .then((result) => {
          if (result.ok) {
            let settings = result.json;

            return this.didUpdateTenantSettings(settings);
          }
        });
    }
  }

  didUpdateTenantSettings(tenantSettings) {
    let promises = [];

    if (!this.state.tenantSettings || tenantSettings.id === this.state.tenantSettings.id) {
      promises.push(this.setStateAsync({
        tenantSettings: tenantSettings,
      }));
    }

    let accountSettings = this.state.user.account.tenantSettings || {};

    if (tenantSettings.id === accountSettings.id) {
      promises.push(this.setUser({
        ...this.state.user,
        account: {
          ...this.state.user.account,
          tenantSettings: tenantSettings
        }
      }, false, false));
    }

    return Promise.all(promises);
  }

  setLanguage(language) {
    localStorage.setItem("language", JSON.stringify(language));

    return this.setStateAsync({
      language: language,
    })
      .then(() => {
        return new ApiClient(this.state).call(
          "GET",
          "/v1/GlobalTranslation/Dictionary"
        );
      })
      .then((result) => {
        if (result.ok) {
          let translations = result.json;

          return this.setStateAsync({
            translations: translations,
          });
        }
      });
  }

  render() {
    if (this.state.theme === false) {
      return (
        <Popup
          title={'Webshop not configured'}
        >
          <div className="popup-section">
            <div className="popup-column">It looks like the DNS records for this domain are set up correctly. This domain is however not linked to a tenant.</div>
          </div>
        </Popup>
      )
    }

    if (!this.state.theme
      || (this.state.user && !this.state.tenantSettings)) {
      return (
        <div
          className="app"
          style={{
            '--secondary-color': '#aaa',
            'height': '100vh',
          }}
        >
          <Loader />
          <NotificationContainer ref={this.notificationContainerRef} />
        </div>
      )
    }
    let theme = this.state.theme;

    return (
      <div
        className="app"
        style={{
          "--primary-color": theme.primaryColor,
          "--primary-color-light": `${theme.primaryColor}22`,
          "--secondary-color": theme.secondaryColor,
          "--secondary-color-medium": `${theme.secondaryColor}66`,
          "--secondary-color-light": `${theme.secondaryColor}22`,
        }}
      >
        <AppContext.Provider value={this.state}>
          <Router>
            <Header setLanguage={this.setLanguage.bind(this)} />
            <div className="app-pageContainer">
              {!this.state.user &&
                <Switch>
                  <Route path="/login">
                    <LandingPage isLoginPage={true} setUser={this.setUser.bind(this)} />
                  </Route>
                  <Route path="/register">
                    <LandingPage isLoginPage={false} setUser={this.setUser.bind(this)} />
                  </Route>
                  <Route path="/password-request">
                    <PasswordRequestPage />
                  </Route>
                  <Route path="/password-reset">
                    <PasswordResetPage />
                  </Route>
                  <Route path="/">
                    <Redirect to="/login" />
                  </Route>
                </Switch>
              }
              {this.state.user &&
                <Switch>
                  <Route path="/logout">
                    <LogoutPage setUser={this.setUser.bind(this)} />
                  </Route>
                  <Route path="/dashboard">
                    <Dashboard />
                  </Route>
                  <Route path="/calculate">
                    <Calculation />
                  </Route>
                  <Route path="/quotes">
                    <QuoteManagementPage />
                  </Route>
                  <Route
                    path="/account/:id/quotes"
                    render={(props) => (
                      <QuoteManagementPage accountId={props.match.params.id} />
                    )}
                  ></Route>
                  <Route path="/orders">
                    <OrderManagementPage />
                  </Route>
                  <Route
                    path="/account/:id/orders"
                    render={(props) => (
                      <OrderManagementPage accountId={props.match.params.id} />
                    )}
                  ></Route>
                  <Route
                    path="/tenant/:tenantId/account/:accountId/contacts"
                    render={(props) => (
                      <ContactManagementPage tenantId={props.match.params.tenantId} accountId={props.match.params.accountId} />
                    )}
                  ></Route>
                  <Route
                    path="/tenant/:id/accounts"
                    render={(props) => (
                      <AccountManagementPage tenantId={props.match.params.id} />
                    )}
                  ></Route>
                  <Route path="/tenants">
                    <TenantManagementPage />
                  </Route>
                  <Route path="/">
                    <Redirect to="/dashboard" />
                  </Route>
                </Switch>
              }
            </div>
            <Footer />
            <NotificationContainer ref={this.notificationContainerRef} />
          </Router>
        </AppContext.Provider>
      </div>
    );
  }
}

export default App;

import React from "react";
import { Component } from "react";

import AppContext from "../../app-context";
import { Link } from "react-router-dom";
import "./hamburger-navigation.css";

class HamburgerNavigation extends Component {
  static contextType = AppContext;

  render() {
    let tl = this.context.translate;
    let actions = this.props.actions.filter((action) =>
      action.shouldDisplay(true)
    );
    let settings = this.props.settings.filter((action) =>
      action.shouldDisplay(true)
    );

    return (
      <div id="hamburgerMenu">
        <div className="hamburgerHeaderWrapper">
          <span className="hamburgerMenu-title first-title">
            {tl('general_pages')}
          </span>
          <button
            className="hamburgerButton"
            onClick={this.props.toggleHamburgerMenu}
          >
            <div className="hamburgerLine"></div>
            <div className="hamburgerLine"></div>
            <div className="hamburgerLine"></div>
          </button>
        </div>
        {actions.map((action) => (
          <Link
            className="hamburgerMenu-link"
            to={action.target}
            onClick={this.props.toggleHamburgerMenu}
          >
            {action.label}
          </Link>
        ))}
        <span className="hamburgerMenu-title">
          {tl('general_settings')}
        </span>
        {settings.map((action) => (
          <Link
            key={action.label}
            to={action.target}
            className="hamburgerMenu-link"
            onClick={this.props.toggleHamburgerMenu}
          >
            {action.label}
          </Link>
        ))}
        <span className="hamburgerMenu-title">
          {tl('general_language')}
        </span>
        <select className="langSelector">
          <option>{tl('language_en')}</option>
          <option>{tl('language_nl')}</option>
          <option>{tl('language_de')}</option>
        </select>
      </div>
    );
  }
}

export default HamburgerNavigation;

import BaseComponent from "../base-component";

import './upload-input.css';

class UploadInput extends BaseComponent {
  constructor(props) {
    super(props);

    let asset = this.props.asset;
    let placeholder = null; // asset && asset.name;

    this.state = {
      fileName: placeholder,
    };
  }

  componentDidMount() {
    let tl = this.context.translate;

    this.setStateAsync(state => ({
      fileName: state.fileName || tl('general_no_file_selected')
    }))
  }

  onChange(e) {
    let file = e.target.files[0];

    if (file && e.target.accept) {
      let extensions = e.target.accept.split(', ');

      if (!extensions.some(e => file.name.toLocaleLowerCase().endsWith(e))) {
        let tl = this.context.translate;
        this.context.addNotification('error', tl('general_file_format_error'));

        return;
      }
    }

    this.setStateAsync({
      fileName: file.name,
    });

    this.props.onChange(e);
  }

  render() {
    let originalParts = (this.state.fileName || '').split('.');
    let name = originalParts.length > 1
      ? originalParts.slice(0, -1).join('.')
      : originalParts[0];
    let ext = originalParts.length > 1
      ? originalParts.slice(-1)[0]
      : '';
    let shortName = name.slice(0, 20);
    let shortExt = ext.slice(-7);
    let parts = [shortName, shortExt].filter(s => s);
    let separator = shortName === name && shortExt === ext
      ? '.'
      : '...';

    let fileName = parts.join(separator) + (shortExt ? '' : separator);

    return (
      <label className="uploadInput">
        <input
          name={this.props.name}
          accept={this.props.accept}
          type="file"
          onChange={this.onChange.bind(this)}
        ></input>
        {fileName}
      </label>
    );
  }
}


export default UploadInput;
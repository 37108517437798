import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";
import PercentageInput from "../../app/inputs/percentage-input";

import Popup from "../../app/popup/popup";
import { pick } from "../../helpers";

import "./account-popup.css";

class AccountPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    var account = {};

    if (props.account) {
      account = {
        externalId: props.account.externalId,
        name: props.account.name,
        email: props.account.email,
        phoneNumber: props.account.phoneNumber,
        defaultLanguage: props.account.defaultLanguage || {},

        address: props.account.address,
        postalCode: props.account.postalCode,
        city: props.account.city,
        country: props.account.country,

        deliveryAddress: props.account.deliveryAddress,
        deliveryPostalCode: props.account.deliveryPostalCode,
        deliveryCity: props.account.deliveryCity,
        deliveryCountry: props.account.deliveryCountry,

        invoiceEmail: props.account.invoiceEmail,

        vatPercentage: props.account.vatPercentage,
        vatNumber: props.account.vatNumber,
        chamberOfCommerceNumber: props.account.chamberOfCommerceNumber,

        laborPercentage: props.account.laborPercentage,
        materialsPercentage: props.account.materialsPercentage,
        generalPercentage: props.account.generalPercentage,
      };
    }

    this.state = {
      countries: [],
      account: {
        externalId: "",
        name: "",
        email: "",
        phoneNumber: "",
        defaultLanguage: {},

        address: "",
        postalCode: "",
        city: "",
        country: "",

        deliveryAddress: "",
        deliveryPostalCode: "",
        deliveryCity: "",
        deliveryCountry: "",

        invoiceEmail: "",

        vatPercentage: "",
        vatNumber: "",
        chamberOfCommerceNumber: "",

        laborPercentage: "",
        materialsPercentage: "",
        generalPercentage: "",

        ...account,
      },
    };
  }

  componentDidMount() {
    this.getCountries();

    if (!this.props.account) {
      let defaultLanguage = this.context.user.account.defaultLanguage;

      this.setState(state => {
        return {
          account: {
            ...state.account,
            defaultLanguage: defaultLanguage || {},
          },
        };
      });
    }
  }

  calcDeliveryProp(state, key, val) {
    const map = {
      'address': 'deliveryAddress',
      'postalCode': 'deliveryPostalCode',
      'city': 'deliveryCity',
      'country': 'deliveryCountry',
    };
    let mKey = map[key];

    if (mKey) {
      let oldVal = state.account[key];
      let mVal = state.account[mKey];

      if (!mVal || mVal === oldVal) {
        return { [mKey]: val };
      }
    }

    const rMap = Object.fromEntries(Object.entries(map).map(([k, v]) => [v, k]));
    let rmKey = rMap[key];

    if (rmKey && !val) {
      let rmVal = state.account[rmKey];

      return { [key]: rmVal };
    }

    return {};
  }

  onPropChange(key, val) {
    this.setState(state => ({
      account: {
        ...state.account,
        [key]: val,
        ...this.calcDeliveryProp(state, key, val),
      },
    }));
  }

  onInputChange(e) {
    let key = e.target.name;
    let val = e.target.value;

    this.onPropChange(key, val);
  }

  onSelectChange(options, e) {
    let key = e.target.name;
    let id = e.target.value;
    let val = options.find(o => o.id === id) || {};

    this.onPropChange(key, val);
  }

  createAccount() {
    let account = {
      ...this.state.account,
    };

    account.defaultLanguageId = account.defaultLanguage && account.defaultLanguage.id;

    let tl = this.context.translate;
    let apiClient = new ApiClient(this.context);

    apiClient
      .call(
        "POST",
        `/v1/Tenant/${this.props.tenantId}/Account`,
        account
      )
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.onCommit && this.props.onCommit();
          this.context.addNotification(
            "success",
            tl('notification_create_account_success')
          );
        } else {
          throw result;
        }
      });
  }

  updateAccount() {
    let account = {
      ...this.state.account,
    };

    account.defaultLanguageId = account.defaultLanguage && account.defaultLanguage.id;

    let tl = this.context.translate;
    let isSelf = this.props.account
      && this.props.account.id === this.context.user.account.id;

    let apiClient = new ApiClient(this.context);
    let url = isSelf
      ? `/v1/Account/UpdateCurrent`
      : `/v1/Account/${this.props.account.id}`;

    apiClient
      .call('PUT', url, account)
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.onCommit && this.props.onCommit();
          this.context.addNotification(
            'success',
            tl('notification_update_account_success')
          );

          if (isSelf) {
            this.context.setUser({
              ...this.context.user,
              account: result.json,
            });
          }
        } else {
          throw result;
        }
      });
  }

  toggleAccountActive(active) {
    let tl = this.context.translate;

    this.askConfirmation(
      tl('notification_are_you_sure'),
      tl('warning_account_activate'),
    )
      .then((confirm) => {
        if (!confirm) return;

        let apiClient = new ApiClient(this.context);
        let call = active
          ? ['PUT', `/v1/Account/${this.props.account.id}/Activate`]
          : ['DELETE', `/v1/Account/${this.props.account.id}`];

        apiClient
          .call(...call)
          .then((result) => {
            if (result.ok) {
              this.props.onDismiss();
              this.props.onCommit();
              this.context.addNotification(
                "success",
                active
                  ? tl('notification_account_reactivate_success')
                  : tl('notification_account_deactivate_success')
              );
            } else {
              throw result;
            }
          });
      })
  }

  render() {
    let tl = this.context.translate;
    let userHasRole = this.context.userHasRole;

    let isSelf = this.props.account
      && this.props.account.id === this.context.user.account.id;

    return (
      // TODO: I18n
      <Popup
        title="Account"
        actions={
          this.props.account
            ? [
              this.props.account.active && {
                label: tl('general_deactivate'),
                handler: this.toggleAccountActive.bind(this, false),
                className: 'red',
                disabled: isSelf,
              },
              !this.props.account.active && {
                label: tl('general_reactivate'),
                handler: this.toggleAccountActive.bind(this, true),
                className: 'green',
                disabled: isSelf,
              },
              {
                label: tl('general_update'),
                handler: this.updateAccount.bind(this),
                submit: true,
              },
            ]
            : [
              {
                label: tl('general_save'),
                handler: this.createAccount.bind(this),
                submit: true,
              },
            ]
        }
        onDismiss={this.props.onDismiss}
      >
        <div className="accountPopup">
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_name')}</h2>
                <input
                  name="name"
                  value={this.state.account.name}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_email')}</h2>
                <input
                  name="email"
                  value={this.state.account.email}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_language')}</h2>
                <select
                  name="defaultLanguage"
                  value={this.state.account.defaultLanguage.id}
                  onChange={this.onSelectChange.bind(this, this.props.languages)}
                >
                  <option></option>
                  {this.props.languages.map((l) => (
                    <option key={l.id} value={l.id}>
                      {l.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="popup-column">
              {!userHasRole('INSIDESALES') && !userHasRole('PURCHASEMANAGER') &&
                <div className="popup-inputContainer">
                  <h2>{tl('general_external_id')}</h2>
                  <input
                    name="externalId"
                    value={this.state.account.externalId}
                    onChange={this.onInputChange.bind(this)}
                  ></input>
                </div>
              }
              <div className="popup-inputContainer">
                <h2>{tl('general_phonenumber')}</h2>
                <input
                  name="phoneNumber"
                  value={this.state.account.phoneNumber}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
          </div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_address')}</h2>
                <input
                  name="address"
                  value={this.state.account.address}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_zip_code')}</h2>
                <input
                  name="postalCode"
                  value={this.state.account.postalCode}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_city')}</h2>
                <input
                  name="city"
                  value={this.state.account.city}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_country')}</h2>
                <select
                  name="country"
                  value={this.state.account.country}
                  onChange={this.onInputChange.bind(this)}
                >
                  <option></option>
                  {this.state.countries.map((c) => (
                    <option key={c} value={c}>
                      {c}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_delivery_address')}</h2>
                <input
                  name="deliveryAddress"
                  value={this.state.account.deliveryAddress}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_delivery_zip_code')}</h2>
                <input
                  name="deliveryPostalCode"
                  value={this.state.account.deliveryPostalCode}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_invoice_email')}</h2>
                <input
                  name="invoiceEmail"
                  value={this.state.account.invoiceEmail}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_delivery_city')}</h2>
                <input
                  name="deliveryCity"
                  value={this.state.account.deliveryCity}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_delivery_country')}</h2>
                <select
                  name="deliveryCountry"
                  value={this.state.account.deliveryCountry}
                  onChange={this.onInputChange.bind(this)}
                >
                  <option></option>
                  {this.state.countries.map((c) => (
                    <option key={c} value={c}>
                      {c}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_vat')}</h2>
                <PercentageInput
                  name="vatPercentage"
                  isDecimal={false}
                  value={this.state.account.vatPercentage}
                  onChange={this.onPropChange.bind(this)}
                />
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('general_coc_number')}</h2>
                <input
                  name="chamberOfCommerceNumber"
                  value={this.state.account.chamberOfCommerceNumber}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_vat_number')}</h2>
                <input
                  name="vatNumber"
                  value={this.state.account.vatNumber}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
          </div>
          {!userHasRole('PURCHASEMANAGER') &&
            <div className="popup-section">
              <div className="popup-column">
                <div className="popup-inputContainer">
                  <h2>{tl('account_labor_percentage')}</h2>
                  <PercentageInput
                    name="laborPercentage"
                    isDecimal={true}
                    value={this.state.account.laborPercentage}
                    onChange={this.onPropChange.bind(this)}
                  />
                </div>
                <div className="popup-inputContainer">
                  <h2>{tl('account_material_percentage')}</h2>
                  <PercentageInput
                    name="materialsPercentage"
                    isDecimal={true}
                    value={this.state.account.materialsPercentage}
                    onChange={this.onPropChange.bind(this)}
                  />
                </div>
              </div>
              <div className="popup-column">
                <div className="popup-inputContainer">
                  <h2>{tl('account_general_percentage')}</h2>
                  <PercentageInput
                    name="generalPercentage"
                    isDecimal={true}
                    value={this.state.account.generalPercentage}
                    onChange={this.onPropChange.bind(this)}
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </Popup>
    );
  }
}

export default AccountPopup;

import { Component } from "react";
import AppContext from "../../app-context";
import './buttons.css';

class QuotesButton extends Component {
  static contextType = AppContext;
  render() {
    let tl = this.context.translate;

    return (
      <span>
        <svg id='svgQuotes' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
          <circle className='svgQuotes-1' cx="17" cy="17" r="17" />
          <g transform="translate(-17 -22)">
            <circle className='svgQuotes-2' cx="1.5" cy="1.5" r="1.5" transform="translate(25 32)" />
            <circle className='svgQuotes-2' cx="1.5" cy="1.5" r="1.5" transform="translate(25 38)" />
            <circle className='svgQuotes-2' cx="1.5" cy="1.5" r="1.5" transform="translate(25 44)" />
            <line className='svgQuotes-3' x2="12" transform="translate(31 33.5)" />
            <line className='svgQuotes-3' x2="12" transform="translate(31 39.5)" />
            <line className='svgQuotes-3' x2="12" transform="translate(31 45.5)" />
          </g>
        </svg>
        <span>{tl('general_quotes')}</span>
      </span>
    );
  }
}

export default QuotesButton;

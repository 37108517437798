
import { Component } from "react";
import AppContext from "../../app-context";

class TableActionSave extends Component {
  static contextType = AppContext;

  render() {
    let tl = this.context.translate;
    let theme = this.context.theme;

    return (
      <div className="tableAction" onClick={e => { e.stopPropagation(); this.props.onClick(e); }} title={tl('general_download_pdf')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20">
          <path fill={theme.secondaryColor} d="M6.12946,7.5813A1,1,0,0,1,6.94318,6H8V3A1,1,0,0,1,9,2h2a1,1,0,0,1,1,1V6h1.05682a1,1,0,0,1,.81372,1.5813l-3.05682,4.27954a1,1,0,0,1-1.62744,0ZM17,14H3a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V15A1,1,0,0,0,17,14Z" />
        </svg>
      </div>
    );
  }
}

export default TableActionSave;

import { Component, createRef } from 'react';

import './popup.css';

class LoginPopup extends Component {

  constructor(props) {
    super(props);
    this.formRef = createRef();
  }

  renderButtons() {
    return this.props.actions &&
      <div className="popup-section popup-buttonSection">
        {this.props.actions
          .filter(a => a)
          .map(action =>
            <button
              type={action.submit ? "submit" : "button"}
              key={action.label}
              className={`popup-popupButton ${action.disabled && 'disabled'} ${action.className}`}
              onClick={action.handler}>
              {action.label}
            </button>
          )}
      </div>;
  }

  componentDidMount() {
    let input = this.formRef.current.querySelector('input, select');
    if (input) {
      input.focus();
    }
  }

  render() {
    return (
      <div className={`popup login-popup`}>
        <div className='popup-container'>
          <div className='popup-headerContainer'>
            <div className='popup-row'>
              <h1 style={{ opacity: this.props.isLoginPage ? 1 : 0.5 }} onClick={this.props.changeState}>{this.props.loginTitle}</h1>
              <h3 className='popup-popupTitle'>{this.props.or}</h3>
              <h1 style={{ opacity: this.props.isLoginPage ? 0.5 : 1 }} onClick={this.props.changeState}>{this.props.registerTitle}</h1>
            </div>
            {this.props.headerItem && this.props.headerItem}
            {this.props.onDismiss &&
              <button id='closePopupButton' onClick={this.props.onDismiss}>
                <svg id='svgClosePopup' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <g transform="translate(-307 -175)">
                    <circle className='svgClosePopup-1' cx="10" cy="10" r="10" transform="translate(307 175)" />
                    <g transform="translate(-0.5 -0.5)">
                      <line className='svgClosePopup-2' x2="8" y2="8" transform="translate(313.5 181.5)" />
                      <line className='svgClosePopup-2' x1="8" y2="8" transform="translate(313.5 181.5)" />
                    </g>
                  </g>
                </svg>
              </button>
            }
          </div>
          <form onSubmit={(e) => e.preventDefault()} ref={this.formRef}>
            {this.props.children}

            {this.renderButtons()}
          </form>
        </div >
      </div >
    );
  }
}

export default LoginPopup;
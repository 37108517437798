import Select from 'react-select';

import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";

import Popup from "../../app/popup/popup";

import "./account-import-popup.css";

class AccountImportPopup extends BaseComponent {
  static contextType = AppContext;

  import(e) {
    let tl = this.context.translate;
    var value = e.target.files[0];

    if (!value) return;

    let data = this.preparePostData({
      ExcelFile: value,
    });
    e.target.value = "";

    this.askConfirmation(
      tl('notification_are_you_sure'),
      tl('warning_direct_file_import'),
    )
      .then((confirm) => {
        if (!confirm) return;

        let apiClient = new ApiClient(this.context);

        apiClient
          .call(
            "PUT",
            "/v1/Tenant/" +
            this.props.tenantId +
            "/ImportAccountsAndContacts",
            data
          )
          .then((result) => {
            if (result.ok) {
              this.context.addNotification(
                "success",
                tl('notification_import_file_success')
              );
            } else {
              throw result;
            }
          });
      });
  }

  preparePostData(obj) {
    let data = new FormData();

    for (const [k, v] of Object.entries(obj)) {
      if (v) {
        data.append(k, v);
      }
    }

    return data;
  }

  render() {
    let tl = this.context.translate;
    return (
      // TODO: I18n
      <Popup
        title={tl('general_import')}
        actions={[]}
        onDismiss={this.props.onDismiss}
      >
        <div className="accountImportPopup">
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('general_import')}</h2>
                <input
                  type="file"
                  onChange={this.import.bind(this)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

export default AccountImportPopup;

import Select from 'react-select';

import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import BaseComponent from "../../app/base-component";
import ToggleInput from '../../app/inputs/toggle-input';
import UploadInput from '../../app/inputs/upload-input';

import Popup from "../../app/popup/popup";

import "./tenant-masterdata-popup.css";

const reactSelectStyles = {
  container: (provided, state) => ({
    ...provided,
    width: '204px',
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #E2E9F0',
  }),
  placeholder: (provided, state) => ({
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'var(--primary-color)',
    thickness: '1px',
  }),
};

class TenantMasterdataPopup extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      nestingStrategies: [],
      cuttingTechnologies: [],
      supportedErrors: [],
      supportedOperations: [],
      tenantSettings: {
        ...props.tenant.tenantSettings
      },
    };
  }

  componentDidMount() {
    Promise.all([
      this.getOptions("NestingStrategies", "nestingStrategies"),
      this.getOptions("CuttingTechnologies", "cuttingTechnologies"),
      this.getOptions("SupportedErrors", "supportedErrors", o => ({ label: `[${o.code}]\u00A0${o.value}` })),
      this.getOptions("SupportedOperations", "supportedOperations", o => ({ label: o.label })),
    ])
      .then(() => {
        this.setState({ loading: false });
      });
  }

  getOptions(type, key, transform = o => o) {
    let url = `/v1/TenantSettings/${type}`;
    return new ApiClient(this.context).call("GET", url)
      .then((response) => {
        if (response.ok) {
          return this.setStateAsync((state) => ({
            [key]: response.json
              .filter(o => o.selectable)
              .map(o => ({
                ...o,
                ...transform(o)
              })),
          }));
        }
      });
  }

  onPropChange(k, v) {
    return this.setStateAsync(state => ({
      tenantSettings: {
        ...state.tenantSettings,
        [k]: v,
      },
    }));
  }

  onInputChange(e) {
    let key = e.target.name;
    var value = e.target.value;

    this.onPropChange(key, value);
  }

  onReactSelectChange(key, selection) {
    this.setState(state => ({
      tenantSettings: {
        ...state.tenantSettings,
        [key]: selection.map(o => o.value),
      }
    }));
  }

  syncMasterData(e) {
    let tl = this.context.translate;
    let key = e.target.name;
    var value = e.target.files[0];

    if (!value) return;

    let data = this.preparePostData({
      ExcelFile: value,
    });
    e.target.value = "";

    this.askConfirmation(
      tl('notification_are_you_sure'),
      tl('warning_direct_file_import'),
    )
      .then((confirm) => {
        if (!confirm) return;

        let apiClient = new ApiClient(this.context);

        apiClient
          .call(
            "PUT",
            "/v1/TenantSettings/" +
            this.props.tenant.tenantSettings.id +
            "/" +
            key,
            data
          )
          .then((result) => {
            if (result.ok) {
              this.context.addNotification(
                "success",
                tl('notification_import_file_success')
              );
            } else {
              throw result;
            }
          });
      });
  }

  preparePostData(obj) {
    let data = new FormData();

    for (const [k, v] of Object.entries(obj)) {
      if (v) {
        data.append(k, v);
      }
    }

    return data;
  }

  updateTenantSettings() {
    let apiClient = new ApiClient(this.context);

    apiClient
      .call(
        "PUT",
        "/v1/TenantSettings/" + this.props.tenant.tenantSettings.id,
        this.state.tenantSettings
      )
      .then((result) => {
        if (result.ok) {
          this.props.onDismiss();
          this.props.onCommit && this.props.onCommit();
          this.context.addNotification(
            "success",
            this.context.translate('notification_client_update_success')
          );
          this.context.didUpdateTenantSettings(result.json);
        } else {
          throw result;
        }
      });
  }

  render() {
    let tl = this.context.translate;
    return (
      // TODO: I18n
      <Popup
        title={tl('tenant_settings_title')}
        actions={[
          {
            label: tl('general_update'),
            handler: this.updateTenantSettings.bind(this),
            submit: true,
          },
        ]}
        onDismiss={this.props.onDismiss}
      >
        <div className="tenantSettingsPopup">
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('tenant_coc_number')}</h2>
                <input
                  name="cocNumber"
                  value={this.state.tenantSettings.cocNumber}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_email')}</h2>
                <input
                  name="email"
                  value={this.state.tenantSettings.email}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_website')}</h2>
                <input
                  name="website"
                  value={this.state.tenantSettings.website}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('tenant_iban')}</h2>
                <input
                  name="iban"
                  value={this.state.tenantSettings.iban}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_phone_number')}</h2>
                <input
                  name="phoneNumber"
                  value={this.state.tenantSettings.phoneNumber}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
          </div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('tenant_wicam_client_id')}</h2>
                <input
                  name="wicamClientId"
                  value={this.state.tenantSettings.wicamClientId}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_wicam_email_sender')}</h2>
                <input
                  name="emailSender"
                  value={this.state.tenantSettings.emailSender}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('tenant_wicam_client_secret')}</h2>
                <input
                  name="wicamClientSecret"
                  value={this.state.tenantSettings.wicamClientSecret}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_wicam_email_password')}</h2>
                <input
                  type="password"
                  name="emailPassword"
                  value={this.state.tenantSettings.emailPassword}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
          </div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('tenant_cutting_technology')}</h2>
                <select
                  name="cuttingTechnology"
                  value={this.state.tenantSettings.cuttingTechnology}
                  onChange={this.onInputChange.bind(this)}
                >
                  {this.state.cuttingTechnologies.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_ignored_error_codes')}</h2>
                <Select
                  isMulti={true}
                  styles={reactSelectStyles}
                  value={this.state.tenantSettings.ignoredErrorCodes.map(v => this.state.supportedErrors.find(o => o.value === v))}
                  options={this.state.supportedErrors}
                  onChange={this.onReactSelectChange.bind(this, 'ignoredErrorCodes')}
                />
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_available_operations')}</h2>
                <Select
                  isMulti={true}
                  styles={reactSelectStyles}
                  value={this.state.tenantSettings.availableOperations.map(v => this.state.supportedOperations.find(o => o.value === v))}
                  options={this.state.supportedOperations}
                  onChange={this.onReactSelectChange.bind(this, 'availableOperations')}
                />
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('tenant_nesting_strategy')}</h2>
                <select
                  name="nestingStrategy"
                  value={this.state.tenantSettings.nestingStrategy}
                  onChange={this.onInputChange.bind(this)}
                >
                  {this.state.nestingStrategies.map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_quote_period')}</h2>
                <input
                  name="overdueQuotePeriod"
                  type="number" min="0" step="1"
                  value={this.state.tenantSettings.overdueQuotePeriod}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
          </div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('tenant_pending_bending_hours_enabled')}</h2>
                <ToggleInput
                  name="calculationPendingBendingHoursEnabled"
                  value={this.state.tenantSettings.calculationPendingBendingHoursEnabled}
                  onChange={this.onPropChange.bind(this)}
                />
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_pending_cutting_hours_enabled')}</h2>
                <ToggleInput
                  name="calculationPendingCuttingHoursEnabled"
                  value={this.state.tenantSettings.calculationPendingCuttingHoursEnabled}
                  onChange={this.onPropChange.bind(this)}
                />
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_pending_material_weight_enabled')}</h2>
                <ToggleInput
                  name="calculationPendingMaterialWeightEnabled"
                  value={this.state.tenantSettings.calculationPendingMaterialWeightEnabled}
                  onChange={this.onPropChange.bind(this)}
                />
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_pending_price_enabled')}</h2>
                <ToggleInput
                  name="calculationPendingPriceEnabled"
                  value={this.state.tenantSettings.calculationPendingPriceEnabled}
                  onChange={this.onPropChange.bind(this)}
                />
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('tenant_pending_bending_hours')}</h2>
                <input
                  name="calculationPendingBendingHours"
                  type="number"
                  min="0"
                  step="1"
                  value={this.state.tenantSettings.calculationPendingBendingHours}
                  disabled={!this.state.tenantSettings.calculationPendingBendingHoursEnabled}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_pending_cutting_hours')}</h2>
                <input
                  name="calculationPendingCuttingHours"
                  type="number"
                  min="0"
                  step="1"
                  value={this.state.tenantSettings.calculationPendingCuttingHours}
                  disabled={!this.state.tenantSettings.calculationPendingCuttingHoursEnabled}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_pending_material_weight')}</h2>
                <input
                  name="calculationPendingMaterialWeight"
                  type="number"
                  min="0"
                  step="1"
                  value={this.state.tenantSettings.calculationPendingMaterialWeight}
                  disabled={!this.state.tenantSettings.calculationPendingMaterialWeightEnabled}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_pending_price')}</h2>
                <input
                  name="calculationPendingPrice"
                  type="number"
                  min="0"
                  step="1"
                  value={this.state.tenantSettings.calculationPendingPrice}
                  disabled={!this.state.tenantSettings.calculationPendingPriceEnabled}
                  onChange={this.onInputChange.bind(this)}
                ></input>
              </div>
            </div>
          </div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('tenant_calculation_sheet')}</h2>
                <UploadInput
                  name="UploadExcelCalculation"
                  accept=".xlsx"
                  asset={this.context.tenantSettings.excelCalculation}
                  onChange={this.syncMasterData.bind(this)}
                />
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('tenant_header_calculation_sheet')}</h2>
                <UploadInput
                  name="UploadHeaderCalculation"
                  accept=".xlsx"
                  asset={this.context.tenantSettings.headerCalculation}
                  onChange={this.syncMasterData.bind(this)}
                />
              </div>
            </div>
          </div>
          <div className="popup-section">
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('tenant_material_groups')}</h2>
                <UploadInput
                  name="ImportTenantMaterialGroups"
                  accept=".xlsx"
                  onChange={this.syncMasterData.bind(this)}
                />
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_plates')}</h2>
                <UploadInput
                  name="ImportTenantPlates"
                  accept=".xlsx"
                  onChange={this.syncMasterData.bind(this)}
                />
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_tapping_options')}</h2>
                <UploadInput
                  name="ImportTenantTappings"
                  accept=".xlsx"
                  onChange={this.syncMasterData.bind(this)}
                />
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_rates')}</h2>
                <UploadInput
                  name="ImportTenantRates"
                  accept=".xlsx"
                  onChange={this.syncMasterData.bind(this)}
                />
              </div>
            </div>
            <div className="popup-column">
              <div className="popup-inputContainer">
                <h2>{tl('tenant_material_prices')}</h2>
                <UploadInput
                  name="ImportTenantMaterials"
                  accept=".xlsx"
                  onChange={this.syncMasterData.bind(this)}
                />
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_nesting_parameters')}</h2>
                <UploadInput
                  name="ImportTenantNestingParameters"
                  accept=".xlsx"
                  onChange={this.syncMasterData.bind(this)}
                />
              </div>
              <div className="popup-inputContainer">
                <h2>{tl('tenant_machines')}</h2>
                <UploadInput
                  name="ImportTenantMachines"
                  accept=".xlsx"
                  onChange={this.syncMasterData.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}

export default TenantMasterdataPopup;

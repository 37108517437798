import React from "react";
import "./news-item.css";
import { fileAsBase64 } from "../../helpers";
import EditIcon from "./edit/edit-icon";
import ExitIcon from "./exit/exit-icon";
import SaveIcon from "./save/save-icon";
import ApiClient from "../../api-client/api-client";
import AppContext from "../../app/app-context";
import moment from "moment";
import DeleteIcon from "./delete/delete-icon";
import BaseComponent from "../../app/base-component";

class NewsItem extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      isEditable: props.item.isNew || false,
      article: props.item,
      imageUri: props.item.imageUri,
    };
    this.titleInputRef = React.createRef();
    this.descriptionInputRef = React.createRef();
  }

  componentDidMount() {
    this.setTextAreaHeight();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item !== this.props.item) {
      this.setStateAsync({
        article: {
          ...this.props.item,
        },
        imageUri: this.props.item.imageUri,
      });
    }
  }

  toggleEditable() {
    this.setState((state) => ({
      isEditable: !state.isEditable,
    }));
  }

  onFileUpload(e) {
    fileAsBase64(e.target.files[0]).then((dataUri) => {
      this.setState((state) => ({
        article: {
          ...state.article,
          image: e.target.files[0],
        },
        imageUri: dataUri,
      }));
    });
  }

  onInputChange(e) {
    let key = e.target.name;
    var value = e.target.value;

    this.setState((state) => ({
      article: {
        ...state.article,
        [key]: value,
      },
    }));
  }
  updateArticle() {
    let formData = new FormData();

    formData.append("Image", this.state.article.image);
    formData.append("Title", this.state.article.title);
    formData.append("Description", this.state.article.description);

    new ApiClient(this.context)
      .call("PUT", "/v1/NewsItem/" + this.state.article.id, formData)
      .then((result) => {
        if (result.ok) {
          this.props.getNewsItemsFromAPI();
        }
      });
    this.toggleEditable();
  }
  onChangeDescription(text) {
    this.setState((state) => ({
      article: {
        ...state.article,
        description: text,
      },
    }));
  }

  deleteArticle() {
    new ApiClient(this.context)
      .call("DELETE", "/v1/NewsItem/" + this.state.article.id)
      .then((result) => {
        if (result.ok) {
          this.props.getNewsItemsFromAPI();
        }
      });
  }

  setTextAreaHeight() {
    this.titleInputRef.current.style.height =
      this.titleInputRef.current.scrollHeight + "px";
    this.descriptionInputRef.current.style.height =
      this.descriptionInputRef.current.scrollHeight + "px";
  }
  textAreaAdjustHeight(e) {
    e.target.style.height = e.target.scrollHeight + "px";
  }
  render() {
    let article = this.state.article;
    let tl = this.context.translate;
    let user = this.context.user;

    return (
      <div className="news-newsItem">
        <>
          {user && user.role.name !==
            "PurchaseManager" && (
              <>
                <button
                  className={
                    "standardButton " +
                    (this.state.isEditable ? "editButton" : "cancelButton")
                  }
                  onClick={this.toggleEditable.bind(this)}
                >
                  {this.state.isEditable ? (
                    <ExitIcon color={"#ffffff"} />
                  ) : (
                    <EditIcon color={"#ffffff"} />
                  )}
                </button>
                {this.state.isEditable && (
                  <button
                    className="standardButton saveButton"
                    onClick={this.updateArticle.bind(this)}
                  >
                    <SaveIcon color={"#ffffff"} />
                  </button>
                )}
                {this.state.isEditable && (
                  <button
                    className="standardButton deleteButton"
                    onClick={this.deleteArticle.bind(this)}
                  >
                    <DeleteIcon color={"#ffffff"} />
                  </button>
                )}
              </>
            )}

          <label
            className="fileUpload"
            style={{
              backgroundImage: `url(${this.state.imageUri})`,
            }}
          >
            {this.state.isEditable && (
              <div className="overlay">
                <p className="uploadText">
                  {tl('news_item_upload_image')}
                </p>
              </div>
            )}

            <input
              className="standardFileUpload"
              disabled={!this.state.isEditable}
              type="file"
              onChange={this.onFileUpload.bind(this)}
            ></input>
          </label>

          <div className="news-newsItemDivider">
            <span className="news-newsItemAuthor">{article.author}</span>
            <span className="news-newsItemDate">
              {moment(article.date).format("DD-MM-Y")}
            </span>
          </div>
          <textarea
            ref={this.titleInputRef}
            onKeyUp={this.textAreaAdjustHeight}
            name="title"
            className="news-newsItemTitle"
            disabled={!this.state.isEditable}
            value={article.title}
            onChange={this.onInputChange.bind(this)}
          ></textarea>
          <textarea
            ref={this.descriptionInputRef}
            onKeyUp={this.textAreaAdjustHeight}
            name="description"
            className="news-newsItemExcerpt"
            disabled={!this.state.isEditable}
            value={article.description}
            onChange={this.onInputChange.bind(this)}
          ></textarea>
        </>
      </div>
    );
  }
}
export default NewsItem;

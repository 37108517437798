export function pick(object, ...keys) {
  return Object.fromEntries(
    keys.map(k => [k, object[k]])
  );
}

export function delay(t) {
  return new Promise(function (resolve) {
    setTimeout(resolve, t);
  });
}

export function arrayify(thing) {
  if (!thing) {
    return [];
  }
  else if (Array.isArray(thing)) {
    return thing;
  }
  else {
    return [thing];
  }
}

export function fileAsBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function buildFormData(formData, data, parentKey) {
  const nest = (key, val) => {
    buildFormData(formData, val, parentKey ? `${parentKey}[${key}]` : key);
  };

  if (data instanceof Array) {
    data.forEach((val, key) => nest(key, val));
  }
  else if (data instanceof Object
    && !(data instanceof Date)
    && !(data instanceof File)) {
    Object.entries(data).forEach(([key, val]) => nest(key, val));
  }
  else {
    if (data !== null) {
      formData.append(parentKey, data);
    }
  }
}

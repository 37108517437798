import BaseComponent from "../../../app/base-component";
import AppContext from "../../../app/app-context";
import Popup from "../../../app/popup/popup";

class EmailPopup extends BaseComponent {
  static contextType = AppContext;

  sendEmail() {
    let attachment = this.props.file;
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      var recipient = "benelux@wicam.com";
      var subject = `Calculatie - ${attachment.name}`;
      var body = ""; // Textual content of the message

      var data = `To: ${recipient}\n`
        + `Subject: ${subject}\n`
        + 'X-Unsent: 1\n'
        + 'Content-Type: multipart/mixed; boundary=652b8c4dcb00cdcdda1e16af36781caf\n'
        + '\n'
        + '--652b8c4dcb00cdcdda1e16af36781caf\n'
        + 'Content-Type: text/html; charset=UTF-8\n'
        + '\n'
        + '<html>\n'
        + '<body>\n'
        + `${body}\n`
        + '</body>\n'
        + '</html>\n'
        + '\n'
        + '--652b8c4dcb00cdcdda1e16af36781caf\n'
        + `Content-Type: application/octet-stream; name=${attachment.name}\n`
        + 'Content-Transfer-Encoding: base64\n'
        + 'Content-Disposition: attachment\n'
        + '\n'
        + `${reader.result}\n`
        + '\n'
        + '--652b8c4dcb00cdcdda1e16af36781caf--\n';

      var blob = new Blob([data], { type: 'text/plain' });

      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = `${attachment.name}.eml`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }, false);

    reader.readAsDataURL(attachment);
  }

  render() {
    let tl = this.context.translate;
    return (
      <Popup
        title={tl('warning_assemblies_not_supported_yet')}
        actions={[
          {
            label: tl('general_cancel'),
            handler: this.props.hide,
          },
          {
            label: tl('calculate_email_wicam_button'),
            handler: () => {
              this.props.hide();
              this.sendEmail();
            },
          },
        ]}
        onDismiss={this.props.hide}
      >
        <div className="popup-section">
          <div className="popup-column">
            {tl('warning_assembly_upload')}
          </div>
        </div>
      </Popup>
    );
  }
}

export default EmailPopup;

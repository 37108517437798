import { Component } from "react";
import AppContext from "../../app/app-context";

import Popup from "../../app/popup/popup";
import ModelPreview from './preview/model-preview';
import "./preview-popup.css";

class PreviewPopup extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      previewPart: null,
    }
  }

  componentDidMount() {
    if(this.props.previewPart) {
      this.setState((state) => ({
        previewPart: this.props.previewPart,
      }));
    }
  }

  render() {
    let tl = this.context.translate;
    return(
      <Popup
        title={tl('calculate_part_model_view_title')}
        onDismiss={this.props.onDismiss}
      >
        <div className="previewPopup">
          
        <div className="popup-section">
          <div className="popup-column">
            {/* <span className="partPreviewContainer-title">{tl('calculate_part_model_view_title')}</span> */}
            <ModelPreview part={this.state.previewPart} />
          </div>
        </div>
        </div>
      </Popup>
    );
  }
}

export default PreviewPopup;

import { Component } from "react";
import AppContext from "../../app-context";

class TableActionDelete extends Component {
  static contextType = AppContext;

  render() {
    let tl = this.context.translate;
    let theme = this.context.theme;

    return (
      <div className="tableAction" onClick={e => { e.stopPropagation(); this.props.onClick(e); }} title={tl('general_delete')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20">
          <path fill={theme.secondaryColor} d="M17,5V6H3V5A1,1,0,0,1,4,4H7V3A1,1,0,0,1,8,2h4a1,1,0,0,1,1,1V4h3A1,1,0,0,1,17,5ZM4,16a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V8H4Z" />
        </svg>
      </div>
    );
  }
}

export default TableActionDelete;

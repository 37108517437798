import React, { Component } from "react";
import { generateUUID } from "three/src/math/MathUtils";
import AppContext from "../../app/app-context";

import Popup from "../../app/popup/popup";
import TableActionDelete from "../../app/table/actions/table-action-delete";
import Table from "../../app/table/table";
import "./tapping-popup.css";

class TappingPopup extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.uploadRef = React.createRef();

    let part = props.parts.find(p => p.selected);
    let lines = part.tapping && Object.entries(part.tapping.lines).map(([id, amount]) => {
      let t = this.props.tappings.find(t => t.id === id);

      return {
        key: generateUUID(),
        type: t.type,
        name: t.name,
        amount: amount,
      };
    });

    this.state = {
      tappingLines: lines && lines.length > 0 ?
        lines : [
          { key: generateUUID() }
        ],
      tappingFile: part.tapping && part.tapping.file,
      errors: {},
    }
  }

  onInputChange(tappingLine, e) {
    let key = e.target.name;
    let val = e.target.value;

    this.setState((state) => ({
      tappingLines: state.tappingLines.map(tl => tl.key === tappingLine.key
        ? {
          ...tl,
          name: key === 'type'
            ? ''
            : tl.name,
          [key]: val
        }
        : tl),
    }));
  }

  addFile(e) {
    let file = e.target.files[0];
    if (!file) return;

    let extensions = this.context.settings.extensions.tappingFiles.map(e => `.${e}`);

    if (!extensions.some(e => file.name.toLocaleLowerCase().endsWith(e))) {
      let tl = this.context.translate;
      this.context.addNotification('error', tl('calculate_tapping_file_format_error'));

      return;
    }

    this.setState((state) => ({
      tappingFile: file,
    }));
  }

  submit() {
    const fields = ['type', 'name', 'amount'];
    let tappingLines = this.state.tappingLines.filter(tl => !fields.every(k => !tl[k]));

    let errors = tappingLines
      .map(tl => [tl.key, fields.filter(k => !tl[k])]);

    if (errors.flatMap(e => e[1]).length > 0) {
      this.setState((state) => ({
        errors: Object.fromEntries(errors),
      }));

      return;
    }

    if (!this.state.tappingFile && tappingLines.length > 0) {
      let tl = this.context.translate;
      this.context.addNotification('error', tl('calculate_tapping_missing_file'));

      return;
    }

    let lines = Object.fromEntries(this.props.tappings
      .map(t => {
        let amount = tappingLines
          .filter(tl => tl.type === t.type
            && tl.name === t.name)
          .reduce((a, v) => a + parseInt(v.amount), 0);

        return [t.id, amount];
      })
      .filter((([id, amount]) => amount > 0)));

    this.props.onInputChange('tapping', Object.entries(lines).length > 0 ? {
      lines: lines,
      file: this.state.tappingFile,
    } : null);
    this.props.onDismiss();
  }

  removeLine(tappingLine) {
    this.setState((state) => {
      let newTappingLines = state.tappingLines.filter(tl => tl.key !== tappingLine.key);

      return {
        tappingLines: newTappingLines.length > 0
          ? newTappingLines
          : [{ key: generateUUID() }]
      };
    });
  }

  reset() {

  }

  addLine() {
    this.setState((state) => ({
      tappingLines: [...state.tappingLines, { key: generateUUID() }]
    }));
  }

  renderSelect(key, tappingLine, options) {
    return (
      <select
        name={key}
        value={tappingLine[key]}
        onChange={this.onInputChange.bind(this, tappingLine)}
        className={this.hasError(tappingLine, key) ? 'error' : ''}
      >
        <option></option>
        {options.map((o) => (
          <option key={o} value={o}>
            {o}
          </option>
        ))}
      </select>
    );
  }

  triggerUpload() {
    this.uploadRef.current.click();
  }

  hasError(tappingLine, key) {
    return this.state.errors[tappingLine.key] && this.state.errors[tappingLine.key].includes(key);
  }

  render() {
    let tl = this.context.translate;
    let typeOptions = this.props.tappings
      .map(t => t.type)
      .filter((v, i, s) => s.indexOf(v) === i);
    let getNameOptions = (tappingLine) => {
      if (!tappingLine.type) return [];
      return this.props.tappings
        .filter(tl => tl.type === tappingLine.type)
        .map(tl => tl.name);
    };
    let file = this.state.tappingFile;

    return (
      <Popup
        title={tl('calculate_tapping_popup_title')}
        onDismiss={this.props.onDismiss}
        actions={[
          {
            label: tl('calculate_tapping_add'),
            handler: this.addLine.bind(this),
          },
          {
            label: file && file.name || tl('calculate_tapping_upload'),
            handler: this.triggerUpload.bind(this),
          },
          {
            label: tl('calculate_tapping_submit'),
            handler: this.submit.bind(this),
            className: 'green'
          }
        ]}
      >
        <div className="tappingPopup">
          <div className="popup-section">
            <Table
              mapping={{
                type: {
                  label: tl("calculate_tapping_type"),
                  value: (tappingLine) => this.renderSelect('type', tappingLine, typeOptions),
                },
                name: {
                  label: tl("calculate_tapping_name"),
                  value: (tappingLine) => this.renderSelect('name', tappingLine, getNameOptions(tappingLine)),
                },
                amount: {
                  label: tl("calculate_tapping_amount"),
                  value: (tappingLine) =>
                    <input
                      name="amount"
                      value={tappingLine.amount}
                      onChange={this.onInputChange.bind(this, tappingLine)}
                      className={this.hasError(tappingLine, 'amount') ? 'error' : ''}
                    />
                },
                actions: {
                  label: '',
                  value: (tappingLine) =>
                    <TableActionDelete
                      onClick={this.removeLine.bind(this, tappingLine)}
                    />
                },
              }}
              entries={this.state.tappingLines}
            />
            <input
              ref={this.uploadRef}
              type="file"
              onChange={this.addFile.bind(this)}
              accept={this.context.settings.extensions
                .tappingFiles.map(s => `.${s}`).join(', ')}
            />
          </div>
        </div>
      </Popup>
    );
  }
}

export default TappingPopup;

import AppContext from "../app/app-context";
import ApiClient from "../api-client/api-client";

import BaseComponent from "../app/base-component";
import GenericPage from "../app/generic-page/generic-page";
import Table from "../app/table/table";

import FilterButton from "../app/generic-page/buttons/filter-button";
import AddButton from "../app/generic-page/buttons/add-button";

import ContactPopup from "./contact-popup/contact-popup";

import "./contact-management-page.css";
import QueryFilterPopup from "../app/table/filter-popup/query-filter-popup";
import AccountImportPopup from "../account-management/account-import-popup/accoun-import-popup";

class ContactManagementPage extends BaseComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      contactFilterPopupShown: false,
      contacts: null,
      contactPopupShown: false,
      selectedContact: null,
      roles: [],
      accounts: [],
      languages: [],
      countries: [],
      filterQuery: 'Active==true',
    };
  }

  updateFilterQuery(filterQuery) {
    this.setStateAsync({
      filterQuery,
    })
      .then(this.refreshData.bind(this));
  }

  updateSortQuery(sortQuery) {
    this.setStateAsync({
      sortQuery
    })
      .then(this.refreshData.bind(this));
  }

  updateFilterConditions(accountFilter, nameFilter, roleFilter, countryFilter) {
    this.setState({
      accountFilter: accountFilter,
      nameFilter: nameFilter,
      roleFilter: roleFilter,
      countryFilter: countryFilter,
    });
  }

  getRoles() {
    if (this.context.userHasClaim('Contacts', 'Add')
      || this.context.userHasClaim('Contacts', 'Edit')) {
      return new ApiClient(this.context)
        .call(
          "GET",
          `/v1/Account/${this.props.accountId}/Contact/AssignableRoles`
        )
        .then((response) => {
          let roles = response.json;

          return this.setStateAsync({
            roles: roles,
          });
        });
    }
  }

  getAccounts() {
    if (this.context.userHasClaim('Accounts', 'View')) {
      return new ApiClient(this.context)
        .call(
          "GET",
          `/v1/Tenant/${this.props.tenantId}/Account`,
          null,
          false,
          {},
          {
            Sorts: "name",
            Filters: "Active==true",
          }
        )
        .then((response) => {
          let accounts = response.json;

          return this.setStateAsync({
            accounts: accounts,
          });
        });
    }
    else {
      return this.setStateAsync({
        accounts: [
          this.context.user.account
        ],
      });
    }
  }

  getLanguages() {
    return new ApiClient(this.context)
      .call("GET", "/v1/Language", null, false, { "Accept-Language": null })
      .then((result) => {
        if (result.ok) {
          let data = result.json;

          return this.setStateAsync({
            languages: data,
          });
        }
      });
  }

  componentDidMount() {
    Promise.all([
      this.getRoles(),
      this.getAccounts(),
      this.getCountries(),
      this.getLanguages(),
    ]).then(() => {
      this.refreshData();
    });
  }

  refreshData() {
    var sortQuery = this.state.sortQuery || '';
    var filterQuery = this.state.filterQuery || '';

    new ApiClient(this.context)
      .call("GET", `/v1/Account/${this.props.accountId}/Contact`, null, false, {}, {
        'Sorts': sortQuery,
        'Filters': filterQuery,
      })
      .then((response) => {
        if (response.ok) {
          let contacts = response.json;

          this.setStateAsync({
            contacts: contacts,
          });
        }
      });
  }

  toggleContactPopup() {
    this.setState({
      contactPopupShown: !this.state.contactPopupShown,
      selectedContact: null,
    });
  }

  toggleContactEditPopup(contact) {
    this.setState({
      contactPopupShown: !this.state.contactPopupShown,
      selectedContact: contact,
    });
  }

  toggleContactFilterPopup() {
    this.setState({
      contactFilterPopupShown: !this.state.contactFilterPopupShown,
    });
  }

  toggleImportPopup() {
    this.setState(state => ({
      importPopupShown: !state.importPopupShown,
    }));
  }

  renderButtons() {
    let tl = this.context.translate;

    return (
      <div>
        <FilterButton onClick={this.toggleContactFilterPopup.bind(this)} />
        <div class="group">
          {this.context.userHasClaim('Accounts', 'Add') && <AddButton onClick={this.toggleImportPopup.bind(this)} label={tl('general_import')} />}
          {this.context.userHasClaim('Contacts', 'Add') && <AddButton onClick={this.toggleContactPopup.bind(this)} />}
        </div>
      </div>
    );
  }

  renderTable() {
    let tl = this.context.translate;

    return (
      <Table
        mapping={{
          // account: {
          //   label: tl('general_account'),
          //   value: (contact) => contact.account.name,
          //   sortKey: 'AccountName',
          // },
          active: {
            label: tl('general_active'),
            sortKey: 'Active',
            value: (contact) => contact.active ? '✅' : '⛔',
          },
          fullName: {
            label: tl('general_name'),
            sortKey: 'FullName',
          },
          address: {
            label: tl('general_address'),
            sortKey: 'Address',
          },
          role: {
            label: tl('general_role'),
            value: (contact) => contact.role !== null ? contact.role.name : '',
            sortKey: 'Role',
          },
          country: {
            label: tl('general_country'),
            sortKey: 'Country',
          },
        }}
        entries={this.state.contacts}
        onSelect={this.context.userHasClaim('Contacts', 'Edit')
          && this.toggleContactEditPopup.bind(this)}

        sortQuery={this.state.sortQuery}
        updateSortQuery={this.updateSortQuery.bind(this)}
      />
    );
  }

  render() {
    let tl = this.context.translate;
    return (
      <GenericPage
        className="contactManagementPage"
        title={tl('general_contacts')}
        buttons={this.renderButtons()}
        content={this.renderTable()}
      >
        {this.state.contactPopupShown && (
          <ContactPopup
            onDismiss={this.toggleContactPopup.bind(this)}
            roles={this.state.roles}
            accounts={this.state.accounts}
            languages={this.state.languages}
            countries={this.state.countries}
            contact={this.state.selectedContact}
            accountId={this.props.accountId}
            onCommit={this.refreshData.bind(this)}
          />
        )}
        {this.state.importPopupShown && (
          <AccountImportPopup
            onDismiss={this.toggleImportPopup.bind(this)}
            tenantId={this.props.tenantId}
            onCommit={this.refreshData.bind(this)}
          />
        )}
        {this.state.contactFilterPopupShown && (
          <QueryFilterPopup
            onDismiss={this.toggleContactFilterPopup.bind(this)}
            filterQuery={this.state.filterQuery}
            updateFilterQuery={this.updateFilterQuery.bind(this)}
            mapping={{
              // 'AccountId': {
              //   label: tl('general_account'),
              //   options: this.state.accounts.map(a => ({
              //     label: a.name,
              //     value: a.id,
              //   })),
              // },
              'FullName': {
                label: tl('general_name'),
              },
              'Address': {
                label: tl('general_address'),
              },
              'Role': {
                label: tl('general_role'),
                options: this.state.roles.map(r => ({
                  label: r.name,
                  value: r.name,
                })),
              },
              'Country': {
                label: tl('general_country'),
                options: this.state.countries.map(c => ({
                  label: c,
                  value: c
                })),
              },
            }}
          />
        )}
      </GenericPage>
    );
  }
}

export default ContactManagementPage;

import { Component } from "react";
import AppContext from "../../../app/app-context";
import "./statistic.css";

class Statistic extends Component {
  static contextType = AppContext;
  render() {
    let tl = this.context.translate;
    return (
      <div className="statistic">
        <img className="statistic-icon" alt="" src={this.props.icon}></img>
        <span className="statistic-statistic">{this.props.statistic}</span>
        <span className="statistic-unit">{this.props.unit}</span>
        <span className="statistic-subtitle">
          {this.props.subtitleAmount !== "" && (
            <span>
              (
              <span className="statistic-subtitleAmount">
                {this.props.subtitleAmount}%
              </span>{" "}
              {this.props.subtitleUnit})
            </span>
          )}
        </span>

        {this.props.worth && (
          <div className="statistic-worth-container">
            <span className="statistic-worth">
              {tl('dashboard_statistics_worth')}:
            </span>
            <span className="statistic-worth-amount">{this.props.worth}</span>
          </div>
        )}

        <div className="statistic-horizontalLine"></div>
        <span className="statistic-difference-container">
          {tl('dashboard_statistics_last_month')}
          <span className="statistic-difference">{this.props.difference}</span>
          {this.props.increase ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 6">
              <path d="M4,0,8,6H0Z" fill="#0caa1b" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 6">
              <path
                d="M4,0,8,6H0Z"
                transform="translate(8 6) rotate(180)"
                fill="#e12626"
              />
            </svg>
          )}
        </span>
      </div>
    );
  }
}

export default Statistic;
